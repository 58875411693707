import styled from "styled-components";

const ModalFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 108px;
  border-top: 1px solid var(--color-neutral-90);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 56px 72px;
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
  margin-top: 16px;
`;

const ChildrenContainer = styled.div`
  width: 400px;
`;

export { ModalFooter, IconContainer, ChildrenContainer };
