import { useNavigate } from "react-router-dom";
import { Stepper, Breadcrumbs, Icons } from "@flash-tecnologia/hros-web-ui-v2";
import { Container, BreadcrumbText } from "./styles";
import { HeaderInterface } from "./types";

const Header = ({ step, type, hiddenSteps = [] }: HeaderInterface) => {
  const navigate = useNavigate();

  const steps =
    type === "survey"
      ? [
          "Modelo",
          "Informações Básicas",
          "Envio",
          "Configurações",
          "Conteúdo",
          "Destinatários",
          "Revisão",
        ]
      : [
          "Informações Básicas",
          "Configurações",
          "Destinatários",
          "Envio",
          "Revisão",
        ];

  return (
    <Container>
      <Breadcrumbs
        separator={<Icons name="IconChevronRight" />}
        breadcrumbs={[
          <BreadcrumbText
            key={"bt1"}
            variant="body4"
            linkMode
            onClick={() => navigate("/engagement/survey")}
          >
            Pesquisas
          </BreadcrumbText>,
          <BreadcrumbText key={"bt2"} variant="body4">
            Criar pesquisa
          </BreadcrumbText>,
        ]}
      />
      <Stepper
        className="survey-form-stepper"
        steps={steps?.filter((s) => !hiddenSteps?.includes(s))}
        activeStep={step}
        disableClick
        style={{ cursor: "default" }}
      />
    </Container>
  );
};
export { Header };
