import { dayjs, Icons } from "@flash-tecnologia/hros-web-ui-v2";
import { ButtonWithIcon, Typography } from "@components/index";
import { Container, DateContainer } from "./styles";
import { BigDatesInteface } from "./types";
import { trpc } from "@api/client";
import { alert } from "@utils";

export default ({
  createdAt,
  sendDate,
  interactionDeadline,
  updatedAt,
  recurrence,
  id,
  getEngagementById,
  status,
}: BigDatesInteface) => {
  const updateRecurrenceStatus = trpc?.updateRecurrenceStatus.useMutation({
    onSuccess: (e) => {
      if (e?.recurrence?.status) getEngagementById();
      alert("Recorrência atualizada com sucesso.", "success");
    },
    onError: (e) => {
      console.log(e);
      alert("Erro ao atualizar recorrência, tente novamente mais tarde.");
    },
  });

  const loading = updateRecurrenceStatus?.isLoading;

  return (
    <Container>
      <DateContainer>
        <Icons name="IconCalendar" color="var(--color-secondary-50)" />
        <Typography
          variant="body4"
          fontWeight={600}
          color="var(--color-neutral-50)"
        >
          Criado em
        </Typography>
        <Typography
          variant="headline9"
          fontWeight={700}
          color="var(--color-neutral-30)"
        >
          {dayjs(createdAt)?.format("DD/MM/YYYY")}
        </Typography>
      </DateContainer>
      {sendDate ? (
        <DateContainer>
          <Icons name="IconCalendar" color="var(--color-secondary-50)" />
          <Typography
            variant="body4"
            fontWeight={600}
            color="var(--color-neutral-50)"
          >
            Enviado em
          </Typography>
          <Typography
            variant="headline9"
            fontWeight={700}
            color="var(--color-neutral-30)"
          >
            {dayjs(sendDate)?.format("DD/MM/YYYY")}
          </Typography>
        </DateContainer>
      ) : null}
      {interactionDeadline ? (
        <DateContainer>
          <Icons name="IconFilePlus" color="var(--color-secondary-50)" />
          <Typography
            variant="body4"
            fontWeight={600}
            color="var(--color-neutral-50)"
          >
            Prazo de respostas
          </Typography>
          <Typography
            variant="headline9"
            fontWeight={700}
            color="var(--color-neutral-30)"
          >
            {dayjs(interactionDeadline)?.format("DD/MM/YYYY")}
          </Typography>
        </DateContainer>
      ) : null}
      {status !== "draft" &&
      !!id &&
      ["progress", "paused"]?.includes(recurrence?.status || "") ? (
        recurrence?.status !== "paused" ? (
          <DateContainer>
            <ButtonWithIcon
              loading={loading}
              icon={{
                name: "IconPlayerPause",
                position: "right",
                style: { fill: "transparent" },
              }}
              variant="secondary"
              variantType="error"
              size="medium"
              onClick={() => {
                updateRecurrenceStatus.mutate({ id, status: "paused" });
              }}
            >
              Pausar recorrência
            </ButtonWithIcon>
          </DateContainer>
        ) : (
          <DateContainer>
            <ButtonWithIcon
              loading={loading}
              icon={{
                name: "IconPlayerPlay",
                position: "right",
                style: { fill: "transparent" },
              }}
              variant="secondary"
              size="medium"
              onClick={() => {
                updateRecurrenceStatus.mutate({ id, status: "progress" });
              }}
            >
              Retomar recorrência
            </ButtonWithIcon>
          </DateContainer>
        )
      ) : null}
      {/* <DateContainer>
    <Icons name="IconRefresh" color="var(--color-secondary-50)" />
    <Typography
      variant="body4"
      fontWeight={600}
      color="var(--color-neutral-50)"
    >
      Última atualização
    </Typography>
    <Typography
      variant="headline9"
      fontWeight={700}
      color="var(--color-neutral-30)"
    >
      {dayjs(updatedAt)?.format("DD/MM/YYYY")}
    </Typography>
  </DateContainer> */}
    </Container>
  );
};
