import {
  Header,
  MetricsContainer,
  MetricTitle,
  MainMetric,
  MetricSubTitle,
} from "./styled";
import { CustomHeaderTooltip } from "../CustomHeaderTooltip";
// import { DateSelect, DrawerGraphFilter } from "../DrawerGraphFilter";
// import { ModalExportSuccess } from "./ModalExportSucess";
import { IconButton, Menu } from "@flash-tecnologia/hros-web-ui-v2";
import { useState } from "react";
import { trpc } from "src/api/client";
import { dispatchToast } from "@utils";

export type DateSelect = {
  month: string;
  day: number;
  monthIndex: number;
  year: number;
};

const ReportNames = {
  // AGE_GENDER: "employeesByAgeGender",
  // CITY: "employeesByCity",
  // DEPARTMENT: "employeesByDepartment",
  // GENDER: "employeesByGender",
  // RACE: "employeesByRace",
  // STATE: "employeesByState",
  // TIME_OF_SERVICE: "employeesByTimeOfService",
  // HEADCOUNT_EVOLUTION: "headcountEvolution",
  // TURNOVER: "turnover",
  FEELING_FIRST_GRAPH: "FEELING_FIRST_GRAPH",
  FEELING_SECOND_GRAPH: "FEELING_SECOND_GRAPH",
  FEELING_THIRD_GRAPH: "FEELING_THIRD_GRAPH",
} as const;

export const GraphHeader = (props: {
  // testCompanyId?: string;
  disabled?: boolean;
  info: {
    title: string;
    metric: string;
    explanation: string;
  };
  filters?: {
    dateTo?: string;
    dateFrom?: string;
  };
  // onFiltersChange?: (filters: DateSelect | undefined) => void;
  activeFilters?: DateSelect;
  // reportName?: keyof typeof ReportNames;
}) => {
  const disabled = props?.disabled;
  // const testCompanyId = props?.testCompanyId;
  const activeFilters = props?.activeFilters;
  const info = props?.info;
  const filters = props?.filters;
  // const onFiltersChange = props?.onFiltersChange;
  const isCurrentMetric = info?.title?.indexOf("Tempo de casa") >= 0;
  // const reportName = props?.reportName;

  const [open, setOpen] = useState(false);

  // const createExport = trpc.createExport.useMutation({
  //   onSuccess() {
  //     dispatchToast({
  //       type: "success",
  //       title: "Arquivo exportado com sucesso!",
  //       content: "",
  //     });
  //     setOpen(true);
  //   },
  //   onError(error: any) {
  //     dispatchToast({
  //       type: "error",
  //       title: "Não foi possível exportar o arquivo. Tente novamente!",
  //       content: error?.data?.error,
  //     });
  //   },
  // });

  function formatDateString(dateObj: any) {
    const monthNames = [
      "Jan",
      "Fev",
      "Mar",
      "Abr",
      "Mai",
      "Jun",
      "Jul",
      "Ago",
      "Set",
      "Out",
      "Nov",
      "Dez",
    ];

    function extractDayMonthYear(dateString: string) {
      const date = new Date(dateString);
      const day = String(date.getDate()).padStart(2, "0");
      const month = monthNames[date.getMonth()];
      const year = date.getFullYear();
      return { day, month, year };
    }

    const dateFrom = extractDayMonthYear(dateObj.dateFrom);
    const dateTo = extractDayMonthYear(dateObj.dateTo);

    let dateFromFormatted = `${dateFrom.day} ${dateFrom.month}`;
    let dateToFormatted = `${dateTo.day} ${dateTo.month}`;

    if (dateFrom.year !== dateTo.year) {
      dateFromFormatted += ` ${dateFrom.year}`;
      dateToFormatted += ` ${dateTo.year}`;
    }
    return `${dateFromFormatted} - ${dateToFormatted}`;
  }

  return (
    <>
      <Header>
        <MetricsContainer>
          <MetricTitle variant="headline8">
            {info?.title}
            {info?.explanation && (
              <CustomHeaderTooltip text={info?.explanation} />
            )}
          </MetricTitle>
          {isCurrentMetric ? (
            <></>
          ) : (
            <MetricSubTitle variant="body4" weight={600}>
              {filters?.dateTo && filters?.dateTo
                ? formatDateString(filters)
                : ""}
            </MetricSubTitle>
          )}
          {info?.metric && (
            <MainMetric variant="body4">{info?.metric}</MainMetric>
          )}
        </MetricsContainer>
        {/* {!!filters && (
          <DrawerGraphFilter
            onDateSelect={(date) => onFiltersChange && onFiltersChange(date)}
            activeDate={activeFilters}
            disabled={disabled}
          />
        )} */}
        {/* {!!reportName && (
          <Menu
            type="select"
            disabled={disabled}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            options={[
              {
                onClick: () => {
                  segment({
                    track:
                      "people_strategic_hr_insights_chart_exportcsv_option_clicked",
                  });
                  dispatchToast({
                    type: "warning",
                    content: "Gerando exportação do relatório...",
                  });
                  createExport.mutate({
                    body: {
                      filters,
                      testCompanyId,
                      type: "csv",
                      reportName: ReportNames[reportName],
                    },
                  });
                },
                children: <>Exportar em CSV</>,
              },
            ]}
          >
            <IconButton
              variant="line"
              icon={"IconDownload"}
              disabled={disabled}
              size="small"
              onClick={() =>
                segment({
                  track:
                    "people_strategic_hr_insights_chart_export_icon_clicked",
                })
              }
            />
          </Menu>
        )} */}
      </Header>
      {/* <ModalExportSuccess open={open} onClose={() => setOpen(false)} /> */}
    </>
  );
};
