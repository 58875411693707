import styled from "styled-components";

const Container = styled.div`
  min-width: 280px;
  max-width: 280px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  gap: 14px;

  @media screen and (max-width: 1450px) {
    display: none;
  }
`;

export { Container };
