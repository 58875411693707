import {
  Button,
  Modal as ModalUiv2,
  LinkButton,
  dayjs,
} from "@flash-tecnologia/hros-web-ui-v2";
import { Typography } from "@components/Typography";
import { ChildrenContainer, IconContainer, ModalFooter } from "./styles";
import { BorderIcon } from "@components/index";
const { Root: ModalRoot } = ModalUiv2;

const DraftConfirmModal = ({
  visible,
  interactionDeadline,
  onCancel,
  onClick,
  loading,
}: {
  visible: boolean;
  interactionDeadline?: string;
  onCancel: () => void;
  onClick: () => void;
  loading: boolean;
}) => (
  <ModalRoot
    open={visible}
    onClose={() => null}
    showClose={false}
    children={
      <ChildrenContainer>
        <Typography
          variant="body4"
          fontWeight={700}
          color="var(--color-feedback-error-40)"
          style={{ marginBottom: "8px" }}
        >
          {"Atenção!"}
        </Typography>
        <Typography
          variant="headline8"
          fontWeight={700}
          color="var(--color-neutral-10)"
          style={{ marginBottom: "8px" }}
        >
          {"Tem certeza que deseja sair da pesquisa?"}
        </Typography>
        <Typography
          variant="body4"
          fontWeight={400}
          color="var(--color-neutral-40)"
          style={{ marginBottom: "8px" }}
        >
          {"Você pode salvar as respostas e continuar respondendo mais tarde."}
        </Typography>
        {interactionDeadline ? (
          <IconContainer>
            <BorderIcon
              name="IconMessage2"
              size={14}
              color="var(--color-secondary-50)"
              style={{
                padding: "4px",
                borderColor: "var(--color-secondary-95)",
              }}
            />
            <Typography
              variant="body4"
              fontWeight={600}
              color="var(--color-secondary-50)"
            >
              {`Prazo de resposta: até ${dayjs(interactionDeadline)?.format(
                "DD/MM/YYYY"
              )}`}
            </Typography>
          </IconContainer>
        ) : null}
      </ChildrenContainer>
    }
    footer={
      <ModalFooter>
        <div>
          <LinkButton
            size="large"
            onClick={() => onCancel()}
            variant={"neutral"}
          >
            Sair sem salvar
          </LinkButton>
        </div>
        <div>
          <Button
            variant="primary"
            size="large"
            loading={loading}
            onClick={() => onClick()}
          >
            Sair e salvar respostas
          </Button>
        </div>
      </ModalFooter>
    }
  />
);

export { DraftConfirmModal };
