import { Container, EmptyStateIconBox, Text } from "./styled";
// import EmptyStateIcon from "@Assets/empty-state-icon.png";

export const GraphEmptyState = () => {
  return (
    <Container>
      {/* <EmptyStateIconBox src={EmptyStateIcon} alt="Empty State Icon" /> */}
      <Text variant="body1" style={{ fontWeight: 600 }} variantColor="#83727D">
        Nenhum dado encontrado para exibir esse gráfico.
      </Text>
    </Container>
  );
};
