import { TransferList } from "@components/v2/TransferList";
import { EmployeeListProps } from "./types";

const EmployeeList = ({
  employees,
  selected,
  filter,
  onChange,
}: EmployeeListProps) => (
  <TransferList
    data={employees}
    selected={selected}
    onChange={(selected) => onChange(selected)}
    filter={filter}
  />
);

export { EmployeeList };
