import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: flex;
  justify-content: flex-start;
  gap: 24px;
  padding: 24px;
`;

export { Container };
