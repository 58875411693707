import { Modal } from "@components/v2";
import { ReminderInterface } from "./types";

const ManualReminderModal = ({
  visible,
  errorVisible,
  loading,
  onClose,
  onSubmit,
  notViewed,
}: ReminderInterface) => (
  <>
    <Modal
      open={errorVisible}
      onClose={() => onClose()}
      title="Atenção!"
      subTitle={`O envio da notificação foi <br/> realizado há menos de 24 <br/> horas.`}
      description="Notificar os colaboradores em um curto espaço de tempo <br/> pode gerar incômodo e prejudicar o engajamento da sua <br/> pesquisa."
      iconStatus="alert"
      closeButton
      iconName="IconAlertCircle"
      footer={{
        closeButton: {
          label: "Não enviar",
          onClick: () => onClose(),
          variant: "neutral",
        },
        submitButton: {
          label: "Notificar mesmo assim",
          loading: loading,
          onClick: () => {
            onSubmit();
            // reminder({ engagementId: id || "", force: true });
          },
          variant: "secondary",
          variantType: "error",
        },
      }}
    />
    <Modal
      open={visible}
      onClose={() => onClose()}
      title="Atenção!"
      subTitle={`Tem certeza que deseja <br/> enviar a notificação <br/> novamente?`}
      description={`${notViewed}% pessoas que ainda não responderam esta pesquisa serão notificadas.`}
      iconStatus="alert"
      closeButton
      iconName="IconAlertCircle"
      footer={{
        closeButton: {
          label: "Não enviar",
          onClick: () => onClose(),
          variant: "neutral",
        },
        submitButton: {
          label: "Enviar notificação",
          loading: loading || false,
          onClick: () => onSubmit(),
          variant: "secondary",
          variantType: "error",
        },
      }}
    />
  </>
);

export default ManualReminderModal;
