import {
  Carousel,
  Icons,
  ShapeIcon,
  Tooltip,
} from "@flash-tecnologia/hros-web-ui-v2";
import { Typography } from "@components/index";
import {
  Container,
  BigNumberContainer,
  BigNumberContentContainer,
  CarouselContainer,
  TooltipContainer,
} from "./styles";
import { InsightsInteface } from "./types";
import { TooltipText } from "@components/Card/styles";

export default ({
  occurences = 0,
  recipients = 0,
  answers = 0,
  viewRate = 0,
  answerRate = 0,
}: InsightsInteface) => (
  <Container>
    <CarouselContainer>
      <Carousel
        header={{ showVisibilityToggle: true, title: "Insights" }}
        slides={[
          <BigNumberContainer>
            <ShapeIcon
              name="IconSend"
              variant={"default"}
              color="var(--color-secondary-50)"
              size={48}
            />
            <BigNumberContentContainer>
              <Typography variant="body3" color={"var(--color-neutral-50)"}>
                Envios
              </Typography>
              <Typography variant="headline5" color={"var(--color-neutral-30)"}>
                {occurences}
              </Typography>
            </BigNumberContentContainer>
          </BigNumberContainer>,
          <BigNumberContainer>
            <ShapeIcon
              name="IconUser"
              variant={"default"}
              color="var(--color-secondary-50)"
              size={48}
            />
            <BigNumberContentContainer>
              <Typography variant="body3" color={"var(--color-neutral-50)"}>
                Próximo envio
              </Typography>
              <Typography variant="headline5" color={"var(--color-neutral-30)"}>
                {recipients}
              </Typography>
            </BigNumberContentContainer>
          </BigNumberContainer>,
          <BigNumberContainer>
            <ShapeIcon
              name="IconEyeCheck"
              variant={"default"}
              color="var(--color-secondary-50)"
              size={48}
            />
            <BigNumberContentContainer>
              <Typography variant="body3" color={"var(--color-neutral-50)"}>
                Taxa de visualização
              </Typography>
              <Typography variant="headline5" color={"var(--color-neutral-30)"}>
                {viewRate ? `${Math.round(viewRate)}%` : Math.round(viewRate)}
              </Typography>
              <TooltipContainer>
                <Tooltip
                  placement="top"
                  title={
                    <>
                      <TooltipText variant="body4">
                        Representa o percentual de pessoas que visualizaram a
                        pesquisa em relação ao total de destinatários. Este
                        número reflete o alcance da pesquisa e pode indicar o
                        engajamento inicial com o conteúdo enviado
                      </TooltipText>
                    </>
                  }
                >
                  <div>
                    <Icons
                      size={18}
                      name={"IconInfoCircle"}
                      fill={"transparent"}
                      color={"var(--color-neutral-dark3)"}
                    />
                  </div>
                </Tooltip>
              </TooltipContainer>
            </BigNumberContentContainer>
          </BigNumberContainer>,
          <BigNumberContainer>
            <ShapeIcon
              name="IconGraph"
              variant={"default"}
              color="var(--color-secondary-50)"
              size={48}
            />
            <BigNumberContentContainer>
              <Typography variant="body3" color={"var(--color-neutral-50)"}>
                Taxa de resposta
              </Typography>
              <Typography variant="headline5" color={"var(--color-neutral-30)"}>
                {answerRate
                  ? `${Math.round(answerRate)}%`
                  : Math.round(answerRate)}
              </Typography>
              <TooltipContainer>
                <Tooltip
                  placement="top"
                  title={
                    <>
                      <TooltipText variant="body4">
                        Representa o percentual de pessoas que responderam à
                        pesquisa em relação ao total de destinatários. Esse
                        número reflete o engajamento final e mostra quantos
                        participantes completaram a pesquisa dentre todos que a
                        receberam.
                      </TooltipText>
                    </>
                  }
                >
                  <div>
                    <Icons
                      size={18}
                      name={"IconInfoCircle"}
                      fill={"transparent"}
                      color={"var(--color-neutral-dark3)"}
                    />
                  </div>
                </Tooltip>
              </TooltipContainer>
            </BigNumberContentContainer>
          </BigNumberContainer>,
        ]}
        gap={24}
        arrows={"bottom"}
      />
    </CarouselContainer>
  </Container>
);
