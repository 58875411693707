import { useState, useEffect } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  LabelList,
  ResponsiveContainer,
} from "recharts";

import { ChildrenContainer, BoxContainer, LabelStyle } from "./styled";

export type TGraphHorizontalBars = {
  data: {
    name: string;
    amt: number;
  }[];
  settings: {
    name: string;
    color: string;
    showAll: boolean;
  };
};

export const GraphHorizontalBars = ({
  data,
  settings,
}: TGraphHorizontalBars) => {
  const [dataToShow, setDataToShow] = useState(data);
  const [ticks, setTicks] = useState<number[]>([]);

  useEffect(() => {
    setDataToShow(data);

    const maxAmt = Math.max(...data?.map((item) => item?.amt));
    if (maxAmt) {
      const newArray = Array.from(
        { length: maxAmt / 10 + 3 },
        (_, index) => index * 10
      );

      setTicks(newArray);
    }
  }, [data]);

  const renderCustomStyle = (props: any) => {
    const { x, y, width, value } = props;

    // 7.2 is average character length for this label in px
    const charactersLength = String(value).length * 7.2;

    const total = data.reduce((accumulator, current) => {
      return accumulator + current.amt;
    }, 0);

    return (
      <g>
        <foreignObject
          x={x + width / 2 - charactersLength}
          y={y - 45}
          width={100}
          height={40}
          display={"flex"}
        >
          <LabelStyle variant="caption">
            <strong>{((value / total) * 100).toFixed(1)}%</strong>
          </LabelStyle>
          <LabelStyle variant="caption">{value} resp.</LabelStyle>
        </foreignObject>
      </g>
    );
  };

  const CustomTooltip = ({ payload, active, label }: any) => {
    if (active && payload && payload.length) {
      return (
        <div
          className="custom-tooltip"
          style={{
            backgroundColor: "#1D161B",
            padding: " 12px 16px",
            borderRadius: "8px",
          }}
        >
          <p className="label" style={{ color: "#fff" }}>
            {`${label}: ${payload?.[0].value}`} pessoa
            {payload?.[0]?.value == 1 ? "" : "s"}
          </p>
        </div>
      );
    }
    return null;
  };

  return (
    <ChildrenContainer>
      <BoxContainer>
        <ResponsiveContainer>
          <BarChart
            layout="horizontal"
            data={dataToShow}
            margin={{
              top: 20,
              right: 30,
              bottom: 20,
              left: 0,
            }}
          >
            <CartesianGrid stroke="#f5f5f5" />

            <XAxis dataKey="name" type="category" style={{ fontWeight: 600 }} />
            <YAxis
              type="number"
              domain={[0, (dataMax) => Math.ceil((dataMax * 1.2) / 10) * 10]}
              ticks={ticks}
              style={{ fontWeight: 600 }}
            />
            <Tooltip content={<CustomTooltip />} />
            <Bar
              isAnimationActive={false}
              dataKey="amt"
              name={settings.name}
              barSize={40}
              fill={settings.color}
            >
              <LabelList
                dataKey="amt"
                position="top"
                content={renderCustomStyle}
              />
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </BoxContainer>
    </ChildrenContainer>
  );
};
