import styled from "styled-components";

const Container = styled.div`
  min-height: 72px;
  width: 100%;
  padding: 0 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid var(--color-neutral-90);
  background-color: var(--color-neutral-100);
  position: sticky;
  bottom: 0;
  z-index: 2;
`;

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
`;

export { Container, ButtonsContainer };
