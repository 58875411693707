import { useState } from "react";
import { Table, tableControllers, Tag } from "@flash-tecnologia/hros-web-ui-v2";
import { PaginationState } from "@flash-tecnologia/hros-web-ui-v2/dist/components/Table/components/Pagination";
import { Avatar } from "../../../components/index";
import { PeopleTabInterface, EmployeeType } from "./types";

export default ({ employees }: PeopleTabInterface) => {
  const [pagination, setPagination] = useState<PaginationState>({
    pageNumber: 1,
    pageSize: 5,
  });

  const { pageNumber, pageSize } = pagination;

  const count = employees.length;

  const finalData = employees.slice(
    (pageNumber - 1) * pageSize,
    pageNumber * pageSize
  );

  const table = tableControllers.useTableColumns<EmployeeType>({
    defaultColumn: {
      minSize: 200,
    },
    total: count,
    // options: {
    //   actions: (context) => [
    //     {
    //       label: context.row.depth === 0 ? "Adicionar" : "Bloquear",
    //       icon: "IconUser",
    //       key: "add",
    //       onClick: (data) => console.log({ data }),
    //     },
    //   ],
    // },
    columns: [
      {
        header: "Nome",
        accessorKey: "name",
        sticky: "left",
        cell: (e) => (
          <Avatar
            name={e?.row?.original?.name || ""}
            picture={e?.row?.original?.picture}
          />
        ),
      },
      {
        header: "Visualização",
        accessorKey: "visualized",
        cell: (e) => {
          const visualized = e?.row?.original?.visualized;

          if (typeof visualized === "boolean") {
            if (visualized) return <Tag variant="green">Sim</Tag>;
            return <Tag variant="yellow">Não</Tag>;
          }

          return <>N/A</>;
        },
      },
      {
        header: "Status",
        accessorKey: "status",
        cell: (e) => {
          const status = e?.row?.original?.status;

          if (status === "not-started")
            return <Tag variant="gray">Não iniciado</Tag>;
          if (status === "done") return <Tag variant="green">Concluído</Tag>;
          if (status === "started")
            return <Tag variant="yellow">Não concluído</Tag>;

          return <>N/A</>;
        },

        minSize: 120,
      },
    ],
    data: finalData,
    pagination: pagination,
    onPaginationChange: setPagination,
  });

  return (
    <Table.Root>
      <Table.Grid.Root loading={false}>
        <Table.Grid.Header getHeaderGroups={table.getHeaderGroups} />
        {table.rows.map((row, index) => (
          <Table.Grid.Row key={index + row.id} row={row} />
        ))}
      </Table.Grid.Root>
      <Table.Pagination
        count={count}
        onPaginationChange={({ pageSize, pageNumber }) =>
          setPagination({
            ...pagination,
            pageSize,
            pageNumber: pagination?.pageSize !== pageSize ? 1 : pageNumber,
          })
        }
        pagination={pagination}
        pageSizeOptions={[
          {
            label: "5 itens",
            value: 5,
          },
          {
            label: "10 itens",
            value: 10,
          },
          {
            label: "25 itens",
            value: 25,
          },
        ]}
      />
    </Table.Root>
  );
};
