import { generateSurveyDataByModel } from "@components/EngagementForm/common/components";
import { Typography, Tag, Icons } from "@flash-tecnologia/hros-web-ui-v2";
import { Box } from "@mui/material";
import styled, { useTheme } from "styled-components";

const Container = styled.div`
  margin-bottom: 30px;
  width: 100%;
`;

const SectionContainer = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const QuestionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid #eee;
`;

const QuestionText = styled(Typography)`
  flex-grow: 1;
`;

const AnswerFormat = styled(Tag)`
  font-size: 12px;
  padding: 2px 8px;
`;

const DimensionWrapper = styled.div`
  border-radius: 12px;
  border: 1px solid var(--Neutral-Neutral-90, #ebe6e9);
  display: flex;
  padding: 40px;
  align-self: stretch;
  margin-top: 24px;
  flex-direction: column;

  gap: 16px;
`;

export const engajaModelParts = {
  personalInfo: {
    partName: "Informações Pessoais",
    partDescription:
      "Coleta informações demográficas e profissionais para garantir uma análise com valor e resultados relevantes para todos os colaboradores.",
    sectionReferenceIds: ["engaja_personal_information"],
  },
  attributes: {
    partName: "Atributos do Engajamento",
    partDescription:
      "Coleta informações sobre como os colaboradores se sentem em relação à empresa, avaliando as seis dimensões do engajamento. As respostas ajudarão a identificar áreas de melhoria e a promover um ambiente de trabalho positivo.",
    sectionReferenceIds: [
      "engaja_job_meaning_pillars",
      "engaja_good_management_practices_pillars",
      "engaja_positive_work_environment_pillars",
      "engaja_growth_opportunity_pillars",
      "engaja_trust_in_top_leadership_pillars",
      "engaja_compensation_and_benefits_pillars",
    ],
  },
  actions: {
    partName: "Ações",
    partDescription:
      "Avalia o impacto das práticas do RH no trabalho dos colaboradores. Os colaboradores devem informar como ações atuais afetam seu engajamento e como iniciativas não implementadas poderiam influenciar sua experiência.",
    sectionReferenceIds: [
      "engaja_job_meaning_actions",
      "engaja_good_management_practices_actions",
      "engaja_positive_work_environment_actions",
      "engaja_growth_opportunity_actions",
      "engaja_trust_in_top_leadership_actions",
      "engaja_compensation_and_benefits_actions",
    ],
  },
  enps: {
    partName: "eNPS",
    partDescription:
      "eNPS (Employee Net Promoter Score) avalia a lealdade dos colaboradores, medindo o quanto recomendam a empresa como um bom lugar para trabalhar. ",
    sectionReferenceIds: ["engaja_enps"],
  },
};

const getAnswerFormatTag = (answerFormat) => {
  switch (answerFormat) {
    case "text":
      return (
        <AnswerFormat variant="gray">
          <Icons name="IconAlignJustified" size={12} />
          <Typography variant="caption" variantColor={"#1D161B"}>
            Texto
          </Typography>
        </AnswerFormat>
      );
    case "multiple-choice":
      return (
        <AnswerFormat variant="gray">
          <Icons name="IconCircleDot" size={12} />

          <Typography variant="caption" variantColor={"#1D161B"}>
            Múltipla escolha
          </Typography>
        </AnswerFormat>
      );
    case "dropdown":
      return (
        <AnswerFormat variant="gray">
          <Icons name="IconArticle" size={12} />

          <Typography variant="caption" variantColor={"#1D161B"}>
            Lista Suspensa
          </Typography>
        </AnswerFormat>
      );
    case "scale":
      return (
        <AnswerFormat variant="gray">
          <Icons name="IconNumbers" size={12} />

          <Typography variant="caption" variantColor={"#1D161B"}>
            Escala 1-5
          </Typography>
        </AnswerFormat>
      );
    case "engaja_enps":
      return (
        <AnswerFormat variant="gray">
          <Icons name="IconNumbers" size={12} />

          <Typography variant="caption" variantColor={"#1D161B"}>
            Escala 1-10
          </Typography>
        </AnswerFormat>
      );
    default:
      return null;
  }
};

const getFilteredEngajaModelData = (engajaModel, sectionReferenceIds) => {
  return engajaModel.filter((section) =>
    sectionReferenceIds.includes(section.referenceId)
  );
};

const engajaModel = generateSurveyDataByModel("engaja");

const EngajaModelQuestions = ({
  part,
  viewMode = "default",
}: {
  part?: keyof typeof engajaModelParts;
  viewMode?: "default" | "sectionSpecific";
}) => {
  const theme = useTheme() as any;
  let questionCounter = 1;

  return (
    <>
      {viewMode === "default" && (
        <>
          {Object.entries(engajaModelParts).map(([key, value]) => {
            if (part && key !== part) return;

            const sections = getFilteredEngajaModelData(
              engajaModel,
              value.sectionReferenceIds
            );

            return (
              <Container>
                <Tag variant="primary" style={{ marginBottom: "16px" }}>
                  {value.partName}
                </Tag>
                <Typography
                  variant="caption"
                  variantColor={theme.colors.neutral[30]}
                >
                  {value.partDescription}
                </Typography>
                {sections.map((section, index) => {
                  const sectionTitle = section.title.split(": ")[1];

                  return (
                    <SectionContainer key={index}>
                      {sectionTitle && (
                        <Typography
                          variant="headline9"
                          variantColor={theme.colors.neutral[30]}
                        >
                          {sectionTitle}
                        </Typography>
                      )}
                      {section.questions.map((question, qIndex) => (
                        <QuestionContainer key={qIndex}>
                          <QuestionText
                            variant="body4"
                            variantColor={theme.colors.neutral[30]}
                            style={{ maxWidth: "430px" }}
                            weight={600}
                          >
                            {questionCounter++}. {question.title}
                          </QuestionText>
                          {getAnswerFormatTag(
                            section.referenceId === "engaja_enps"
                              ? "engaja_enps"
                              : question.answerFormat
                          )}
                        </QuestionContainer>
                      ))}
                    </SectionContainer>
                  );
                })}
              </Container>
            );
          })}
        </>
      )}
      {viewMode === "sectionSpecific" && (
        <>
          {Object.entries(engajaModelParts).map(([key, value], index) => {
            if (part && key !== part) return;

            const sections = getFilteredEngajaModelData(
              engajaModel,
              value.sectionReferenceIds
            );

            return (
              <Container>
                <Typography
                  variant="headline8"
                  variantColor={theme.colors.neutral[30]}
                >
                  {value.partName}
                </Typography>

                <Typography
                  variant="body3"
                  variantColor={theme.colors.neutral[30]}
                >
                  {value.partDescription}
                </Typography>
                {sections.map((section, index) => {
                  const sectionTitle = section.title.split(": ")[1];
                  const sectionDescription = section.description;

                  return (
                    <DimensionWrapper key={index}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          gap: "8px",
                        }}
                      >
                        {sections.length > 1 && (
                          <AnswerFormat variant="gray">
                            <Typography
                              variant="caption"
                              variantColor={"#1D161B"}
                            >
                              Dimensão {index + 1}
                            </Typography>
                          </AnswerFormat>
                        )}
                        <AnswerFormat variant="gray">
                          <Typography
                            variant="caption"
                            variantColor={"#1D161B"}
                          >
                            {sections?.[index]?.questions?.length} perguntas
                          </Typography>
                        </AnswerFormat>
                      </div>
                      {sectionTitle && (
                        <Typography
                          variant="headline9"
                          variantColor={theme.colors.neutral[30]}
                        >
                          {sectionTitle}
                        </Typography>
                      )}
                      {sectionDescription && (
                        <Typography
                          variant="body4"
                          variantColor={theme.colors.neutral[50]}
                        >
                          {sectionDescription}
                        </Typography>
                      )}
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography
                          variant="headline9"
                          variantColor={theme.colors.neutral[30]}
                          style={{ margin: "16px 0 0" }}
                        >
                          Perguntas
                        </Typography>
                        <Typography
                          variant="headline9"
                          variantColor={theme.colors.neutral[30]}
                          style={{ margin: "16px 0 0" }}
                        >
                          Resposta
                        </Typography>
                      </div>
                      {section.questions.map((question, qIndex) => (
                        <QuestionContainer key={qIndex}>
                          <QuestionText
                            variant="body4"
                            variantColor={theme.colors.neutral[30]}
                            style={{ maxWidth: "430px" }}
                            weight={600}
                          >
                            {questionCounter++}. {question.title}
                          </QuestionText>
                          {getAnswerFormatTag(
                            section.referenceId === "engaja_enps"
                              ? "engaja_enps"
                              : question.answerFormat
                          )}
                        </QuestionContainer>
                      ))}
                    </DimensionWrapper>
                  );
                })}
              </Container>
            );
          })}
        </>
      )}
    </>
  );
};

export default EngajaModelQuestions;
