import styled from "styled-components";

const Body = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  gap: 12px;
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 12px 40px;
  border-top: 1px solid var(--color-neutral-90);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
`;

export { Body, Footer, IconContainer };
