export const graphs = ({
  theme,
  firstGraph = [],
  secondGraph = [],
  thirdGraph = [],
  second_graph_options,
  third_graph_options,
}: {
  firstGraph: { name: string; line: number }[];
  theme: any;
  secondGraph: { [key: string]: string | number }[];
  thirdGraph: { [key: string]: string | number }[];
  second_graph_options: any;
  third_graph_options: any;
}) => [
  {
    id: "firstgraph",
    info: {
      title: "Sentimento ao longo dos envios",
      metric: "Sentimento x Data de envio",
      explanation: "",
    },
    data: firstGraph,
    settings: {
      line: "Sentimento Atual",
      lineColor: "#F20D7A",
      isPercentage: false,
    },
  },
  {
    id: "secondgraph",
    info: {
      title: "O que foi bom?",
      metric: "Respostas ao longo do tempo",
      explanation: "",
    },
    data: secondGraph,
    settings: second_graph_options.map((option, index) => {
      return {
        name: option,
        color: theme.colors.secondary[10 + 10 * index],
      };
    }),
  },
  {
    id: "thirdgraph",
    info: {
      title: "O que poderia melhorar?",
      metric: "Respostas ao longo do tempo",
      explanation: "",
    },
    data: thirdGraph,
    settings: third_graph_options.map((option, index) => {
      return {
        name: option,
        color: theme.colors.secondary[10 + 10 * index],
      };
    }),
  },
];
