import { useState } from "react";
import { Divider, Icons, Typography } from "@flash-tecnologia/hros-web-ui-v2";
import {
  Survey,
  Frame,
  Section,
  GreenContainer,
  RedContainer,
  ResultLineWrapper,
  Thermometer,
  YellowContainer,
  StyledBigNumber,
  Content,
} from "./styled";
import { useTheme } from "styled-components";
import { isNaN } from "formik";
import { IconButtonContainer } from "../ReviewTab/styles";
import { Drawer } from "@components/Drawer";
import { DrawerContent } from "../../../components/DrawerContent";
import { TabButton } from "@components/TabButton";
import { segment } from "@utils";

export default function ResultsTab({ data }) {
  const [currentTab, setCurrentTab] = useState<number>(0);
  const [drawerIsOpen, setDrawerIsOpen] = useState(false);
  const theme = useTheme() as any;

  const results = data?.result?.[0];

  const companyLowAnswersPercentage =
    (results?.company_respondents_low_answers /
      results?.company_respondents_total_count) *
    100;
  const companyMediumAnswersPercentage =
    (results?.company_respondents_medium_answers /
      results?.company_respondents_total_count) *
    100;
  const companyHighAnswersPercentage =
    (results?.company_respondents_high_answers /
      results?.company_respondents_total_count) *
    100;

  const marketLowAnswersPercentage =
    (results?.market_respondents_low_answers /
      results?.market_respondents_total_count) *
    100;
  const marketMediumAnswersPercentage =
    (results?.market_respondents_medium_answers /
      results?.market_respondents_total_count) *
    100;
  const marketHighAnswersPercentage =
    (results?.market_respondents_high_answers /
      results?.market_respondents_total_count) *
    100;

  const engagementAverage = Math.round(results?.engagement_average * 100) / 100;

  return (
    <>
      <Drawer
        content={
          <DrawerContent
            content={[
              {
                title: "Ativamente desengajados (≤2)",
                description:
                  "Estão insatisfeitos, desmotivados e desconectados dos objetivos e valores da empresa. Por isso, não dedicam energia para contribuir com o sucesso organizacional",
                color: "#F45A55",
              },
              {
                title: "Desengajados (2-4) ",
                description:
                  "Tendem a cumprir as tarefas exigidas, mas não estão plenamente comprometidos com os objetivos e valores da organizaçãoe não fazem esforço extra para que ela atinja metas.",
                color: "#F8B81C",
              },
              {
                title: "Engajados  (≥4)",
                description:
                  "Estão comprometidos com os objetivos e valores da organização, motivados e dispostos a contribuir para o sucesso organizacional.",
                color: "#6AA01D",
              },
            ]}
          />
        }
        title="Sobre a escala"
        open={drawerIsOpen}
        onClose={() => setDrawerIsOpen(false)}
      />
      <TabButton
        style={{
          width: "450px",
        }}
        selected={currentTab}
        onChange={(e) => {
          setCurrentTab(e);
          const track = [
            "people_strategic_hr_engagement_company_engajadetails_results_mycompany_clicked",
            "people_strategic_hr_engagement_company_engajadetails_results_marketdata_clicked",
          ][e];

          if (track) segment({ track });
        }}
        tabs={[
          {
            name: "Dados da empresa",
            icon: "IconBuilding",
          },
          // {
          //   name: "Dados do mercado",
          //   icon: "IconGraph",
          // },
        ]}
      />
      {currentTab === 0 ? (
        <Section style={{ marginTop: "16px" }}>
          <Content>
            {!isNaN(engagementAverage) && (
              <StyledBigNumber
                orientation={"vertical"}
                title={"Média do engajamento"}
                value={engagementAverage}
                valueDetails={"5"}
                icon={"IconBuilding"}
                iconColors={{
                  primary: "#6B5B66",
                  secondary: "#EBE6E9",
                }}
              />
            )}
            <StyledBigNumber
              orientation={"vertical"}
              title={"Respostas da empresa"}
              value={results?.company_respondents_total_count}
              icon={"IconMessage"}
              iconColors={{
                primary: "#6B5B66",
                secondary: "#EBE6E9",
              }}
            />
            <Survey>
              <Content>
                <Typography
                  variant="headline9"
                  variantColor={theme?.colors?.neutral[30]}
                >
                  Distribuição de respostas da empresa
                </Typography>
                <IconButtonContainer
                  onClick={() => {
                    segment({
                      track:
                        "people_strategic_hr_engagement_company_engajadetails_scaledescription_clicked",
                    });
                    setDrawerIsOpen(true);
                  }}
                  style={{ borderBottomColor: "var(--color-neutral-40)" }}
                >
                  <Icons
                    name="IconInfoCircle"
                    color={theme?.colors?.neutral[40]}
                    size={15}
                  />
                  <Typography
                    variant="caption"
                    style={{ fontWeight: 700 }}
                    variantColor={theme?.colors?.neutral[40]}
                  >
                    Sobre a escala
                  </Typography>
                </IconButtonContainer>
              </Content>

              <Frame>
                <ResultLineWrapper>
                  <Typography
                    variant="body4"
                    variantColor={theme?.colors?.neutral[30]}
                  >
                    Ativamente desengajados (≤2)
                  </Typography>
                  <Typography
                    variant="body4"
                    variantColor={theme?.colors?.neutral[30]}
                  >
                    {results?.company_respondents_low_answers} (
                    {companyLowAnswersPercentage.toFixed(2)}%)
                  </Typography>
                  <Thermometer>
                    <RedContainer
                      style={{
                        width: `${companyLowAnswersPercentage}%`,
                      }}
                    >
                      <Thermometer width={100} color="#F45A55" />
                    </RedContainer>
                  </Thermometer>
                </ResultLineWrapper>
                <Divider orientation="horizontal" />
                <ResultLineWrapper>
                  <Typography
                    variant="body4"
                    variantColor={theme?.colors?.neutral[30]}
                  >
                    Desengajados (2-4)
                  </Typography>
                  <Typography
                    variant="body4"
                    variantColor={theme?.colors?.neutral[30]}
                  >
                    {results?.company_respondents_medium_answers} (
                    {companyMediumAnswersPercentage.toFixed(2)}%)
                  </Typography>
                  <Thermometer>
                    <YellowContainer
                      style={{
                        width: `${companyMediumAnswersPercentage}%`,
                      }}
                    >
                      <Thermometer width={100} color="#F8B81C" />
                    </YellowContainer>
                  </Thermometer>
                </ResultLineWrapper>
                <Divider orientation="horizontal" />
                <ResultLineWrapper>
                  <Typography
                    variant="body4"
                    variantColor={theme?.colors?.neutral[30]}
                  >
                    Engajados (≥4)
                  </Typography>
                  <Typography
                    variant="body4"
                    variantColor={theme?.colors?.neutral[30]}
                  >
                    {results?.company_respondents_high_answers} (
                    {companyHighAnswersPercentage.toFixed(2)}%)
                  </Typography>
                  <Thermometer>
                    <GreenContainer
                      style={{
                        width: `${companyHighAnswersPercentage}%`,
                      }}
                    >
                      <Thermometer width={100} color="#6AA01D" />
                    </GreenContainer>
                  </Thermometer>
                </ResultLineWrapper>
              </Frame>
            </Survey>
          </Content>
        </Section>
      ) : (
        <Section style={{ marginTop: "16px" }}>
          <Content>
            <StyledBigNumber
              orientation={"vertical"}
              title={"Média do mercado"}
              value={Math.round(results?.market_average * 100) / 100}
              valueDetails={"5"}
              icon={"IconBuilding"}
              iconColors={{
                primary: "#6B5B66",
                secondary: "#EBE6E9",
              }}
            />
            <StyledBigNumber
              orientation={"vertical"}
              title={"Respostas do mercado"}
              value={results?.market_respondents_total_count}
              icon={"IconMessage"}
              iconColors={{
                primary: "#6B5B66",
                secondary: "#EBE6E9",
              }}
            />
            <Survey>
              <Content>
                <Typography
                  variant="headline9"
                  variantColor={theme?.colors?.neutral[30]}
                >
                  Distribuição de respostas do mercado
                </Typography>
                <IconButtonContainer
                  onClick={() => setDrawerIsOpen(true)}
                  style={{ borderBottomColor: "var(--color-neutral-40)" }}
                >
                  <Icons
                    name="IconInfoCircle"
                    color={theme?.colors?.neutral[40]}
                    size={15}
                  />
                  <Typography
                    variant="caption"
                    style={{ fontWeight: 700 }}
                    variantColor={theme?.colors?.neutral[40]}
                  >
                    Sobre a escala
                  </Typography>
                </IconButtonContainer>
              </Content>

              <Frame>
                <ResultLineWrapper>
                  <Typography
                    variant="body4"
                    variantColor={theme?.colors?.neutral[30]}
                  >
                    Ativamente desengajados (≤2)
                  </Typography>
                  <Typography
                    variant="body4"
                    variantColor={theme?.colors?.neutral[30]}
                  >
                    {results?.market_respondents_low_answers} (
                    {marketLowAnswersPercentage.toFixed(2)}%)
                  </Typography>
                  <Thermometer>
                    <RedContainer
                      style={{
                        width: `${marketLowAnswersPercentage}%`,
                      }}
                    >
                      <Thermometer width={100} color="#F45A55" />
                    </RedContainer>
                  </Thermometer>
                </ResultLineWrapper>
                <Divider orientation="horizontal" />
                <ResultLineWrapper>
                  <Typography
                    variant="body4"
                    variantColor={theme?.colors?.neutral[30]}
                  >
                    Desengajados (2-4)
                  </Typography>
                  <Typography
                    variant="body4"
                    variantColor={theme?.colors?.neutral[30]}
                  >
                    {results?.market_respondents_medium_answers} (
                    {marketMediumAnswersPercentage.toFixed(2)}%)
                  </Typography>
                  <Thermometer>
                    <YellowContainer
                      style={{
                        width: `${marketMediumAnswersPercentage}%`,
                      }}
                    >
                      <Thermometer width={100} color="#F8B81C" />
                    </YellowContainer>
                  </Thermometer>
                </ResultLineWrapper>
                <Divider orientation="horizontal" />
                <ResultLineWrapper>
                  <Typography
                    variant="body4"
                    variantColor={theme?.colors?.neutral[30]}
                  >
                    Engajados (≥4)
                  </Typography>
                  <Typography
                    variant="body4"
                    variantColor={theme?.colors?.neutral[30]}
                  >
                    {results?.market_respondents_high_answers} (
                    {marketHighAnswersPercentage.toFixed(2)}%)
                  </Typography>
                  <Thermometer>
                    <GreenContainer
                      style={{
                        width: `${marketHighAnswersPercentage}%`,
                      }}
                    >
                      <Thermometer width={100} color="#6AA01D" />
                    </GreenContainer>
                  </Thermometer>
                </ResultLineWrapper>
              </Frame>
            </Survey>
          </Content>
        </Section>
      )}
    </>
  );
}
