import {
  Icons,
  SelectField,
  TextField,
  Toggle,
  Tooltip,
  MultiSelect,
} from "@flash-tecnologia/hros-web-ui-v2";
import { LineSeparator, Typography } from "@components/index";
import { Divider } from "@components/v2";
import {
  Container,
  ContentContainer,
  FieldContainer,
  FieldRow,
  IconContainer,
  IconDivider,
  LeftContainer,
} from "./styles";
import { QuestionInterface } from "./types";
import {
  Options,
  NumericScale,
  React,
  TextPreview,
  DatePreview,
  OptionsPreview,
  ScalePreview,
} from "./components";
import { useState } from "react";
import { generateId } from "@components/EngagementForm/common/components/generateId";
import { segment } from "@utils";
import { NewModal } from "@components/NewModal";

const Question = ({
  id,
  referenceId,
  title,
  options,
  answerFormat,
  lowerLabel,
  upperLabel,
  scaleRange,
  justification,
  required,
  index,
  isDragging,
  isDragDisabled,
  disabledFields,
  model,
  active,
  showDeleteConfirmationModal,
  onClick,
  onChange,
  onDelete,
  onCloneQuestionClick,
}: QuestionInterface) => {
  const [showModal, setShowModal] = useState(false);
  const feelingTags = {
    0: [
      {
        referenceId: "feeling_survey_what_was_good_issues_resolved",
        label: "Problemas resolvidos",
      },
      {
        referenceId: "feeling_survey_what_was_good_expectations_aligned",
        label: "Expectativas alinhadas",
      },
      {
        referenceId: "feeling_survey_what_was_good_team_relationship",
        label: "Bom relacionamento com a equipe",
      },
      {
        referenceId: "feeling_survey_what_was_good_leader_support",
        label: "Apoio do líder",
      },
      {
        referenceId: "feeling_survey_what_was_good_personal_satisfaction",
        label: "Satisfação na vida pessoal",
      },
      {
        referenceId: "feeling_survey_what_was_good_goals_achieved",
        label: "Metas alcançadas",
      },
      {
        referenceId: "feeling_survey_what_was_good_work_recognized",
        label: "Trabalho reconhecido",
      },
      {
        referenceId: "feeling_survey_what_was_good_development_opportunity",
        label: "Oportunidade de desenvolvimento",
      },
      {
        referenceId: "feeling_survey_what_was_good_work_life_balance",
        label: "Equilíbrio entre trabalho e vida pessoal",
      },
      ,
    ],
    1: [
      {
        referenceId: "feeling_survey_what_could_improve_productivity",
        label: "Produtividade",
      },
      {
        referenceId: "feeling_survey_what_could_improve_team_relationship",
        label: "Relacionamento com a equipe",
      },
      {
        referenceId: "feeling_survey_what_could_improve_leader_support",
        label: "Apoio do líder",
      },
      {
        referenceId: "feeling_survey_what_could_improve_personal_issues",
        label: "Problemas pessoais",
      },
      {
        referenceId: "feeling_survey_what_could_improve_task_overload",
        label: "Sobrecarga de tarefas",
      },
      {
        referenceId: "feeling_survey_what_could_improve_deadlines",
        label: "Dificuldade com prazos",
      },

      {
        referenceId: "feeling_survey_what_could_improve_communication",
        label: "Problemas de comunicação",
      },
      {
        referenceId: "feeling_survey_what_could_improve_autonomy",
        label: "Falta de autonomia",
      },
      {
        referenceId: "feeling_survey_what_could_improve_recognition",
        label: "Falta de reconhecimento",
      },
      {
        referenceId: "feeling_survey_what_could_improve_priority_misalignment",
        label: "Desalinhamento de prioridades",
      },
      {
        referenceId: "feeling_survey_what_could_improve_feedback",
        label: "Feedback do meu trabalho",
      },
      {
        referenceId: "feeling_survey_what_could_improve_planning_organization",
        label: "Falta de planejamento e/ou organização",
      },
    ],
  };

  const answerFormats = [
    {
      label: "Texto",
      value: "text",
    },
    {
      label: "Data",
      value: "date",
    },
    {
      label: "Múltipla escolha",
      value: "multiple-choice",
    },
    {
      label: "Caixa de seleção",
      value: "select-box",
    },
    {
      label: "Lista suspensa",
      value: "dropdown",
    },
    {
      label: "Escala numérica",
      value: "scale",
    },
    {
      label: "Reação",
      value: "react",
    },
  ];

  if (model === "feeling") {
    answerFormats?.push({
      label: "Seleção Multi-Tags",
      value: "multi-select",
    });
  }

  const disabledIconTrash =
    (model === "enps" && answerFormat === "scale") ||
    (model === "climate" && answerFormat === "scale") ||
    (model === "feeling" && answerFormat === "emoji");

  const disabledIconCopy =
    model === "enps" || model === "climate" || model === "feeling";

  const [open, setOpen] = useState<boolean>(false);

  const renderPreview = () => {
    if (answerFormat) {
      if (answerFormat === "text") return <TextPreview />;
      if (answerFormat === "date") return <DatePreview />;
      if (["scale", "emoji", "star", "heart"]?.includes(answerFormat))
        return (
          <ScalePreview
            answerFormat={answerFormat}
            scaleRange={scaleRange}
            upperLabel={upperLabel}
            lowerLabel={lowerLabel}
          />
        );
      if (
        ["multiple-choice", "multi-select", "select-box", "dropdown"]?.includes(
          answerFormat
        )
      )
        return (
          <OptionsPreview
            options={options}
            answerFormat={answerFormat}
            justification={justification}
          />
        );
    }

    return null;
  };

  return (
    <Container
      isDragging={isDragging}
      active={active}
      style={{ cursor: !active && isDragDisabled ? "pointer" : "" }}
      onClick={onClick}
    >
      <NewModal
        id={"modal_create_survey"}
        title={"Excluir pergunta permanentemente?"}
        description={`Ao excluir esta pergunta, ela será removida permanentemente <br/> e não poderá ser adicionada novamente. Para incluir esta <br/> pergunta novamente, será necessário recriar a pesquisa desde o <br/> início. Deseja realmente continuar?`}
        open={showModal}
        onClose={() => setShowModal(false)}
        footer={{
          closeButton: {
            label: "Cancelar",
            variant: "neutral",
            onClick: () => setShowModal(false),
          },
          submitButton: {
            label: "Excluir pergunta",
            variant: "primary",
            variantType: "error",
            onClick: () => {
              onDelete(id || "");
              setShowModal(false);
            },
          },
        }}
        status={{
          icon: "IconAlertCircle",
          color: "#C96C01",
          message: "Atenção!",
        }}
      />
      {isDragDisabled ? null : (
        <LeftContainer isDragging={isDragging} active={active}>
          <Icons
            name="IconGripVertical"
            color={
              isDragging
                ? "var(--color-neutral-100)"
                : active
                ? "var(--color-primary)"
                : "var(--color-neutral-30)"
            }
          />
        </LeftContainer>
      )}
      <ContentContainer
        isDragging={isDragging}
        active={active}
        isDragDisabled={isDragDisabled}
      >
        <Typography
          variant="body4"
          color="var(--color-neutral-40)"
          fontWeight={600}
        >
          Pergunta {index}
        </Typography>
        <Divider spacing="quark" />
        {title && !active ? (
          <Typography
            variant="headline8"
            color="var(--color-neutral-30)"
            fontWeight={700}
          >
            {title}
          </Typography>
        ) : null}
        {active ? (
          <FieldContainer>
            <FieldRow>
              <TextField
                value={title}
                disabled={disabledFields}
                label="Digite sua pergunta"
                className="full-width-field"
                onChange={(e) =>
                  onChange({
                    id,
                    referenceId,
                    options,
                    answerFormat,
                    lowerLabel,
                    justification,
                    upperLabel,
                    scaleRange,
                    required,
                    // otherOption,
                    title: e?.target?.value,
                  })
                }
              />
              <Divider spacing="xs1" type="horizontal" />
              <SelectField
                fullWidth
                className="answer-format-field"
                disabled={
                  ["enps", "climate", "feeling"]?.includes(model || "") ||
                  disabledFields
                }
                label={"Formato da resposta"}
                options={answerFormats}
                onSelectChange={(_: any, option) => {
                  const value = option?.value;

                  const segmentFormats = {
                    text: "text",
                    date: "date",
                    "multiple-choice": "multiplechoice",
                    "select-box": "checkbox",
                    "multi-select": "multiselect",
                    react: "reaction",
                    scale: "scale",
                    dropdown: "dropdownlist",
                  };

                  segment({
                    track: `company_engagement_${
                      model === "enps" ? "enps" : "survey"
                    }content_answerformat_${segmentFormats[value]}_selected`,
                  });

                  const questionOptions =
                    value === "multi-select"
                      ? []
                      : options?.length
                      ? options
                      : [
                          {
                            id: generateId("option"),
                            value: "",
                          },
                          {
                            id: generateId("option"),
                            value: "",
                          },
                        ];

                  if (value !== "react") {
                    onChange({
                      id,
                      referenceId,
                      options: questionOptions,
                      lowerLabel,
                      upperLabel,
                      justification:
                        value === "dropdown" ? false : justification,
                      scaleRange: undefined,
                      required,
                      title,
                      answerFormat: value,
                    });
                    setOpen(false);
                  } else {
                    onChange({
                      id,
                      referenceId,
                      options: questionOptions,
                      lowerLabel,
                      upperLabel,
                      justification,
                      scaleRange,
                      required,
                      title,
                      answerFormat: undefined,
                    });
                    setOpen(true);
                  }
                }}
                value={
                  open ||
                  ["emoji", "heart", "star"]?.includes(answerFormat || "")
                    ? "react"
                    : answerFormat
                }
              />
            </FieldRow>
            {open ||
            ["emoji", "heart", "star"]?.includes(answerFormat || "") ? (
              <FieldContainer>
                <Divider spacing="xs1" />
                <FieldRow>
                  <SelectField
                    fullWidth
                    className="full-width-field"
                    disabled={
                      ["enps", "climate", "feeling"]?.includes(model || "") ||
                      disabledFields
                    }
                    label={"Formato da escala"}
                    options={[
                      {
                        label: "Escala emoji",
                        value: "emoji",
                      },
                      {
                        label: "Escala coração",
                        value: "heart",
                      },
                      {
                        label: "Escala estrela",
                        value: "star",
                      },
                    ]}
                    onSelectChange={(_: any, option) => {
                      const value = option?.value;

                      onChange({
                        id,
                        referenceId,
                        options,
                        lowerLabel,
                        upperLabel,
                        justification,
                        scaleRange: {
                          min: 1,
                          max: 5,
                        },
                        required,
                        title,
                        answerFormat: value,
                      });
                    }}
                    value={answerFormat}
                  />
                </FieldRow>
              </FieldContainer>
            ) : null}

            {answerFormat === "text" || answerFormat === "date" ? (
              <>
                <Divider spacing="xs1" />
                {renderPreview()}
              </>
            ) : null}
            {answerFormat &&
            ["multiple-choice", "select-box", "dropdown"]?.includes(
              answerFormat
            ) ? (
              <>
                <Divider spacing="xs1" />
                <Options
                  disabled={
                    disabledFields ||
                    (model === "feeling" && answerFormat === "multiple-choice")
                  }
                  onChange={(e) =>
                    onChange({
                      id,
                      referenceId,
                      lowerLabel,
                      upperLabel,
                      scaleRange,
                      required,
                      title,
                      answerFormat,
                      justification: e?.justification,
                      options: e?.options,
                    })
                  }
                  answerFormat={answerFormat}
                  justification={justification}
                  options={options}
                />
              </>
            ) : answerFormat === "multi-select" && model === "feeling" ? (
              <>
                <Divider spacing="xs1" />
                <MultiSelect
                  disabled={disabledFields}
                  renderInput={() => null}
                  value={
                    options?.map(({ value, id, referenceId }) => ({
                      label: value,
                      value: id,
                      referenceId: referenceId,
                    })) || []
                  }
                  onSelectChange={(_, values: any) => {
                    onChange({
                      id,
                      referenceId,
                      lowerLabel,
                      upperLabel,
                      scaleRange,
                      required,
                      title,
                      answerFormat,
                      justification: justification,
                      options:
                        values?.map((v) => ({
                          id: v?.value,
                          value: v?.label,
                          referenceId: v?.referenceId,
                        })) || [],
                    });
                  }}
                  loading={false}
                  label="Selecione as opções"
                  noOptionsText="Nenhum valor encontrado."
                  fullWidth
                  options={feelingTags[index === 3 ? 0 : 1]
                    ?.map((tag) => ({
                      label: tag?.label,
                      value:
                        options?.find((o) => o?.value === tag?.label)?.id ||
                        generateId("option"),
                      referenceId:
                        options?.find((o) => o?.value === tag?.label)
                          ?.referenceId || tag?.referenceId,
                    }))
                    ?.filter(
                      (o) => !options?.some((op) => op?.value === o?.label)
                    )}
                  error={false}
                />
              </>
            ) : answerFormat === "scale" ? (
              <>
                <Divider spacing="xs1" />
                <NumericScale
                  disabled={disabledFields}
                  model={model}
                  upperLabel={upperLabel}
                  lowerLabel={lowerLabel}
                  scaleRange={scaleRange}
                  onChange={(e) =>
                    onChange({
                      id,
                      referenceId,
                      required,
                      title,
                      answerFormat,
                      justification,
                      options,
                      // otherOption,
                      lowerLabel: e?.lowerLabel,
                      upperLabel: e?.upperLabel,
                      scaleRange: e?.scaleRange,
                    })
                  }
                />
              </>
            ) : answerFormat &&
              ["star", "heart", "emoji"]?.includes(answerFormat) ? (
              <>
                <Divider spacing="xs1" />
                <React
                  disabled={disabledFields}
                  answerFormat={answerFormat}
                  lowerLabel={lowerLabel}
                  upperLabel={upperLabel}
                  onChange={(e) =>
                    onChange({
                      id,
                      referenceId,
                      required,
                      title,
                      options,
                      scaleRange,
                      justification,
                      // otherOption,
                      answerFormat: e?.answerFormat,
                      lowerLabel: e?.lowerLabel,
                      upperLabel: e?.upperLabel,
                    })
                  }
                />
              </>
            ) : null}
            {["scale", "emoji", "heart", "star"]?.includes(
              answerFormat || ""
            ) ? (
              typeof scaleRange?.min === "number" &&
              typeof scaleRange?.max === "number" ? (
                <>
                  <Divider spacing="xs1" />
                  <LineSeparator />
                  <Divider spacing="xs1" />
                  <Typography
                    variant="headline9"
                    color="var(--color-neutral-30)"
                    fontWeight={700}
                  >
                    Pré-visualização
                  </Typography>
                  <Divider spacing="xs1" />
                  {renderPreview()}
                </>
              ) : null
            ) : null}
            <Divider spacing="xs1" />
            <LineSeparator />
            <Divider spacing="xs1" />
            <FieldRow>
              {disabledIconCopy || disabledFields ? null : (
                <Tooltip placement="top-end" title={"Duplicar"}>
                  <IconContainer
                    onClick={() =>
                      onCloneQuestionClick({
                        id: generateId("question"),
                        required,
                        title,
                        options:
                          options?.map((o) => ({
                            ...o,
                            id: generateId("option"),
                          })) || [],
                        scaleRange,
                        justification,
                        // otherOption,
                        answerFormat,
                        lowerLabel,
                        upperLabel,
                      })
                    }
                  >
                    <Icons name="IconCopy" color={"var(--color-neutral-40)"} />
                  </IconContainer>
                </Tooltip>
              )}

              {disabledIconTrash || disabledFields ? null : (
                <>
                  {disabledIconCopy ? null : (
                    <Divider spacing="xxxs" type="horizontal" />
                  )}
                  <Tooltip placement="top-end" title={"Excluir"}>
                    <IconContainer
                      onClick={() =>
                        showDeleteConfirmationModal
                          ? setShowModal(true)
                          : onDelete(id || "")
                      }
                    >
                      <Icons
                        name="IconTrash"
                        color={"var(--color-neutral-40)"}
                      />
                    </IconContainer>
                  </Tooltip>
                </>
              )}

              {disabledIconCopy && disabledIconTrash ? null : (
                <>
                  <Divider spacing="xxs2" type="horizontal" />
                  <IconDivider />
                  <Divider spacing="xxs2" type="horizontal" />
                </>
              )}

              <Toggle
                disabled={
                  (model === "enps" && answerFormat === "scale") ||
                  (model === "climate" && answerFormat === "scale") ||
                  (model === "feeling" && answerFormat === "emoji") ||
                  disabledFields
                }
                checked={required}
                onChange={() =>
                  onChange({
                    id,
                    referenceId,
                    required: !required,
                    title,
                    options,
                    scaleRange,
                    justification,
                    answerFormat,
                    lowerLabel,
                    upperLabel,
                    // otherOption,
                  })
                }
              />
              <Divider spacing="xxxs" type="horizontal" />
              <Typography
                variant="body3"
                color="var(--color-neutral-30)"
                fontWeight={600}
              >
                Pergunta obrigatória
              </Typography>
            </FieldRow>
          </FieldContainer>
        ) : answerFormat &&
          answerFormat !== "text" &&
          answerFormat !== "date" ? (
          <>
            <Divider spacing="xs1" />
            {renderPreview()}
          </>
        ) : null}
      </ContentContainer>
    </Container>
  );
};

export { Question };
