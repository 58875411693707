import {
  Button,
  Icons,
  Modal,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";
import { Body, Footer, IconContainer } from "./styles";
import { SuccessModalProps } from "./types";

const { Root: ModalRoot } = Modal;

const SuccessModal = ({ open, date, onClose }: SuccessModalProps) => {
  return (
    <ModalRoot
      open={open}
      onClose={onClose}
      showClose={false}
      children={
        <Body>
          <IconContainer>
            <Icons
              name="IconCircleCheck"
              color="var(--color-feedback-success-40)"
            />
            <Typography
              variant="body4"
              variantColor="var(--color-feedback-success-40)"
              weight={700}
            >
              Tudo certo!
            </Typography>
          </IconContainer>
          <Typography
            variant="headline8"
            variantColor="var(--color-neutral-10)"
            weight={700}
          >
            Destinatários adicionados com sucesso
          </Typography>
          <Typography
            variant="body4"
            variantColor="var(--color-neutral-40)"
            weight={400}
          >
            A pesquisa será <b>enviada em {date} aos destinatários.</b>
          </Typography>
        </Body>
      }
      footer={
        <Footer
          style={{
            justifyContent: "center",
          }}
        >
          <div>
            <Button
              variant={"primary"}
              variantType={"default"}
              size={"large"}
              onClick={onClose}
            >
              Voltar para Pesquisas
            </Button>
          </div>
        </Footer>
      }
    />
  );
};

export { SuccessModal };
