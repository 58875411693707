import { DataGrid } from "@components/DataGrid";

import { Button, dayjs, Icons } from "@flash-tecnologia/hros-web-ui-v2";
import { routes } from "@routes";
import { useNavigate, useParams } from "react-router-dom";

export default function ({
  getRecurrenceByIdLoading,
  recurrenceFilters,
  setRecurrenceFilters,
  occurrences,
}) {
  const { id = "" } = useParams();

  const navigate = useNavigate();

  return (
    <DataGrid
      loading={getRecurrenceByIdLoading}
      emptyState={{
        isFiltered: true,
        message: "Nenhum resultado encontrado",
      }}
      filterTags={{
        filters: recurrenceFilters,
        onFilter: (e) => setRecurrenceFilters(e),
        onClear: (e) => setRecurrenceFilters(e),
      }}
      columns={[
        {
          disableSortBy: true,
          id: "s-title",
          Header: "Data de envio",
          accessor: "sendDate",
          Cell: ({ value }: any) => {
            return (
              <>{value ? dayjs(value)?.format("DD/MM/YYYY - HH:mm") : ""}</>
            );
          },
        },
        {
          disableSortBy: true,
          id: "s-employee",
          Header: "Pessoas",
          accessor: "employees",
          Cell: ({ value }: any) => {
            return <>{value?.length || 0}</>;
          },
        },
        {
          disableSortBy: true,
          id: "s-view",
          Header: "Visualizações",
          accessor: "analytics",
          Cell: ({ value }: any) => {
            return <>{value?.visualization || 0}</>;
          },
        },
        // {
        //   disableSortBy: true,
        //   id: "s-link",
        //   Header: "Cliques em link",
        //   accessor: "analytics",
        //   Cell: ({ value }: any) => {
        //     return <>{value?.open || 0}</>;
        //   },
        // },
        // {
        //   disableSortBy: true,
        //   id: "s-react",
        //   Header: "Reações",
        //   accessor: "analytics",
        //   Cell: ({ value }: any) => {
        //     return <>{value?.likeCount || 0}</>;
        //   },
        // },
        {
          Header: "Ações",
          accessor: "_id",
          sticky: "right",
          minSize: 100,
          Cell: (e) => (
            // <Cell>
            // </Cell>
            // <IconContainer onClick={() => e?.row?.original?.onClick()}>
            //   <Button variant="secondary">
            //     Ver envio <Icons name="IconArrowRight" size={16} />
            //   </Button>
            // </IconContainer>
            <Button
              variant="secondary"
              style={{ minWidth: "173px" }}
              onClick={() => {
                console.log(e);
                navigate(
                  `/engagement/survey/feeling/recurrence/${id}/occurence/${e?.row?.original?._id}`
                );
              }}
            >
              Ver envio <Icons name="IconArrowRight" size={16} />
            </Button>
          ),
        },
      ]}
      data={occurrences || []}
    />
  );
}
