import { Typography } from "@components/Typography";
import { Container } from "./styles";
import { Icons } from "@flash-tecnologia/hros-web-ui-v2";

const ConfidentialityInfo = () => (
  <Container>
    <Icons name="IconShieldLock" color="var(--color-neutral-100)" size={14} />
    <Typography
      variant="body4"
      fontWeight={400}
      color="var(--color-neutral-100)"
    >
      Sua resposta é anônima
    </Typography>
  </Container>
);

export { ConfidentialityInfo };
