import { GenericBox } from "@components/GenericBox";
import { GraphOneVerticalBar } from "@components/Graphs";
import { GraphEmptyState } from "@components/Graphs/GraphEmptyState";
import { GraphHeader } from "@components/Graphs/GraphHeader";

export const FirstGraph = ({
  data,
}: {
  data: {
    info: { title: string; metric: string; explanation: string };
    data: any;
    settings: any;
  };
}) => {
  const info = data?.info;
  const graphData = data?.data;
  const settings = data?.settings;

  const isEmpty = graphData?.every((info: any) => info.amt == 0);

  return (
    <GenericBox size="full">
      <GraphHeader
        info={info}
        // reportName=""
      />
      {isEmpty ? (
        <GraphEmptyState />
      ) : (
        <GraphOneVerticalBar data={graphData} settings={settings} />
      )}
    </GenericBox>
  );
};
