import { Modal } from "@components/v2";
import { AutoReminderModalInterface } from "./types";

const AutoReminderModal = ({
  visible,
  onClose,
  onSubmit,
  loading,
  notViewed,
}: AutoReminderModalInterface) => (
  <Modal
    open={visible}
    onClose={onClose}
    title="Atenção!"
    subTitle={`Tem certeza que deseja parar <br/> o envio automático de <br/> notificações?`}
    description={`${
      notViewed || 0
    } pessoas ainda não responderam <br/> esta pesquisa e não serão mais notificadas.`}
    iconStatus="alert"
    closeButton
    iconName="IconAlertCircle"
    footer={{
      closeButton: {
        label: "Não enviar",
        onClick: () => onClose(),
        variant: "neutral",
      },
      submitButton: {
        label: "Notificar mesmo assim",
        loading: loading,
        onClick: () => onSubmit(),
        variant: "secondary",
        variantType: "error",
      },
    }}
  />
);

export default AutoReminderModal;
