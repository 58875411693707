import { GenericBox } from "@components/GenericBox";
import { GraphStackedBar, TGraphStackedBar } from "@components/Graphs";
import { GraphEmptyState } from "@components/Graphs/GraphEmptyState";
import { GraphHeader } from "@components/Graphs/GraphHeader";

export type TSecondGraph = {
  data: {
    info: { title: string; metric: string; explanation: string };
    // data: TGraphStackedBar["data"];
    // settings: TGraphStackedBar["settings"];
    settings: any;
    data: any;
  };
  // testCompanyId?: string;
};

export const SecondGraph = ({ data }: TSecondGraph) => {
  const info = data.info;
  const graphData = data.data;
  const settings = data.settings;

  const isEmpty = !graphData?.length;

  return (
    <GenericBox size="custom">
      <GraphHeader
        info={info}
        // reportName="FEELING_SECOND_GRAPH"
        // testCompanyId={testCompanyId}
      />
      {isEmpty ? (
        <GraphEmptyState />
      ) : (
        <GraphStackedBar data={graphData} settings={settings} />
      )}
    </GenericBox>
  );
};
