import styled from "styled-components";

const TagsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`;

const TagContainer = styled.div`
  margin-left: 10px;
  margin-bottom: 10px;
`;

const Cell = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--color-neutral-80);
  height: 40px;
  width: 40px;
  border-radius: 40px;
  cursor: pointer;
`;

export { TagsContainer, TagContainer, Cell, IconContainer };
