import { Link, useNavigate } from "react-router-dom";
import {
  Breadcrumbs,
  Icons,
  LinkButton,
  Stepper,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";
import { Container } from "./styles";
import { HeaderProps } from "./types";

const Header = ({ activeStep }: HeaderProps) => {
  const navigate = useNavigate();

  return (
    <Container>
      <Breadcrumbs
        separator={<Icons name="IconChevronRight" />}
        breadcrumbs={[
          <LinkButton
            variant="neutral"
            onClick={() => navigate("/engagement/survey")}
          >
            Pesquisas
          </LinkButton>,
          <Typography
            variant="body4"
            variantColor="var(--color-neutral-50)"
            weight={700}
            style={{ cursor: "pointer" }}
          >
            Adicionar destinatários
          </Typography>,
        ]}
      />
      <Stepper steps={["Destinatários", "Envio"]} activeStep={activeStep} />
    </Container>
  );
};

export { Header };
