import { Typography } from "../../../../../..";
import { Divider } from "../../../../../../v2";
import { SectionInterface } from "../../../../types";
import { Container, Table, Th, Td, TableContainer } from "./styles";

const TableReview = ({ sections }: { sections?: SectionInterface[] }) => {
  const formats = {
    text: "Texto",
    date: "Data",
    "multiple-choice": "Múltipla escolha",
    "select-box": "Caixa de seleção",
    "multi-select": "Seleção Multi-Tags",
    dropdown: "Lista suspensa",
    emoji: "Reação (Emoji)",
    heart: "Reação (Coração)",
    star: "Reação (Estrela)",
    scale: "Escala Numérica",
  };

  return (
    <Container>
      {sections?.map((section, i) => (
        <TableContainer key={`section-${section?.id}-${i}`}>
          <Typography
            variant="headline8"
            color="var(--color-neutral-30)"
            fontWeight={700}
          >
            {section?.title}
          </Typography>
          <Divider spacing="xxxs" />
          <Table>
            <tr>
              <Th style={{ width: "70%" }}>
                <Typography
                  variant="headline9"
                  color="var(--color-neutral-30)"
                  fontWeight={700}
                >
                  Perguntas
                </Typography>
              </Th>
              <Th style={{ width: "20%" }}>
                <Typography
                  variant="headline9"
                  color="var(--color-neutral-30)"
                  fontWeight={700}
                >
                  Formato da resposta
                </Typography>
              </Th>
              <Th>
                <Typography
                  variant="headline9"
                  color="var(--color-neutral-30)"
                  fontWeight={700}
                >
                  Obrigatória
                </Typography>
              </Th>
            </tr>
            {section?.questions?.map((question, i) => (
              <tr key={`question-${question?.id}-${i}`}>
                <Td>
                  <Typography
                    variant="body3"
                    color="var(--color-neutral-40)"
                    fontWeight={600}
                  >
                    {question?.title || ""}
                  </Typography>
                </Td>
                <Td>
                  <Typography
                    variant="body3"
                    color="var(--color-neutral-30)"
                    fontWeight={600}
                  >
                    {question?.answerFormat
                      ? formats[question?.answerFormat]
                      : ""}
                  </Typography>
                </Td>
                <Td>
                  <Typography
                    variant="body3"
                    color="var(--color-neutral-30)"
                    fontWeight={600}
                  >
                    {question?.required ? "Sim" : "Não"}
                  </Typography>
                </Td>
              </tr>
            ))}
          </Table>
        </TableContainer>
      ))}
    </Container>
  );
};

export { TableReview };
