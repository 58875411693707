import { useNavigate } from "react-router-dom";
import { NotificationCardV2 } from "@components/NotificationCardV2";
import { Typography } from "../../../..";
import { Divider } from "../../../../v2";
import {
  ChildrenNotificationCard,
  Container,
  Description,
  Title,
} from "./styles";
import { StepDescriptionInterface } from "./types";
import { LinkButton } from "@flash-tecnologia/hros-web-ui-v2";

const StepDescription = ({
  model,
  title,
  description,
  anonymous,
  currentStep,
  children,
  width,
  notifications,
  containerStyle = {},
  recipients,
  onClick,
}: StepDescriptionInterface) => {
  const navigate = useNavigate();

  const wrapText = (text: string) => (
    <>
      {text?.split("<br/>")?.map((t, i) => (
        <Description key={i} variant="body3">
          {t}
        </Description>
      ))}
    </>
  );

  return (
    <Container width={width} style={containerStyle}>
      <Title variant="headline7">{title}</Title>
      <Divider spacing={"xxs2"} />
      {wrapText(description)}
      {model === "engaja" && currentStep === 4 ? (
        <div style={{ width: "100%" }}>
          <Divider spacing={"xxs3"} />
          <NotificationCardV2
            closeButton
            autoClose
            variant="contained"
            type={"primary" as any}
            title={"Dica da Flash!"}
            subtitle="Para resultados mais precisos, garanta que as pessoas estejam em departamentos e/ou grupos."
            iconName={"IconBulb"}
          />
        </div>
      ) : null}
      {model !== "custom" && model ? (
        <div style={{ width: "100%" }}>
          <Divider spacing={"xxs3"} />
          <NotificationCardV2
            variant="outlined"
            type={"info"}
            title={`Pesquisa a partir do modelo "${
              {
                enps: "eNPS",
                climate: "Pesquisa de Engajamento",
                engaja: "Engaja S/A",
                feeling: "Pesquisa de Sentimento",
                voluntaryExit: "Pesquisa de Desligamento Voluntário",
                involuntaryExit: "Pesquisa de Desligamento Involuntário",
              }[model]
            }"`}
            iconName={"IconAlertTriangle"}
          />
        </div>
      ) : null}
      {["enps", "climate", "engaja"]?.includes(model || "") &&
        currentStep === 4 && (
          <div style={{ width: "100%" }}>
            <Divider spacing={"xxs3"} />
            <NotificationCardV2
              variant="outlined"
              type={"info"}
              title="Seleção de pessoas"
              subtitle={
                <>
                  Por essa pesquisa ser anônima, para que possamos apresentar os
                  resultados da pesquisa garantindo a segurança,
                  <strong>
                    {" "}
                    a pesquisa deve ser enviada para pelo menos 3 pessoas.
                  </strong>
                </>
              }
              iconName={"IconAlertTriangle"}
            />
          </div>
        )}
      {anonymous && !["enps", "climate", "engaja"]?.includes(model || "") ? (
        <div style={{ width: "100%" }}>
          <Divider spacing={"xxs3"} />
          <NotificationCardV2
            variant="contained"
            type={"info"}
            title={`O anonimato está habilitado`}
            subtitle="Para garantir a segurança da informação a pesquisa deve ser enviada para pelo menos 3 pessoas."
            iconName={"IconAlertTriangle"}
          />
        </div>
      ) : null}
      {currentStep === 4 &&
      notifications?.email &&
      recipients?.some((r) => !r?.corporateEmail) ? (
        <div style={{ width: "100%" }}>
          <Divider spacing={"xxs3"} />
          <NotificationCardV2
            children={
              <ChildrenNotificationCard>
                <Typography
                  variant="body3"
                  fontWeight={600}
                  color="var(--color-feedback-info-10)"
                >
                  Destinatários sem e-mails corporativos cadastrado
                </Typography>
                <Typography
                  variant="body4"
                  fontWeight={400}
                  color="var(--color-feedback-info-10)"
                >
                  Alguns destinatários não estão associados a um e-mail
                  corporativo, impedindo o envio da comunicação por e-mail.
                  Clique no link abaixo para atualizar os e-mails destes
                  destinatários.
                </Typography>
                <LinkButton
                  variant={"neutral"}
                  onClick={() => (onClick ? onClick() : null)}
                >
                  Atualizar destinatários
                </LinkButton>
              </ChildrenNotificationCard>
            }
            variant="contained"
            type={"info"}
            title={`Destinatários sem e-mails corporativos cadastrados`}
            subtitle="Alguns destinatários não estão associados a um e-mail corporativo, impedindo o envio da comunicação por e-mail. Clique no link abaixo para atualizar os e-mails destes destinatários."
            iconName={"IconAlertTriangle"}
          />
        </div>
      ) : null}
      {children ? (
        <>
          <Divider spacing={"xxs3"} />
          {children}
        </>
      ) : null}
    </Container>
  );
};

export { StepDescription };
