import React, { useMemo } from "react";
import { Grid } from "@mui/material";
import { useParams } from "react-router-dom";
import { useTheme } from "styled-components";

import { trpc } from "@api/client";
import { Metrics } from "./styles";
import { FirstGraph } from "./graphs/FirstGraph";
import { generateSurveyDataByModel } from "@components/EngagementForm/common/components/generateSurveyDataByModel";
import { graphs } from "./data";
import { VerticalBarChart } from "@components/VerticalBarChart";

interface AnalyticsResult {
  monitoring_id: string;
  question_1_value: string;
  question_3_value: Array<{ value: string }>;
  question_4_value: Array<{ value: string }>;
}

export default function EngagementMetrics({ employeesMonitorings = [] }: any) {
  const { id = "", occurenceId = "" } = useParams();

  const theme = useTheme();
  const { data } = trpc.getFeelingAnalytics.useQuery({
    engagementId: occurenceId || id,
  });
  const engagement = data?.find((e) => e?.engagement_id === occurenceId || id);

  const getEmployeeMonitoringIds = (monitorings) =>
    monitorings.flatMap((em) =>
      em.monitorings.map((monitoring) => monitoring._id)
    );

  const filteredAnalyticsResults = useMemo(() => {
    const employeesMonitoringsIds =
      getEmployeeMonitoringIds(employeesMonitorings);

    return (
      data?.result?.filter((db) =>
        employeesMonitoringsIds.includes(db.monitoring_id)
      ) || []
    );
  }, [data, employeesMonitorings]);

  const feelingModel = generateSurveyDataByModel("feeling");

  const getQuestionOptions = (referenceId: string) =>
    feelingModel[0].questions
      ?.find((question) => question.referenceId === referenceId)
      ?.options?.map((option) => option?.value || "") || [""];

  const secondGraphOptions = getQuestionOptions("feeling_survey_what_was_good");
  const thirdGraphOptions = getQuestionOptions(
    "feeling_survey_what_could_improve"
  );

  const validNumericValues = useMemo(
    () =>
      filteredAnalyticsResults
        .map((item) => parseFloat(item.question_1_value))
        .filter((value) => !isNaN(value)),
    [filteredAnalyticsResults]
  );

  const graphsData = useMemo(() => {
    const createGraphData = (values: number[], maxValue: number) =>
      Array.from({ length: maxValue }, (_, i) => ({
        name: `${i + 1}`,
        amt: values.filter((value) => value === i + 1).length,
      }));

    const createOptionGraph = (
      options: string[],
      questionKey: keyof Pick<
        AnalyticsResult,
        "question_3_value" | "question_4_value"
      >
    ) =>
      options.map((option) => ({
        name: option,
        amt: filteredAnalyticsResults.filter((result) =>
          result[questionKey]?.some(
            (q: { value: string }) => q.value === option
          )
        ).length,
      }));

    return {
      secondGraph:
        engagement?.sections[0]?.questions[2]?.options?.map((o) => ({
          name: o?.value,
          amt:
            engagement?.monitorings?.reduce((a, c) => {
              const value = c?.sections[0]?.questions[2]?.value;

              if (value) {
                const parsedValue = JSON.parse(value);
                return a + parsedValue?.filter((v) => v === o?.id)?.length || 0;
              }

              return a;
            }, 0) || 0,
        })) || [],
      thirdGraph:
        engagement?.sections[0]?.questions[3]?.options?.map((o) => ({
          name: o?.value,
          amt:
            engagement?.monitorings?.reduce((a, c) => {
              const value = c?.sections[0]?.questions[3]?.value;

              if (value) {
                const parsedValue = JSON.parse(value);
                return a + parsedValue?.filter((v) => v === o?.id)?.length || 0;
              }

              return a;
            }, 0) || 0,
        })) || [],
    };
  }, [
    filteredAnalyticsResults,
    validNumericValues,
    secondGraphOptions,
    thirdGraphOptions,
  ]);

  const graphsValues = graphs({ theme, ...graphsData });

  return (
    <Metrics>
      <Grid
        item
        xs={12}
        sm={6}
        lg={6}
        gap={2}
        display="flex"
        flexDirection="column"
        padding="0 16px 16px 0"
      >
        <VerticalBarChart
          title="Como você se sentiu essa semana?"
          data={
            [1, 2, 3, 4, 5]?.map((i) => ({
              name: `${i}`,
              value:
                engagement?.monitorings?.reduce((a, m) => {
                  const question = m?.sections[0]?.questions[0]?.value;
                  if (question) {
                    const value = parseInt(question);
                    if (value === i) return a + 1;
                    return a;
                  }
                  return a;
                }, 0) || 0,
              label: `${i}-label`,
            })) || []
          }
        />
        {graphsValues?.map((graphData, index) => (
          <FirstGraph key={index} data={graphData} />
        ))}
      </Grid>
    </Metrics>
  );
}
