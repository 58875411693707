import { dayjs } from "@flash-tecnologia/hros-web-ui-v2";
import { Typography } from "@components/Typography";
import { LineSeparator } from "@components/LineSeparator";
import { BorderIcon } from "@components/BorderIcon";
import { TextContainer } from "../SurveyReview/styles";
import { Container, InfoContainer } from "./styles";
import { SurveyInfoInterface } from "./types";

const SurveyInfo = ({
  title,
  sections,
  model,
  sendDate,
  interactionDeadline,
  anonymous,
  description,
}: SurveyInfoInterface) => {
  const totalSections = sections?.length || 0;
  const totalQuestions =
    sections?.reduce((acc, { questions }) => acc + questions?.length || 0, 0) ||
    0;

  return (
    <Container>
      <InfoContainer>
        <Typography
          variant="headline7"
          fontWeight={700}
          color="var(--color-neutral-20)"
          style={{ marginBottom: "12px" }}
        >
          {title}
        </Typography>
        <Typography
          variant="body4"
          fontWeight={400}
          color="var(--color-neutral-40)"
          style={{ marginBottom: "12px", marginTop: "12px" }}
        >
          {`Recebida em ${dayjs(sendDate)?.format("DD/MM/YYYY")}`}
        </Typography>
        <LineSeparator />
        <TextContainer style={{ marginBottom: "12px", marginTop: "12px" }}>
          <BorderIcon
            name="IconMessage2"
            size={14}
            color="var(--color-secondary-50)"
            style={{ padding: "4px", borderColor: "var(--color-secondary-95)" }}
          />
          <Typography
            variant="body4"
            fontWeight={600}
            color="var(--color-secondary-50)"
          >
            {`${totalSections} ${
              totalQuestions > 1 ? "seções" : "seção"
            } e ${totalQuestions} ${
              totalQuestions > 1 ? "perguntas" : "pergunta"
            }`}
          </Typography>
        </TextContainer>
        {interactionDeadline ? (
          <TextContainer style={{ marginBottom: "16px" }}>
            <BorderIcon
              name="IconCalendar"
              size={14}
              color="var(--color-secondary-50)"
              style={{
                padding: "4px",
                borderColor: "var(--color-secondary-95)",
              }}
            />
            <Typography
              variant="body4"
              fontWeight={600}
              color="var(--color-secondary-50)"
            >
              Prazo de resposta:{" "}
            </Typography>
            {sendDate ? (
              <Typography
                variant="body4"
                fontWeight={600}
                color="var(--color-secondary-50)"
              >
                {dayjs(sendDate)?.format("DD/MM/YYYY")}
                {" - "}
              </Typography>
            ) : null}
            <Typography
              variant="body4"
              fontWeight={600}
              color="var(--color-secondary-50)"
            >
              {dayjs(interactionDeadline)?.format("DD/MM/YYYY")}
            </Typography>
          </TextContainer>
        ) : null}
        {model === "engaja" ? (
          <TextContainer style={{ marginBottom: "12px", marginTop: "12px" }}>
            <BorderIcon
              name="IconMessage2"
              size={14}
              color="var(--color-secondary-50)"
              style={{
                padding: "4px",
                borderColor: "var(--color-secondary-95)",
              }}
            />
            <Typography
              variant="body4"
              fontWeight={600}
              color="var(--color-secondary-50)"
            >
              Tempo estimado de duração: cerca de 15 min
            </Typography>
          </TextContainer>
        ) : null}
        {anonymous ? (
          <TextContainer>
            <BorderIcon
              name="IconShieldLock"
              size={14}
              color="var(--color-secondary-50)"
              style={{
                padding: "4px",
                borderColor: "var(--color-secondary-95)",
              }}
            />
            <Typography
              variant="body4"
              fontWeight={600}
              color="var(--color-secondary-50)"
            >
              Pesquisa anônima
            </Typography>
          </TextContainer>
        ) : null}
        {description ? (
          <>
            <LineSeparator style={{ marginTop: "12px" }} />
            {model === "engaja" ? (
              <div style={{ marginTop: "12px" }}>
                <Typography
                  variant="body4"
                  fontWeight={400}
                  color="var(--color-neutral-40)"
                  style={{ marginBottom: "12px" }}
                >
                  Nesta pesquisa, buscamos entender o nível de engajamento de
                  nossos colaboradores, mapeando desafios, motivações,
                  oportunidades e outros fatores que influenciam sua conexão com
                  a nossa organização. Com os dados coletados, queremos criar um
                  índice que nos ajude a medir e aprimorar o engajamento. A
                  pesquisa é dividida em:
                </Typography>
                <div style={{ marginLeft: "10px" }}>
                  <Typography
                    variant="body4"
                    fontWeight={400}
                    color="var(--color-neutral-40)"
                  >
                    <strong>- Informações Pessoais:</strong> Coletamos
                    informações demográficas e profissionais para garantir uma
                    análise mais detalhada e segmentada, ajudando a identificar
                    padrões e gerar insights relevantes para diferentes grupos
                    de colaboradores.
                  </Typography>
                  <Typography
                    variant="body4"
                    fontWeight={400}
                    color="var(--color-neutral-40)"
                  >
                    <strong>- Atributos do Engajamento:</strong> Responda sobre
                    como você se sente atualmente em relação à empresa,
                    avaliando seis dimensões do engajamento.
                  </Typography>
                  <Typography
                    variant="body4"
                    fontWeight={400}
                    color="var(--color-neutral-40)"
                  >
                    <strong>- Ações:</strong> Avalie como ações do RH
                    impactariam seu engajamento, mesmo que sua empresa não
                    implemente essas iniciativas.
                  </Typography>
                  <Typography
                    variant="body4"
                    fontWeight={400}
                    color="var(--color-neutral-40)"
                    style={{ marginBottom: "12px" }}
                  >
                    <strong>- eNPS:</strong> Avaliamos a satisfação e
                    recomendação dos colaboradores por meio do eNPS (Employee
                    Net Promoter Score), que ajuda a medir o nível de satisfação
                    dos funcionários.
                  </Typography>
                </div>
                <Typography
                  variant="body4"
                  fontWeight={400}
                  color="var(--color-neutral-40)"
                >
                  <strong>Consentimento qualificado:</strong> estou ciente de
                  que alguns dados coletados são sensíveis, nos termos da LGPD,
                  especialmente aqueles relacionados ao tema da pesquisa.
                </Typography>
              </div>
            ) : (
              <Typography
                variant="body4"
                fontWeight={400}
                color="var(--color-neutral-40)"
                style={{ marginTop: "12px" }}
              >
                {description}
              </Typography>
            )}
          </>
        ) : null}
      </InfoContainer>
    </Container>
  );
};

export { SurveyInfo };
