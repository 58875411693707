import { useState } from "react";
import {
  Icons,
  Table,
  tableControllers,
} from "@flash-tecnologia/hros-web-ui-v2";
import { PaginationState } from "@flash-tecnologia/hros-web-ui-v2/dist/components/Table/components/Pagination";
import { QuestionTableInterface, TableDataType } from "./types";
import { Cell, IconContainer, TagContainer, TagsContainer } from "./styles";

const QuestionTable = ({ data }: QuestionTableInterface) => {
  const [pagination, setPagination] = useState<PaginationState>({
    pageNumber: 1,
    pageSize: 5,
  });

  const count = data.length;

  const finalData = data;
  const table = tableControllers.useTableColumns<TableDataType>({
    defaultColumn: {
      minSize: 200,
    },
    total: count,
    columns: [
      {
        header: "Pergunta",
        accessorKey: "title",
        cell: (e) => <>{e?.row?.original?.title}</>,
      },
      {
        header: "Resposta",
        accessorKey: "answer",
        cell: (e) => {
          const answer = e?.row?.original?.answer;

          if (typeof answer === "string" || typeof answer === "number")
            return <>{answer}</>;

          if (Array.isArray(answer)) {
            return (
              <>
                {answer?.reduce(
                  (a, c) => `${a}${a?.length ? ", " : ""}${c}`,
                  ""
                )}
              </>
            );
          }

          return <></>;
        },
      },
      {
        header: "Ações",
        accessorKey: "id",
        sticky: "right",
        minSize: 100,
        cell: (e) => (
          <Cell>
            <IconContainer onClick={() => e?.row?.original?.onClick()}>
              <Icons name="IconPencil" size={16} />
            </IconContainer>
          </Cell>
        ),
      },
    ],
    data: finalData,
    pagination: pagination,
    onPaginationChange: setPagination,
  });

  return (
    <Table.Root>
      <Table.Grid.Root loading={false}>
        <Table.Grid.Header getHeaderGroups={table.getHeaderGroups} />
        {table.rows.map((row, index) => (
          <Table.Grid.Row key={index + row.id} row={row} />
        ))}
      </Table.Grid.Root>
    </Table.Root>
  );
};

export { QuestionTable };
