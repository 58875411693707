import { Typography } from "../../../Typography";
import { ColorBlockInterface } from "./types";
import { Container, Block, BlockContainer } from "./styles";

const ColorBlock = ({ data }: ColorBlockInterface) => (
  <Container>
    <Typography
      variant="caption"
      color="var(--color-neutral-30)"
      fontWeight={600}
      style={{ marginTop: "22px", marginRight: "8px" }}
    >
      Ativamente <br /> desengajado
    </Typography>
    {data?.map(({ label, color }, i) => (
      <BlockContainer key={i}>
        <Typography variant="body4" color="var(--color-neutral-30)">
          {label}
        </Typography>
        <Block
          color={color}
          style={
            i === 0
              ? {
                  borderLeft: "1px solid var(--color-neutral-95)",
                  borderTopLeftRadius: "4px",
                  borderBottomLeftRadius: "4px",
                }
              : i === data?.length - 1
              ? {
                  borderTopRightRadius: "4px",
                  borderBottomRightRadius: "4px",
                }
              : {}
          }
        />
      </BlockContainer>
    ))}
    <Typography
      variant="caption"
      color="var(--color-neutral-30)"
      fontWeight={600}
      style={{ marginTop: "22px", marginLeft: "8px" }}
    >
      Engajado
    </Typography>
  </Container>
);

export { ColorBlock };
