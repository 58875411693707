import { dayjs } from "@flash-tecnologia/hros-web-ui-v2";
import { useTranslation } from "react-i18next";
import { CardInfo } from "@components/index";
import { DetailInterface } from "./types";
import { PreviewAside, PreviewContainer } from "./styles";
import { TagList } from "@components/TagList";
import { trpc } from "@api/client";

const Detail = ({ data }: DetailInterface) => {
  const [t] = useTranslation("translations", {
    keyPrefix: "page",
  });

  const { data: creator } = trpc.getEmployeeById.useQuery(
    { id: data?.createdBy ?? "" },
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: !!data?.createdBy,
    }
  );

  return (
    <PreviewContainer>
      <PreviewAside>
        <CardInfo
          style={{ flex: 1 }}
          direction="column"
          title="Informações básicas"
          data={[
            {
              label: "Modelo de pesquisa",
              value: {
                custom: "Personalizado",
                climate: "Engajamento",
                enps: "eNPS",
                engaja: "Engaja S/A",
                feeling: "Pesquisa Sentimento",
              }[data?.model || "custom"],
            },
            {
              label: "Data de criação",
              value: data?.createdAt
                ? dayjs(data?.createdAt)?.format("DD/MM/YYYY - HH:mm")
                : "-",
            },
            {
              label: "Título da pesquisa",
              value: data?.name || "-",
            },
            {
              label: "Título de exibição pública",
              value: data?.title || "-",
            },
            {
              label: "Descrição",
              value: data?.description || "-",
            },
          ]}
        />
      </PreviewAside>
      <PreviewAside style={{ flex: 1 }}>
        <CardInfo
          style={{ flex: 1 }}
          direction="column"
          title="Informações de envio"
          data={
            data?.recurrence
              ? [
                  {
                    label: "Recorrência",
                    value:
                      data?.recurrence?.frequency && data?.recurrence?.repeat
                        ? `a cada ${data?.recurrence?.repeat} ${t(
                            data?.recurrence?.repeat > 1
                              ? `${data?.recurrence?.frequency}s`
                              : data?.recurrence?.frequency
                          )}`
                        : "-",
                  },
                  {
                    label: "Dias de envio",
                    value: data?.recurrence?.daysOfTheWeek?.length
                      ? `${data?.recurrence?.daysOfTheWeek?.reduce(
                          (a, c, i) => {
                            return `${a} ${t(c)}${
                              i ===
                              (data?.recurrence?.daysOfTheWeek?.length || 0) - 1
                                ? ""
                                : ","
                            }`;
                          },
                          ""
                        )}`
                      : "-",
                  },
                  {
                    label: "Período",
                    value: data?.startDate?.period
                      ? t(data?.startDate?.period)
                      : "-",
                  },
                  {
                    label: "Início da recorrência",
                    value: data?.startDate?.date
                      ? dayjs(data?.startDate?.date)?.format("DD/MM/YYYY")
                      : "-",
                  },
                  {
                    label: "Término da recorrência",
                    value: data?.recurrence
                      ? data?.recurrence?.endDate
                        ? dayjs(data?.recurrence?.endDate)?.format("DD/MM/YYYY")
                        : data?.recurrence?.occurrenceLimit === -1
                        ? "Nunca"
                        : data?.recurrence?.occurrenceLimit &&
                          data?.recurrence?.occurrenceLimit > 0
                        ? `Depois de ${data?.recurrence?.occurrenceLimit} envios`
                        : "-"
                      : "-",
                  },
                  {
                    label: "Prazo de resposta",
                    value:
                      (data?.recurrence?.interactionDeadlineConfig
                        ?.calculatedDate?.multiplier &&
                        data?.recurrence?.interactionDeadlineConfig
                          ?.calculatedDate?.period) ||
                      data?.recurrence?.interactionDeadlineConfig?.nextMode
                        ? `${
                            data?.recurrence?.interactionDeadlineConfig
                              ?.nextMode
                              ? "Até o próximo envio"
                              : `Até ${
                                  data?.recurrence?.interactionDeadlineConfig
                                    ?.calculatedDate?.multiplier
                                } ${t(
                                  `${
                                    data?.recurrence?.interactionDeadlineConfig
                                      ?.calculatedDate?.period || "day"
                                  }${
                                    data?.recurrence?.interactionDeadlineConfig
                                      ?.calculatedDate?.multiplier &&
                                    data?.recurrence?.interactionDeadlineConfig
                                      ?.calculatedDate?.multiplier > 1
                                      ? "s"
                                      : ""
                                  }`
                                )}`
                          } após a data de cada envio`
                        : "-",
                  },
                ]
              : [
                  {
                    label: "Data de envio",
                    value: data?.sendDate
                      ? dayjs(data?.sendDate)?.format("DD/MM/YYYY - HH:mm")
                      : "-",
                  },
                  {
                    label: "Prazo de respostas",
                    value: data?.interactionDeadline
                      ? dayjs(data?.interactionDeadline)?.format("DD/MM/YYYY")
                      : "-",
                  },
                ]
          }
        />
        <CardInfo
          style={{ flex: 1 }}
          direction="column"
          title="Configurações"
          data={[
            {
              label: "Anonimato",
              value: data?.anonymous
                ? "Habilitado (as pessoas não serão identificadas)"
                : "Não habilitado (as pessoas serão identificadas)",
            },
            {
              label: "Notificações",
              value:
                data?.notifications?.push || data?.notifications?.email
                  ? `Habilitado - ${
                      data?.notifications?.push ? "Push no aplicativo" : ""
                    } ${
                      data?.notifications?.push && data?.notifications?.email
                        ? " e "
                        : ""
                    } ${data?.notifications?.email ? "E-mail corporativo" : ""}`
                  : "Desabilitado",
            },
            {
              label: "Envio da notificação",
              value:
                data?.notifications?.reminder?.enabled &&
                data?.notifications?.reminder?.minRate
                  ? `Notificar a cada 3 dias até atingir a taxa de resposta de ${data?.notifications?.reminder?.minRate}%`
                  : "Desabilitado",
            },
            {
              label: "Criador",
              value: creator?.name ?? "",
            },
            {
              label: "Responsáveis",
              value: !data?.owners.map((o) => o.id).includes("*") ? (
                <TagList
                  tags={
                    data?.owners?.map((owner) => ({
                      id: owner?.id,
                      label: owner?.name,
                    })) ?? []
                  }
                />
              ) : (
                "Todos com acesso ao módulo"
              ),
            },
          ]}
        />
      </PreviewAside>
    </PreviewContainer>
  );
};

export { Detail };
