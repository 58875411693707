import { useCallback, useState } from "react";
import { Typography } from "../Typography";
import { ColorBlock } from "./components";
import { HeatMapInterface, DrawerContentType } from "./types";
import {
  Container,
  TableContainer,
  TD,
  TH,
  Table,
  Border,
  RequiredMessageContainer,
  Hover,
  HoverBorder,
  TR,
  THContent,
} from "./styles";
import { Divider } from "../v2";
import { Asterisk, Drawer } from "..";
import { Icons, Tooltip } from "@flash-tecnologia/hros-web-ui-v2";
import { TooltipText } from "@components/Card/styles";
import { segment as segmentTrack } from "@utils";

const HeatMap = ({
  tableId,
  columns,
  rows,
  segment,
  drawerProps,
}: HeatMapInterface) => {
  const [isOpen, setIsOpen] = useState<
    [boolean, DrawerContentType | undefined]
  >([false, undefined]);

  const segmentLabel =
    segment === "departments"
      ? "Departamentos"
      : segment === "groups"
      ? "Grupos"
      : "Cargos";
  const getColorByTemperature = useCallback((t: number) => {
    if (t <= 2) return "var(--color-neutral-100)";
    if (t > 2 && t <= 3) return "var(--color-secondary-99)";
    if (t > 3 && t <= 4) return "var(--color-secondary-95)";
    if (t > 4 && t < 5) return "var(--color-secondary-90)";
    return "var(--color-secondary-80)";
  }, []);

  const renderValue = useCallback((num: number) => {
    if (num < 0) return "Anônimo";

    const value = `${num}`;

    if (!value?.includes(".")) return value;

    const roundedValue = parseFloat(value).toFixed(2);

    return roundedValue;
  }, []);

  return (
    <>
      <Container>
        <TableContainer id={tableId}>
          <Table>
            <thead>
              <TR>
                <TH scope="col">
                  <THContent
                    style={{ borderRight: "1px solid var(--color-neutral-90)" }}
                  >
                    <Typography
                      variant="body3"
                      fontWeight={700}
                      color="var(--color-neutral-20)"
                    >
                      {`Dimensões x ${segmentLabel}`}
                    </Typography>
                  </THContent>
                </TH>
                {columns?.map((col, i) => (
                  <TH
                    key={`col-${i}`}
                    scope="col"
                    className={`${i < 2 ? "main-border" : ""}`}
                  >
                    <THContent>
                      <Typography
                        variant="body3"
                        fontWeight={700}
                        color="var(--color-neutral-30)"
                      >
                        {col}
                      </Typography>
                      {rows?.some(({ values }) =>
                        values
                          ?.filter((_, valueIndex) => valueIndex === i)
                          ?.some((v) => v === -1)
                      ) ? (
                        <Tooltip
                          style={{ marginLeft: "2px" }}
                          placement="top"
                          title={
                            <>
                              <TooltipText variant="body4">
                                Buscando respeitar a identidade dos
                                respondentes, {segmentLabel} com menos de 3
                                pessoas não apresentam respostas.
                              </TooltipText>
                            </>
                          }
                        >
                          <div>
                            <Icons
                              size={18}
                              name={"IconInfoCircle"}
                              fill={"transparent"}
                              color={"var(--color-neutral-dark3)"}
                            />
                          </div>
                        </Tooltip>
                      ) : (
                        ""
                      )}
                      {col === "Mercado" ? (
                        <Tooltip
                          style={{ marginLeft: "2px" }}
                          placement="top"
                          title={
                            <>
                              <TooltipText variant="body4">
                                O mercado compreende os resultados <br /> de
                                todas as empresas participantes da <br />{" "}
                                pesquisa.
                              </TooltipText>
                            </>
                          }
                        >
                          <div>
                            <Icons
                              size={18}
                              name={"IconInfoCircle"}
                              fill={"transparent"}
                              color={"var(--color-neutral-dark3)"}
                            />
                          </div>
                        </Tooltip>
                      ) : null}
                    </THContent>
                  </TH>
                ))}
              </TR>
            </thead>
            <tbody>
              {rows?.map(({ label, values }, rowIndex) => (
                <TR key={`th-${rowIndex}`}>
                  <TD
                    style={{
                      zIndex: 1,
                    }}
                  >
                    <Border>
                      <Typography
                        variant="body3"
                        fontWeight={600}
                        color="var(--color-neutral-30)"
                      >
                        {label || ""}
                      </Typography>
                    </Border>
                  </TD>
                  {Array(columns?.length)
                    ?.fill(0)
                    ?.map((intialValue, columnIndex) => {
                      const value = values[columnIndex] || intialValue;
                      const isDrawerTrigger =
                        drawerProps?.columnsIndexes.includes(columnIndex);

                      return (
                        <TD
                          key={`th-${rowIndex}-td-${columnIndex}`}
                          color={
                            value === -1
                              ? "var(--color-neutral-90)"
                              : getColorByTemperature(value)
                          }
                          style={
                            rowIndex === rows?.length - 1 &&
                            columnIndex === values?.length - 1
                              ? {
                                  borderBottomRightRadius: "12px",
                                }
                              : {}
                          }
                        >
                          <Hover>
                            {isDrawerTrigger && value !== -1 ? (
                              <HoverBorder
                                onClick={() => {
                                  setIsOpen((prev) => [
                                    !prev[0],
                                    { label: label, index: rowIndex },
                                  ]);

                                  const dimension = [
                                    "jobmeaning",
                                    "goodmanagementpractices",
                                    "positiveworkenvironment",
                                    "growthopportunity",
                                    "trustintopleadership",
                                    "compensationandbenefits",
                                  ][rowIndex];

                                  if (dimension) {
                                    segmentTrack({
                                      track: `people_strategic_hr_engagement_company_engajadetails_analysis_attributes_${dimension}_clicked`,
                                    });
                                  }
                                }}
                              />
                            ) : null}
                            <>
                              <Typography
                                variant="body3"
                                fontWeight={700}
                                color={
                                  value === -1
                                    ? "var(--color-neutral-30)"
                                    : "var(--color-neutral-10)"
                                }
                              >
                                {renderValue(value)}
                              </Typography>
                              {isDrawerTrigger && value !== -1 ? (
                                <Icons
                                  name="IconChevronRight"
                                  color="var(--color-neutral-20)"
                                  size={16}
                                />
                              ) : null}
                            </>
                          </Hover>
                        </TD>
                      );
                    })}
                </TR>
              ))}
            </tbody>
          </Table>
        </TableContainer>
        <Divider spacing="xxs3" />
        <RequiredMessageContainer>
          <Asterisk />
          <Typography
            variant="body4"
            fontWeight={400}
            color={"var(--color-neutral-30)"}
          >
            Buscando respeitar a identidade dos respondentes, os{" "}
            {segmentLabel?.toLowerCase()} só exibem resultados quando há 3 ou
            mais respostas.
          </Typography>
        </RequiredMessageContainer>
        <Divider spacing="xs2" />
        <ColorBlock
          data={[
            {
              label: "1 - 2",
              color: "var(--color-neutral-100)",
            },
            {
              label: "> 2",
              color: "var(--color-secondary-99)",
            },
            {
              label: "> 3",
              color: "var(--color-secondary-95)",
            },
            {
              label: "> 4",
              color: "var(--color-secondary-90)",
            },
            {
              label: "= 5",
              color: "var(--color-secondary-80)",
            },
          ]}
        />
      </Container>
      {drawerProps && (
        <Drawer
          open={isOpen[0]}
          onClose={() => setIsOpen((prev) => [!prev[0], undefined])}
          content={drawerProps?.content(isOpen[1]).drawerContent}
          title={drawerProps?.content(isOpen[1]).title}
        />
      )}
    </>
  );
};

export { HeatMap };
