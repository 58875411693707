import { EmptyState } from "@flash-tecnologia/hros-web-ui-v2";
import { Typography } from "@components/index";

export default ({ tab }: { tab: "results" | "analysis" | "answers" }) => (
  <EmptyState
    title={
      ["results", "analysis"]?.includes(tab)
        ? "Dados não disponíveis"
        : "Nenhuma resposta disponível"
    }
    withoutButton
    description={
      ["results", "analysis"]?.includes(tab) ? (
        <div style={{ textAlign: "center" }}>
          <Typography
            variant="body3"
            fontWeight={400}
            color="var(--color-neutral-40)"
          >
            Os dados estão indisponíveis porque a pesquisa não atingiu
          </Typography>
          <Typography
            variant="body3"
            fontWeight={400}
            color="var(--color-neutral-40)"
          >
            a quantidade mínima de respostas ou o processamento não
          </Typography>
          <Typography
            variant="body3"
            fontWeight={400}
            color="var(--color-neutral-40)"
          >
            foi concluído. Por favor, aguarde.
          </Typography>
        </div>
      ) : (
        <div style={{ textAlign: "center" }}>
          <Typography
            variant="body3"
            fontWeight={400}
            color="var(--color-neutral-40)"
          >
            A pesquisa ainda não apresenta respostas.
          </Typography>
        </div>
      )
    }
  />
);
