import styled from "styled-components";

const MainContainer = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 32px;
`;

const ContentContainer = styled.div`
  margin: 32px 0;
`;

const EmployeeListContainer = styled.div`
  width: 100%;
`;

const Row = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 24px;
  width: 100%;
`;

export {
  MainContainer,
  ContentContainer,
  TitleContainer,
  EmployeeListContainer,
  Row,
};
