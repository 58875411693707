import styled from "styled-components";

const MainContainer = styled.div<{ open?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  max-width: 100%;
  height: 100%;
  z-index: 3 !important;
  margin-right: ${({ open }) => (open ? 0 : "-100%")};
  transition: 0.5s;
`;

const RelativeContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const Shadow = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #3b2e37;
  opacity: 0.65;
`;

const FloatContainer = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  padding: 0;
  margin: 0;
  width: 671px;
  height: 100%;
  max-width: 100%;
  background-color: var(--color-neutral-100);
`;

const Header = styled.div`
  position: relative;
  width: 100%;
  padding: 40px 40px 24px 40px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  gap: 8px;
  border-bottom: 1px solid var(--color-neutral-90);
`;

const ShapeIconContainer = styled.div`
  position: absolute;
  top: 24px;
  right: 24px;
  border: 1px solid var(--color-neutral-80);
  border-radius: 100%;
  cursor: pointer;

  svg {
    cursor: pointer;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  padding: 40px;
  height: calc(100% - 92px);
  overflow-x: auto;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export {
  MainContainer,
  RelativeContainer,
  Shadow,
  FloatContainer,
  Header,
  ShapeIconContainer,
  ContentContainer,
  TitleContainer,
};
