import { useNavigate } from "react-router-dom";
import { ShapeIcon, Tag, dayjs } from "@flash-tecnologia/hros-web-ui-v2";
import { trpc } from "@api/client";
import { DataGrid, Avatar } from "../../../../components";
import { Question } from "../../../../models/engagement/EngagementModel";
import { EmptyState } from "./components";
import { MonitoringDataGridInterface } from "./types";
import { TagContainer } from "./styles";
import { segment } from "@utils";

const MonitoringDataGrid = ({
  engagementId,
  data,
  employees,
  engagement,
  onSearch,
  empty,
}: MonitoringDataGridInterface) => {
  const navigate = useNavigate();

  const { refetch: getEngagementReportByEngagementId } =
    trpc?.getEngagementReportByEngagementId?.useQuery(
      {
        screen: "survey-answer",
        type: "survey",
        id: engagementId,
      },
      {
        enabled: false,
        retry: false,
        refetchOnWindowFocus: false,
        onSuccess: () => {
          if (
            [
              "ShY1SYEMjLQ-qyCMYklnu",
              "xjcQaaoNI__BZ1aAkvgtz",
              "3h8BQMy-fRc2nfCrLhyXE",
            ]?.includes(engagementId || "")
          )
            flashReport();
        },
        onError: (error: any) => {
          alert("Ocorreu um erro, tente novamente mais tarde.");
          console.log(error);
        },
      }
    );

  const { refetch: flashReport } =
    trpc?.getEngagementReportByEngagementId?.useQuery(
      {
        screen: "flash-report",
        type: "survey",
        id: engagementId,
      },
      {
        enabled: false,
        retry: false,
        refetchOnWindowFocus: false,
        onError: (error: any) => {
          console.log(error);
        },
      }
    );

  const getAllQuestions = () => {
    const allQuestions: Question[] = [];
    engagement?.sections
      ?.map(({ questions }) => questions)
      ?.forEach((questions) =>
        questions?.forEach((q) => (q ? allQuestions.push(q) : null))
      );

    return allQuestions;
  };

  const getColumns = () => {
    const questions = getAllQuestions();
    const columns = questions?.map(
      ({ id, title, answerFormat, options }, i) => ({
        sticky: "",
        Header: title || `question-${i}`,
        accessor: `accessor-${i}`,
        disableSortBy: true,
        Cell: ({ row }: any) => {
          if (id) {
            const { value, justification } =
              getQuestionValueByEmployeeIdAndQuestionId(row?.original?._id, id);

            if (answerFormat === "text") return <div>{value}</div>;
            if (
              answerFormat === "multiple-choice" ||
              answerFormat === "dropdown"
            ) {
              const v = options?.find((o) => o?.id === value)?.value;
              if (v || justification)
                return <Tag variant="gray">{v || justification}</Tag>;
              return <></>;
            }

            if (answerFormat === "date" && typeof value === "string")
              return value ? <>{dayjs(value)?.format("DD/MM/YYYY")}</> : <></>;
            if (
              ["scale", "emoji", "star", "heart"]?.includes(answerFormat || "")
            )
              return <div>{value}</div>;
            if (
              ["multi-select", "select-box"]?.includes(answerFormat || "") &&
              Array.isArray(value)
            )
              return (
                <div>
                  {value?.map((v, i) => {
                    const optionValue = options?.find(
                      (o) => o?.id === v
                    )?.value;

                    if (optionValue)
                      return (
                        <TagContainer key={`tag-container-${i}`}>
                          <Tag variant="gray">{optionValue}</Tag>
                        </TagContainer>
                      );
                    return <></>;
                  })}
                  {justification ? (
                    <TagContainer key={`tag-container-${i}`}>
                      <Tag variant="gray">{justification}</Tag>
                    </TagContainer>
                  ) : null}
                </div>
              );
          }

          return <></>;
        },
      })
    );

    if (!engagement?.anonymous)
      columns?.push({
        sticky: "right",
        Header: "",
        accessor: "_id",
        disableSortBy: true,
        Cell: ({ value }: any) => {
          return (
            <ShapeIcon
              style={{ cursor: "pointer" }}
              name="IconUser"
              size={40}
              onClick={() => navigate(`/engagement/employee/survey/${value}`)}
              variant={"neutral"}
            />
          );
        },
      });

    return columns;
  };

  const getQuestionValueByEmployeeIdAndQuestionId = (
    employeeId: string,
    questionId: string
  ) => {
    const monitorings = employees?.find(
      ({ _id }) => _id === employeeId
    )?.monitorings;

    const selectedQuestion: {
      value: string | number | string[];
      justification?: string;
    } = {
      value: "",
      justification: "",
    };

    monitorings?.forEach(({ sections }) => {
      sections?.forEach(({ questions }) => {
        questions?.forEach(({ id, value, justification }) => {
          if (id === questionId) {
            selectedQuestion["value"] = value;
            if (justification)
              selectedQuestion["justification"] = justification;
          }
        });
      });
    });

    return selectedQuestion;
  };

  if (empty) return <EmptyState />;

  return (
    <DataGrid
      report={{
        type: "survey",
        onExport: () => {
          segment({
            track: "company_engagement_surveydetail_exportaswersreport_clicked",
          });
          getEngagementReportByEngagementId();
        },
        texts: {
          title: <>Deseja exportar o relatório?</>,
          message: (
            <>
              O relatório apresentará somente os{" "}
              <strong>dados dessa tabela</strong>
              <br />
              convertidos em um arquivo CSV.
            </>
          ),
          toolTip: "Exportar relatório",
          endButton: "Voltar para detalhe da pesquisa",
        },
      }}
      searchField={
        engagement?.anonymous
          ? undefined
          : {
              onSearch: (e) => onSearch(e),
              placeholder: "Buscar por pessoa",
            }
      }
      columns={[
        {
          Header: "Pessoas",
          accessor: "name",
          disableSortBy: true,
          Cell: ({ row }: any) => {
            const { name, contacts, profilePicture } = row?.original;

            const email =
              contacts?.find(({ type }) => type === "email")?.value || "";
            const image = profilePicture?.path || "";

            return (
              <Avatar
                name={engagement?.anonymous ? "Anônimo" : name}
                email={engagement?.anonymous ? "" : email}
                image={engagement?.anonymous ? "" : image}
              />
            );
          },
        },
        {
          Header: "Data da resposta",
          accessor: "qweqwe",
          disableSortBy: true,
          Cell: ({ row }: any) => {
            const monitoring = employees
              ?.find(({ _id }) => _id === row?.original?._id)
              ?.monitorings?.find(({ type }) =>
                ["react", "enps"]?.includes(type || "")
              );

            if (monitoring?.updatedAt)
              return <>{dayjs(monitoring?.updatedAt)?.format("DD/MM/YYYY")}</>;

            return <></>;
          },
        },
        ...getColumns(),
      ]}
      data={data}
    />
  );
};

export { MonitoringDataGrid };
