import { useNavigate } from "react-router-dom";
import { Tag, ShapeIcon } from "@flash-tecnologia/hros-web-ui-v2";
import { trpc } from "@api/client";
import { DataGrid, Avatar, BorderIcon } from "@components/index";
import { PeopleDataGridInterface } from "./types";
import { segment } from "@utils";

const PeopleDataGrid = ({
  engagementId,
  data,
  anonymous,
  onSearch,
  hideColumns,
  selectedEmployees,
}: PeopleDataGridInterface) => {
  const navigate = useNavigate();

  const { refetch: getEngagementReportByEngagementId } =
    trpc?.getEngagementReportByEngagementId?.useQuery(
      {
        screen: hideColumns?.length
          ? "survey-recurrence-employee"
          : "survey-employee",
        type: "survey",
        id: engagementId,
      },
      {
        enabled: false,
        retry: false,
        refetchOnWindowFocus: false,
        onError: (error: any) => {
          alert("Ocorreu um erro, tente novamente mais tarde.");
          console.log(error);
        },
      }
    );

  const columns = [
    {
      sticky: "",
      Header: "Nome",
      accessor: "name",
      disableSortBy: true,
      Cell: ({ row }: any) => {
        const { name, contacts, profilePicture } = row?.original;

        const email =
          contacts?.find(({ type }) => type === "email")?.value || "";
        const image = profilePicture?.path || "";

        return <Avatar name={name} email={email} image={image} />;
      },
    },
  ];

  if (!hideColumns || !hideColumns?.includes("view")) {
    columns?.push({
      sticky: "",
      Header: "Visualização",
      accessor: "view",
      disableSortBy: true,
      Cell: ({ row }: any) => {
        if (anonymous) return <>{"N/A"}</>;
        const visualized = !!row?.original?.monitorings?.find(
          (m) => m?.type === "visualized" && m?.visualized
        );

        if (visualized) return <Tag variant="success">Sim</Tag>;
        return <Tag variant="yellow">Não</Tag>;
      },
    });
  }

  if (!hideColumns || !hideColumns?.includes("status")) {
    columns?.push({
      sticky: "",
      Header: "Status",
      accessor: "status",
      disableSortBy: true,
      Cell: ({ row }: any) => {
        if (anonymous) return <>{"N/A"}</>;
        const monitoring = row?.original?.monitorings?.find(
          (m) => m?.type === "react" || m?.type === "enps"
        );

        if (!monitoring) return <Tag variant="yellow">{"Não iniciado"}</Tag>;
        if (monitoring?.draft)
          return <Tag variant="gray">{"Não concluído"}</Tag>;
        if (monitoring?.draft === false)
          return <Tag variant="success">{"Concluído"}</Tag>;

        return <></>;
      },
    });
  }

  if (!hideColumns?.includes("profile") && !anonymous)
    columns?.push({
      sticky: "right",
      Header: "",
      accessor: "_id",
      disableSortBy: true,
      Cell: ({ value }: any) => {
        return (
          <BorderIcon
            name="IconUser"
            fill="transparent"
            onClick={() => navigate(`/engagement/employee/survey/${value}`)}
          />
        );
      },
    });

  return (
    <DataGrid
      report={{
        type: "survey",
        onExport: () => {
          segment({
            track: hideColumns?.length
              ? "company_engagement_recurringsurveydetail_exportpersonsreport_clicked"
              : "company_engagement_surveydetail_exportpersonsreport_clicked",
          });
          getEngagementReportByEngagementId();
        },
        texts: {
          title: <>Deseja exportar o relatório?</>,
          message: (
            <>
              O relatório apresentará somente os{" "}
              <strong>dados dessa tabela</strong>
              <br />
              convertidos em um arquivo CSV.
            </>
          ),
          toolTip: "Exportar relatório",
          endButton: "Voltar para detalhe da pesquisa",
        },
      }}
      searchField={
        anonymous
          ? undefined
          : {
              onSearch: (e) => onSearch(e),
              placeholder: "Buscar por pessoa",
            }
      }
      columns={columns}
      data={anonymous && selectedEmployees?.length ? selectedEmployees : data}
    />
  );
};

export { PeopleDataGrid };
