import { useState, useEffect } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  LabelList,
} from "recharts";

import { ChildrenContainer, BoxContainer, LabelStyle } from "./styled";

export type TGraphOneVerticalBar = {
  data: {
    name: string;
    amt: number;
  }[];
  settings: {
    name: string;
    color: string;
    showAll: boolean;
    forceHorizontal: boolean;
  };
};

export const GraphOneVerticalBar = ({
  data,
  settings,
}: TGraphOneVerticalBar) => {
  const [dataToShow, setDataToShow] = useState(data);
  const [ticks, setTicks] = useState<number[]>([]);

  useEffect(() => {
    if (!data) return;
    setDataToShow(data);

    const maxAmt = Math.max(...data?.map((item) => item?.amt));
    if (maxAmt) {
      const newArray = Array.from(
        { length: maxAmt / 10 + 3 },
        (_, index) => index * 10
      );

      setTicks(newArray);
    }
  }, [data]);

  const height = dataToShow?.length < 5 ? 230 : dataToShow?.length * 50;

  const renderCustomStyle = ({ x, y, width, value }: any) => {
    return (
      <g>
        <foreignObject x={x + width + 5} y={y - 2} width={100} height={100}>
          <LabelStyle variant="caption">{value}</LabelStyle>
        </foreignObject>
      </g>
    );
  };

  const CustomTooltip = ({ payload, active, label }: any) => {
    if (active && payload && payload.length) {
      return (
        <div
          className="custom-tooltip"
          style={{
            backgroundColor: "#1D161B",
            padding: " 12px 16px",
            borderRadius: "8px",
          }}
        >
          <p className="label" style={{ color: "#fff" }}>
            {`${label}: ${payload?.[0].value}`} pessoa
            {payload?.[0]?.value == 1 ? "" : "s"}
          </p>
        </div>
      );
    }
    return null;
  };

  return (
    <ChildrenContainer>
      <BoxContainer>
        <BarChart
          layout={settings?.forceHorizontal ? "horizontal" : "vertical"}
          data={dataToShow}
          height={height}
          width={600}
          margin={{
            top: 0,
            right: 0,
            bottom: 0,
            left: 10,
          }}
        >
          <CartesianGrid stroke="#f5f5f5" />

          <XAxis
            type="number"
            domain={[0, "dataMax + 40"]}
            ticks={ticks}
            style={{ fontWeight: 600 }}
          />
          <YAxis
            type="category"
            dataKey="name"
            width={140}
            style={{ fontWeight: 600 }}
          />
          <Tooltip content={<CustomTooltip />} />
          <Bar
            isAnimationActive={false}
            dataKey="amt"
            name={settings?.name}
            barSize={20}
            fill={settings?.color}
          >
            <LabelList
              dataKey="amt"
              position="right"
              content={renderCustomStyle}
            />
          </Bar>
        </BarChart>
      </BoxContainer>
    </ChildrenContainer>
  );
};
