import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  getAccessTokenPayloadSync,
  useSelectedCompany,
} from "@flash-tecnologia/hros-web-utility";
import {
  StepDescription,
  StepContainer,
  generateSurveyDataByModel,
} from "../../../common/components";

import { Card, BlankCard } from "./components";
import { CardInterface } from "./components/Card/types";
import { CardsContainer } from "./styles";
import { StepModelInterface } from "./types";

import { segment } from "@utils";
import { trpc } from "@api/client";

const StepModel = ({ hide, disabled, onChange }: StepModelInterface) => {
  const location = useLocation();
  const { drawerOpen } = (location.state as any) || {};
  const { selectedCompany } = useSelectedCompany();
  const accessTokenPayload = getAccessTokenPayloadSync("hrosAccessToken");
  const companyId = selectedCompany?.id || accessTokenPayload?.companyId;

  const stage =
    process.env.BFF_URL ===
    "https://hros-web-engagement-bff.private.flashapp.dev"
      ? "staging"
      : "production";

  const showClimateModel =
    stage === "staging" ||
    ["paRMZHnRi7oEI6", "FuXPPZ0P6KwR3UHydq7Ru"]?.includes(companyId || "");

  const {
    data: allowedSurveyModels,
    refetch: getAllowedSurveyModels,
    isFetching: getAllowedSurveyModelsLoading,
  } = trpc?.getAllowedSurveyModels?.useQuery(undefined, {
    retry: false,
    refetchOnWindowFocus: false,
    onSuccess: (e) => {
      console.log(e);
    },
    onError: (error: unknown) => {
      alert("Ocorreu um erro, tente novamente mais tarde.");
      console.log(error);
    },
    enabled: false,
  });

  useEffect(() => {
    getAllowedSurveyModels();
  }, []);

  // const showEngajaModel = [
  //   "-6nzC6jtx36qBP0gNiIB5", // staging PODPAH
  //   "Q5ZtohPYg2NY0CqNtuDaF", // staging LMS V2
  //   "uomTtYnevouHrDBWXpX5T", // 74178779000176 - Flash People Dev
  //   "FuXPPZ0P6KwR3UHydq7Ru", // 29657449000109 - Demo AnaB
  //   "eV6Gye9bqvFhJWZ4khrKE", // 71134328000149 - [Teste AnaB] Demonstrações
  //   "4DNRQZcvtoEctXwk5mAkk", // Prime Motel Ltda
  //   "r6pClkD2rq_xVcKMwfSc5", // Prime Piracicaba Motel Ltda
  //   "vf9JOYNQ0JuGKHaPtLVE3", // Prime Americana
  //   "VnL60RssDY5PVp", // TECNOCIRURGICA
  //   "2CfHrW1ZSiGifbzUFK0_w", // SUBSEA DRILLING DO BRASIL LTDA
  //   "VsCXe8O9D8Hc7YGsLS6ET", // DER HECK MED SERVICOS HOSPITALARES LTDA
  //   // "paRMZHnRi7oEI6", // 32223020000118 - FLASH
  //   // "JKa6OBCj7S5pRY", // 02185018000130 - PharmaPerez Farmácia e Manipulação Ltda
  //   // "2zWMzQnJX4Im_bseVg5dU", // 13520779000118 - JASGAB TELECOM
  //   // "l0bmmnIcmanaik", // 32611648000190 - Gypwork Drywall/Steel Frame
  //   // "4e37zzvth4j0Ig", // 42217199000154 - LIPEDEMA
  // ]?.includes(companyId || "");

  const [open, setOpen] = useState(drawerOpen);

  if (hide) return <></>;

  const engajaModel: CardInterface = {
    loading: false,
    disabled: false,
    model: "engaja",
    icon: "IconMoodHappy",
    title: "Pesquisa Engaja S/A",
    modelTitle: "Pesquisa Engaja S/A",
    subtitle: "Avaliação de engajamento das pessoas na empresa.",
    showNewTag: false,
    questionsCount:
      generateSurveyDataByModel("engaja")?.reduce(
        (acc, { questions = [] }) => acc + questions?.length || 0,
        0
      ) || 0,
    duration: 15,
    modelDescription: [
      {
        title: "Características",
        paragraphs: [
          `Nesta pesquisa, buscamos entender o nível de engajamento dos colaboradores, mapeando desafios, motivações, oportunidades e outros fatores que influenciam na conexão com a organização. Com os dados coletados, queremos criar um índice que ajude a medir e aprimorar o engajamento. A pesquisa é dividida em quatro partes:`,
          `1. Informações Pessoais: Coletamos informações demográficas e profissionais para garantir uma análise mais detalhada e segmentada, ajudando a identificar padrões e gerar insights relevantes para diferentes grupos de colaboradores.`,
          `2. Atributos do Engajamento: Responda sobre como você se sente atualmente em relação à empresa, avaliando seis dimensões do engajamento.`,
          `3. Ações: Avalie como ações do RH impactariam seu engajamento, mesmo que sua empresa não implemente essas iniciativas.`,
          `4. eNPS: Avaliamos a satisfação e recomendação dos colaboradores por meio do eNPS (Employee Net Promoter Score), que ajuda a medir o nível de satisfação dos funcionários.`,
          `A pesquisa é 100% confidencial e anônima, e levará cerca de 15 minutos para ser respondida.`,
        ],
      },
      {
        title: "Recomendação de aplicação",
        paragraphs: [
          `Recomendamos enviar a pesquisa a todos os colaboradores para obter uma análise mais completa e eficiente. 
Como a pesquisa é anônima, orientamos você a cadastrar os cargos e departamentos da sua empresa para ter uma análise mais estratégica dos resultados por área da empresa.`,
        ],
      },
    ],
    open: open === "engaja",
    onUse: (e) => {
      setOpen("");
      segment({
        track: open
          ? "people_strategic_hr_engagement_company_engajadetails_create_engaja"
          : "people_strategic_hr_engagement_company_templates_create_engaja",
      });

      onChange(e);
    },
    onDetail: () => {
      segment({
        track:
          "people_strategic_hr_engagement_company_templates_details_engaja",
      });
      open === "engaja" ? setOpen("") : setOpen("engaja");
    },
    // innerCardProps: {
    //   title: "O impacto do engajamento",
    //   description:
    //     "Entenda a importância de manter os colaboradores engajados e como isso se reflete no ambiente corporativo.",
    //   svg: <PersonStairsSVG />,
    //   actionButtonLabel: (
    //     <a
    //       href="https://flashapp.com.br/blog/institucional/lancamento-indice-engajamento-flash"
    //       target="_blank"
    //       rel="noopener noreferrer"
    //       style={{ textDecoration: "none" }}
    //     >
    //       <Button variant="secondary" style={{ width: "100%" }}>
    //         Saiba mais <Icons name="IconExternalLink" size={16} />
    //       </Button>
    //     </a>
    //   ),
    //   actionButtonCallback: () => null,
    // },
  };

  const climateModel: CardInterface = {
    disabled: disabled || false,
    model: "climate",
    icon: "IconThumbUp",
    title: "Pesquisa de Engajamento",
    modelTitle: "Pesquisa de Engajamento",
    subtitle: "Avaliação de engajamento das pessoas na empresa.",
    modelDescription: [
      {
        title: "Características",
        paragraphs: [
          `A Pesquisa de engajamento Organizacional é uma pesquisa estratégica e essencial para analisar o bem-estar e percepções dos colaboradores em relação ao ambiente de trabalho. Através dessa pesquisa sua empresa poderá coletar dados valiosos para criar e potencializar uma cultura organizacional mais saudável e produtiva. Esse modelo foi desenvolvido pelo time de parceiros e especialistas da Flash, incorporando as principais recomendações do mercado.`,
        ],
      },
      {
        title: "Recomendação de aplicação",
        paragraphs: [
          `Recomendamos enviar a pesquisa a todos os colaboradores para uma análise mais completa e eficiente.`,
        ],
      },
    ],
    open: open === "climate",
    onUse: (e) => {
      setOpen("");
      onChange(e);
    },
    onDetail: () => (open === "climate" ? setOpen("") : setOpen("climate")),
  };

  const enpsModel: CardInterface = {
    disabled: disabled || false,
    model: "enps",
    icon: "IconDashboard",
    title: "Pesquisa de eNPS",
    modelTitle: "Pesquisa eNPS (Employee Net Promoter Score)",
    subtitle: "Avaliação de satisfação das pessoas na empresa.",
    questionsCount: 1,
    duration: 1,
    modelDescription: [
      {
        title: "Características",
        paragraphs: [
          "Mensure o nível de lealdade e satisfação dos seus colaboradores por meio da pesquisa de eNPS e acompanhe a taxa de promotores, detratores e neutros na sua empresa. A pesquisa de eNPS é baseada na metodologia NPS e é atualmente considerada uma das principais formas de medir o engajamento dos colaboradores.",
          "Para obter insights valiosos e garantir uma aplicação imparcial, recomendamos que a pesquisa seja realizada somente após os profissionais de RH, gestores e líderes estarem treinados e alinhados.",
        ],
      },
      {
        title: "Recomendação de aplicação",
        paragraphs: [
          "Recomendamos enviar a pesquisa a todos os colaboradores para uma análise mais justa e estruturada.",
        ],
      },
    ],
    open: open === "enps",
    onUse: (e) => {
      setOpen("");
      segment({
        track: "people_strategic_hr_engagement_company_templates_create_enps",
      });
      onChange(e);
    },
    onDetail: () => (open === "enps" ? setOpen("") : setOpen("enps")),
  };

  const feelingModel: CardInterface = {
    disabled: disabled || false,
    showNewTag: true,
    model: "feeling",
    icon: "IconHeart",
    questionsCount: 4,
    duration: 3,
    title: "Pesquisa de Sentimento",
    modelTitle: "Pesquisa de Sentimento",
    subtitle: "Avalie como os colaboradores estão se sentindo.",
    modelDescription: [
      {
        title: "Características",
        paragraphs: [
          `Este modelo de pesquisa foi desenvolvido para avaliar de forma contínua o bem-estar emocional dos colaboradores e identificar rapidamente mudanças no clima organizacional. Com um formato curto e direto, ele visa facilitar a participação regular de todos, oferecendo feedback em tempo real para a empresa.`,
        ],
      },
      {
        title: "Recomendação de aplicação",
        paragraphs: [
          `A pesquisa deve ser enviada para todos os funcionários, garantindo uma visão abrangente do clima organizacional e recomendamos que a pesquisa seja aplicada semanalmente, sempre em um dia específico da semana (por exemplo, segunda ou sexta-feira), para criar consistência e facilitar a inclusão da pesquisa na rotina dos colaboradores.`,
        ],
      },
    ],
    open: open === "feeling",
    onUse: (e) => {
      setOpen("");
      onChange(e);
    },
    onDetail: () => (open === "feeling" ? setOpen("") : setOpen("feeling")),
  };

  const voluntaryExitModel: CardInterface = {
    loading: getAllowedSurveyModelsLoading,
    disabled:
      disabled || !allowedSurveyModels?.includes("voluntaryExit") || false,
    showNewTag: true,
    model: "voluntaryExit",
    icon: "IconDoorExit",
    questionsCount:
      generateSurveyDataByModel("voluntaryExit")?.reduce(
        (acc, { questions = [] }) => acc + questions?.length || 0,
        0
      ) || 0,
    duration: 3,
    title: "Pesquisa de Desligamento Voluntário",
    modelTitle: "Pesquisa de Desligamento Voluntário",
    subtitle: "Avaliação de satisfação das pessoas na empresa após a saída.",
    modelDescription: [
      {
        title: "Características",
        paragraphs: [
          `Este modelo de pesquisa foi desenvolvido para avaliar de forma contínua o bem-estar emocional dos colaboradores e identificar rapidamente mudanças no clima organizacional. Com um formato curto e direto, ele visa facilitar a participação regular de todos, oferecendo feedback em tempo real para a empresa.`,
        ],
      },
      {
        title: "Recomendação de aplicação",
        paragraphs: [
          `A pesquisa deve ser enviada para todos os funcionários, garantindo uma visão abrangente do clima organizacional e recomendamos que a pesquisa seja aplicada semanalmente, sempre em um dia específico da semana (por exemplo, segunda ou sexta-feira), para criar consistência e facilitar a inclusão da pesquisa na rotina dos colaboradores.`,
        ],
      },
    ],
    open: open === "voluntaryExit",
    onUse: (e) => {
      setOpen("");
      onChange(e);
    },
    onDetail: () =>
      open === "voluntaryExit" ? setOpen("") : setOpen("voluntaryExit"),
  };

  const involuntaryExitModel: CardInterface = {
    loading: getAllowedSurveyModelsLoading,
    disabled:
      disabled || !allowedSurveyModels?.includes("involuntaryExit") || false,
    showNewTag: true,
    model: "involuntaryExit",
    icon: "IconDoorExit",
    questionsCount:
      generateSurveyDataByModel("involuntaryExit")?.reduce(
        (acc, { questions = [] }) => acc + questions?.length || 0,
        0
      ) || 0,
    duration: 3,
    title: "Pesquisa de Desligamento Involuntário",
    modelTitle: "Pesquisa de Desligamento Involuntário",
    subtitle: "Avaliação de satisfação das pessoas na empresa após a saída.",
    modelDescription: [
      {
        title: "Características",
        paragraphs: [
          `Este modelo de pesquisa foi desenvolvido para avaliar de forma contínua o bem-estar emocional dos colaboradores e identificar rapidamente mudanças no clima organizacional. Com um formato curto e direto, ele visa facilitar a participação regular de todos, oferecendo feedback em tempo real para a empresa.`,
        ],
      },
      {
        title: "Recomendação de aplicação",
        paragraphs: [
          `A pesquisa deve ser enviada para todos os funcionários, garantindo uma visão abrangente do clima organizacional e recomendamos que a pesquisa seja aplicada semanalmente, sempre em um dia específico da semana (por exemplo, segunda ou sexta-feira), para criar consistência e facilitar a inclusão da pesquisa na rotina dos colaboradores.`,
        ],
      },
    ],
    open: open === "involuntaryExit",
    onUse: (e) => {
      setOpen("");
      onChange(e);
    },
    onDetail: () =>
      open === "involuntaryExit" ? setOpen("") : setOpen("involuntaryExit"),
  };

  let cards = [
    // involuntaryExitModel,
    // voluntaryExitModel,
    feelingModel,
    climateModel,
    enpsModel,
    engajaModel,
  ];

  if (!showClimateModel) cards = cards?.filter((c) => c?.model !== "climate");

  return (
    <StepContainer direction="column">
      <StepDescription
        title="Galeria de Modelos de pesquisa"
        description="Crie uma pesquisa em branco ou utilize um de nossos modelos padrões baseados em metodologias do mercado."
        width="100%"
      />

      <CardsContainer>
        <BlankCard
          disabled={disabled}
          icon="IconPlus"
          model="custom"
          title="Criar pesquisa do zero"
          onUse={onChange}
        />
        {cards?.map((card, i) => (
          <Card
            key={`card-${i}`}
            {...card}
            disabled={card?.disabled || disabled || false}
          />
        ))}
      </CardsContainer>
    </StepContainer>
  );
};

export { StepModel };
