import styled from "styled-components";

const Container = styled.div`
  width: 309px;
  border: 1px solid var(--color-neutral-90);
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
`;

const Header = styled.header`
  padding: 32px 24px 24px 24px;
  gap: 24px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  border-bottom: 1px solid var(--color-neutral-90);
  width: 100%;
`;

const HeaderTitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
`;

const Feed = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  padding: 24px;
  gap: 24px;
  width: 100%;
  overflow: auto;
  max-height: 725px;
`;

const EmptyStateContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 4px;
`;

export { Container, Header, HeaderTitleContainer, Feed, EmptyStateContainer };
