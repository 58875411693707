import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const InfoContainer = styled.div`
  width: 100%;
  border-radius: 12px;
  padding: 40px;
  background-color: var(--color-neutral-100);
  margin-top: 40px;
  margin-bottom: 40px;
  max-width: 870px;
`;

export { Container, InfoContainer };
