export function orderedEngajaQuestions(sectionQuestions, engajaModelSection) {
  const correctOrder = engajaModelSection?.questions?.map((q) => q.referenceId);

  const orderedQuestions = sectionQuestions?.sort((a, b) => {
    const indexA = correctOrder.indexOf(a.question_reference_id);
    const indexB = correctOrder.indexOf(b.question_reference_id);

    return indexA - indexB;
  });

  return orderedQuestions;
}
