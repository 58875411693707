import { Container, FieldsContainer } from "./styles";
import { SchedulerFormProps } from "./types";
import {
  DatePicker,
  Dayjs,
  dayjs,
  SelectField,
  Typography,
} from "@flash-tecnologia/hros-web-ui-v2";

const SchedulerForm = ({ date, period, onChange }: SchedulerFormProps) => {
  const now = dayjs();
  const hour = now?.hour();
  const disabledOptions: string[] = [];
  const isSameDay = dayjs(date)?.isSame(now, "day");

  if (isSameDay) {
    if (hour > 11) disabledOptions?.push("morning");
    if (hour > 17) disabledOptions?.push("afternoon");
  }

  const periodOptions = [
    {
      label: isSameDay && hour < 12 ? "Imediatamente" : "Manhã (08h-12h)",
      value: "morning",
    },
    {
      label:
        isSameDay && hour > 11 && hour < 18
          ? "Imediatamente"
          : "Tarde (12h-18h)",
      value: "afternoon",
    },
    {
      label: isSameDay && hour > 17 ? "Imediatamente" : "Noite (18h-22h)",
      value: "night",
    },
  ]?.filter((o) => !disabledOptions?.includes(o?.value));

  if (period && disabledOptions?.includes(period))
    onChange({ date, period: undefined });

  return (
    <Container>
      <Typography
        variant="headline8"
        variantColor="var(--color-neutral-30)"
        style={{ marginBottom: "8px" }}
      >
        Configurações do envio
      </Typography>
      <Typography variant="body4" variantColor="var(--color-neutral-50)">
        Escolha a data de envio e o período em que sua pesquisa será enviada.
      </Typography>
      <FieldsContainer>
        <DatePicker
          fromDate={dayjs(new Date())}
          label="Data de envio"
          onDateChange={(date: Dayjs | undefined) => {
            if (date) {
              const value = dayjs(date)?.format("YYYY-MM-DD");
              if (value) onChange({ date: value, period });
            }
          }}
          value={date ? dayjs(date) : undefined}
        />
        <SelectField
          disabled={!!!date}
          label="Período"
          fullWidth
          options={periodOptions}
          onSelectChange={(_: any, option) => {
            const value = option?.value;
            if (value) onChange({ date, period: value });
          }}
          value={period}
        />
      </FieldsContainer>
    </Container>
  );
};

export { SchedulerForm };
