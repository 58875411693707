import { useState } from "react";
import { TagFilter } from "@flash-tecnologia/hros-web-ui-v2";
import {
  VerticalBarChart,
  HorizontalBarChart,
  QuestionDetailBox,
  Typography,
  QuestionFeed,
  ENPSCharts,
  LineSeparator,
  HeatMap,
  TabButton,
  CampaignReport,
  EmptyState,
} from "../../../../components";
import {
  Header,
  QuestionContainer,
  RightHeaderContent,
  SectionContainer,
  TagContainer,
} from "./styles";
import { AnalyticInterface } from "./types";
import { Divider } from "@components/v2";
import { trpc } from "@api/client";

const Analytic = ({
  totalAnswers,
  empty,
  sections,
  monitorings,
  employees,
  enps,
  anonymous,
  departments,
  model,
  engagementId,
  engagementEmployees,
}: AnalyticInterface) => {
  const [showHeatMapModalReport, setShowHeatMapModalReport] = useState(false);
  const [selectTabIndex, setSelectTabIndex] = useState(
    model === "enps" ? 2 : model === "climate" || model === "engaja" ? 0 : 1
  );
  const climateWithEnpsSectionId =
    model === "climate" || model === "engaja"
      ? sections?.find(({ questions }) =>
          questions?.some(({ scaleRange }) => scaleRange?.max === 10)
        )?.id
      : undefined;

  const anonymousDepartmentIds = anonymous
    ? departments
        ?.filter(({ employees }) => {
          const countEmployees =
            employees?.filter((employeeId) =>
              engagementEmployees?.includes(employeeId)
            )?.length || 0;

          return countEmployees < 3 || employees?.length < 3;
        })
        ?.map(({ _id }) => _id) || []
    : [];

  const [filteredIdSections, setFilteredIdSections] = useState<string[]>([]);
  const [filteredIdDepartments, setFilteredIdDepartments] = useState<string[]>(
    []
  );

  const { refetch: getEngagementReportByEngagementIdRefetch } =
    trpc?.getEngagementReportByEngagementId?.useQuery(
      {
        screen: "survey-heat-map",
        type: "survey",
        id: engagementId,
      },
      {
        retry: false,
        refetchOnWindowFocus: false,
        onError: (error: any) => {
          alert("Ocorreu um erro, tente novamente mais tarde.");
          console.log(error);
        },
        enabled: false,
      }
    );

  const { data: getEngajaDepartmentsHeatmapResponse } =
    trpc?.getEngajaHeatmap.useQuery(
      { filter: "departments", engagementId },
      { retry: false, refetchOnWindowFocus: false }
    );
  const { data: getEngajaGroupsHeatmapResponse } =
    trpc?.getEngajaHeatmap.useQuery(
      { filter: "groups", engagementId },
      { retry: false, refetchOnWindowFocus: false }
    );
  const { data: getEngajaRolesHeatmapResponse } =
    trpc?.getEngajaHeatmap.useQuery(
      { filter: "roles", engagementId },
      { retry: false, refetchOnWindowFocus: false }
    );

  const getMonitorings = () => {
    if (filteredIdDepartments?.length) {
      return monitorings?.filter(
        ({ draft, employeeId }) =>
          !draft &&
          (departments
            ?.filter(({ _id }) =>
              filteredIdDepartments?.length
                ? filteredIdDepartments?.includes(_id)
                : true
            )
            ?.some(({ employees }) => employees?.includes(employeeId)) === true
            ? true
            : false)
      );
    }

    return monitorings?.filter(({ draft }) => !draft);
  };

  const getScaleArray = (min: number, max: number) => {
    const data: number[] = [];
    for (let i = min; i <= max; i++) data.push(i);
    return data;
  };

  const getTotalAnswer = (questionId: string) => {
    let result = 0;

    monitorings
      ?.filter(({ draft }) => !draft)
      ?.forEach(({ sections }) =>
        sections?.forEach(({ questions }) => {
          questions?.forEach(({ id }) => {
            if (id === questionId) result = result + 1;
          });
        })
      );

    return result;
  };

  const getOthers = (questionId: string) => {
    const result: { value: string; date: string; employeeName?: string }[] = [];

    monitorings
      ?.filter(({ draft }) => !draft)
      ?.forEach(({ sections, createdAt, employeeId }) =>
        sections?.forEach(({ questions }) => {
          questions?.forEach(({ id, justification }) => {
            if (id === questionId && justification) {
              result?.push({
                value: justification,
                date: createdAt || "",
                employeeName: employees?.find(({ _id }) => _id === employeeId)
                  ?.name,
              });
            }
          });
        })
      );

    return result;
  };

  const calcENPS = () => {
    const enps = {
      detractors: 0,
      neutrals: 0,
      promoters: 0,
      score: 0,
    };

    const enpsClimateSection = sections?.find(({ questions }) =>
      questions?.some(({ scaleRange }) => scaleRange?.max === 10)
    );

    const enpsMonitorings = getMonitorings()?.filter(({ type, draft }) =>
      ["enps", "react"]?.includes(type)
    );

    // enpsMonitorings = enpsMonitorings?.filter(({ employeeId }) =>
    //   departments
    //     ?.filter(({ _id }) =>
    //       filteredIdDepartments?.length
    //         ? filteredIdDepartments?.includes(_id)
    //         : true
    //     )
    //     ?.some(({ employees }) => employees?.includes(employeeId)) === true
    //     ? true
    //     : false
    // );

    if (enpsMonitorings?.length) {
      enpsMonitorings?.forEach(({ sections }) => {
        if (sections?.length) {
          const sectionQuestions =
            model === "climate" || model === "engaja"
              ? sections?.find(({ id }) => id === enpsClimateSection?.id)
                  ?.questions
              : sections[0]?.questions;
          if (sectionQuestions?.length) {
            const value = sectionQuestions?.find(
              ({ value }) => typeof value === "number"
            )?.value;

            if (typeof value === "number") {
              if (value < 7) {
                enps["detractors"] = enps["detractors"] + 1;
              } else if (value > 6 && value < 9) {
                enps["neutrals"] = enps["neutrals"] + 1;
              } else {
                enps["promoters"] = enps["promoters"] + 1;
              }
            }
          }
        }
      });

      enps["score"] =
        ((enps?.promoters - enps?.detractors) / enpsMonitorings?.length) * 100;
    }

    return enps;
  };

  const getAnswers = (questionId: string) => {
    const result: { date: string; value: string; employeeName: string }[] = [];

    let sent = monitorings?.filter(({ draft }) => !draft);

    if (model === "climate" || model === "engaja")
      sent = sent?.filter(({ employeeId }) =>
        departments
          ?.filter(({ _id }) =>
            filteredIdDepartments?.length
              ? filteredIdDepartments?.includes(_id)
              : true
          )
          ?.some(({ employees }) => employees?.includes(employeeId)) === true
          ? true
          : false
      );

    sent?.forEach(({ sections, updatedAt, employeeId }) =>
      sections?.forEach(({ questions }) => {
        questions?.forEach(({ id, value }) => {
          if (id === questionId) {
            if (typeof value === "string")
              result?.push({
                date: updatedAt || "",
                value,
                employeeName:
                  employees?.find(({ _id }) => _id === employeeId)?.name || "",
              });
          }
        });
      })
    );

    return result;
  };

  const scaleCount = (questionId: string, questionValue: number) => {
    let result = 0;
    getMonitorings()?.forEach((m) =>
      m?.sections?.forEach((s) => {
        s?.questions?.forEach(({ id, value }) => {
          if (questionId === id) {
            if (value === questionValue) result = result + 1;
          }
        });
      })
    );

    return result;
  };

  const count = (optionId: string) => {
    let result = 0;

    monitorings
      ?.filter(({ draft }) => !draft)
      ?.forEach((m) =>
        m?.sections?.forEach((s) => {
          s?.questions?.forEach(({ id, value }) => {
            if (typeof value !== "number") {
              const values = typeof value === "string" ? [value] : value;

              if (values?.find((value) => value === optionId))
                result = result + 1;
            }
          });
        })
      );

    return result;
  };

  const employeesWithoutDepartment = employees?.filter(
    ({ _id }) =>
      departments?.filter(({ employees }) => employees?.includes(_id))
        ?.length === 0
  );

  const getHeatMapData = (
    hiddenDepartments?: string[]
  ): {
    label: string;
    values: { value: number; na: boolean; sumAnswer: number }[];
  }[] => {
    const answered: string[] = [];

    const otherSection = {
      label: "Outros",
      values:
        sections
          ?.filter(
            ({ questions }) =>
              !!!questions?.find(({ scaleRange }) => scaleRange?.max === 10)
          )
          ?.map(({ id: sectionId, questions: sectionQuestions }) => {
            let result = 0;
            let sumAnswer = 0;

            const employeeAnswerCount: string[] = [];

            employeesWithoutDepartment?.forEach(({ _id, monitorings }) => {
              monitorings
                ?.filter(({ draft }) => !draft)
                ?.forEach(({ type, sections }) => {
                  if (type === "react" || type === "enps") {
                    sections
                      ?.filter(({ id }) => id === sectionId)
                      ?.forEach(({ questions }) => {
                        questions?.forEach(({ value, id: questionId }) => {
                          if (value && typeof value === "number") {
                            result = result + value;

                            if (
                              !answered?.includes(
                                `${sectionId}-${questionId}-${_id}`
                              )
                            ) {
                              sumAnswer = sumAnswer + value;
                              answered?.push(
                                `${sectionId}-${questionId}-${_id}`
                              );
                            }

                            if (!employeeAnswerCount?.find((id) => id === _id))
                              employeeAnswerCount?.push(_id);
                          }
                        });
                      });
                  }
                });
            });

            return {
              value: result
                ? result /
                  ((sectionQuestions?.length || 0) *
                    (employeeAnswerCount?.length || 0))
                : 0,
              na: employeesWithoutDepartment?.length < 3,
              sumAnswer,
            };
          }) || [],
    };

    const departmentSections =
      sections
        ?.filter(
          ({ questions }) =>
            !!!questions?.find(({ scaleRange }) => scaleRange?.max === 10)
        )
        ?.map(({ title, id: sectionId, questions: sectionQuestions }) => {
          return {
            label: title || "",
            values:
              (hiddenDepartments?.length === 0
                ? departments
                : departments?.filter(({ _id }) =>
                    hiddenDepartments?.includes(_id)
                  )
              )?.map(({ employees: employeeIds }) => {
                let result = 0;
                let sumAnswer = 0;

                const employeeAnswerCount: string[] = [];
                const departmentEmployees = employees?.filter(({ _id }) =>
                  employeeIds?.includes(_id)
                );

                departmentEmployees?.forEach(({ monitorings, _id }) => {
                  monitorings
                    ?.filter(({ draft }) => !draft)
                    ?.forEach(({ type, sections }) => {
                      if (type === "react" || type === "enps") {
                        sections
                          ?.filter(({ id }) => id === sectionId)
                          ?.forEach(({ questions }) => {
                            questions?.forEach(({ value, id: questionId }) => {
                              if (value && typeof value === "number") {
                                result = result + value;

                                if (
                                  !answered?.includes(
                                    `${sectionId}-${questionId}-${_id}`
                                  )
                                ) {
                                  sumAnswer = sumAnswer + value;
                                  answered?.push(
                                    `${sectionId}-${questionId}-${_id}`
                                  );
                                }

                                if (
                                  !employeeAnswerCount?.find((id) => id === _id)
                                )
                                  employeeAnswerCount?.push(_id);
                              }
                            });
                          });
                      }
                    });
                });

                return {
                  value: result
                    ? result /
                      ((sectionQuestions?.length || 0) *
                        (employeeAnswerCount?.length || 0))
                    : 0,
                  na:
                    employeeIds?.length < 3 ||
                    (engagementEmployees || [])?.filter((employeeId) =>
                      employeeIds?.includes(employeeId)
                    )?.length < 3,
                  sumAnswer,
                };
              }) || [],
          };
        }) || [];

    return employeesWithoutDepartment?.length
      ? departmentSections?.map(({ label, values }, i) => ({
          label,
          values: [...values, otherSection?.values[i]],
        }))
      : departmentSections;
  };

  const getHeatMapRows = () => {
    const geral = getHeatMapData(departments?.map((d) => d?._id))?.map(
      ({ values, label }) => {
        const total = values?.reduce((a, c) => a + c?.sumAnswer || 0, 0);
        const totalQuestions =
          sections?.find(({ title }) => title === label)?.questions?.length ||
          0;

        return total / (totalQuestions * (totalAnswers || 0));
      }
    );

    return getHeatMapData(filteredIdDepartments)?.map(
      ({ label, values }, i) => ({
        label,
        values: [
          geral[i],
          ...values?.map(({ value, na }) => (na ? -1 : value)),
        ],
      })
    );
  };

  const getSections = (comenps?: boolean) => {
    const s =
      sections?.filter(({ id }) =>
        filteredIdSections?.length === 0
          ? true
          : filteredIdSections?.includes(id || "")
      ) || [];
    if (model !== "climate") return s;

    return (
      s?.filter(({ questions }) =>
        questions?.find(({ scaleRange }) =>
          comenps ? scaleRange?.max === 10 : scaleRange?.max === 5
        )
      ) || []
    );
  };

  if (empty) return <EmptyState />;

  return (
    <>
      {model === "climate" || model === "engaja" ? (
        <>
          <Header>
            <div>
              <Typography variant="headline7" color="var(--color-neutral-30)">
                {selectTabIndex === 0
                  ? "Mapa de calor"
                  : selectTabIndex === 1
                  ? "Perguntas"
                  : "eNPS"}
              </Typography>
              <Typography
                variant="body4"
                color="var(--color-neutral-30)"
                fontWeight={400}
              >
                {selectTabIndex === 0
                  ? "Seções x Departamentos"
                  : selectTabIndex === 1
                  ? ""
                  : "Employee Net Promoter Score"}
              </Typography>
            </div>
            <RightHeaderContent>
              <TagContainer>
                {selectTabIndex !== 2 ? (
                  <Typography
                    variant="body3"
                    color="var(--color-neutral-40)"
                    fontWeight={600}
                  >
                    Exibir
                  </Typography>
                ) : null}
                <Divider spacing="xxs2" type="horizontal" />
                {selectTabIndex >= 0 ? (
                  <>
                    <TagFilter
                      variant="secondary"
                      optionIconType={"checkbox"}
                      hasLeftIcon={false}
                      // disableOptionsFooter
                      filterLabel={
                        <>
                          {filteredIdDepartments?.length
                            ? "Departamentos"
                            : "Todos os departamentos"}
                        </>
                      }
                      selectedOptions={filteredIdDepartments}
                      options={
                        departments
                          ?.filter(
                            ({ _id }) => !anonymousDepartmentIds?.includes(_id)
                          )
                          ?.map(({ name, _id }) => ({
                            label: `${
                              name && name?.length > 19
                                ? name?.substring(0, 19)
                                : name || ""
                            }${name && name?.length > 19 ? "..." : ""}`,
                            value: _id || "",
                          })) || []
                      }
                      onClick={(e) => {
                        setFilteredIdDepartments(
                          filteredIdDepartments?.includes(e)
                            ? filteredIdDepartments?.filter((v) => v !== e)
                            : [...(filteredIdDepartments || []), e]
                        );
                      }}
                      disabled={false}
                      onClear={() => setFilteredIdDepartments([])}
                    />
                    {selectTabIndex === 2 ? null : (
                      <Divider spacing="xs2" type="horizontal" />
                    )}
                    {selectTabIndex === 0 ? (
                      <CampaignReport
                        type={"survey"}
                        visible={showHeatMapModalReport}
                        onClick={() =>
                          setShowHeatMapModalReport(!showHeatMapModalReport)
                        }
                        onClose={() =>
                          setShowHeatMapModalReport(!showHeatMapModalReport)
                        }
                        onFinish={() =>
                          setShowHeatMapModalReport(!showHeatMapModalReport)
                        }
                        onExport={() =>
                          getEngagementReportByEngagementIdRefetch()
                        }
                      />
                    ) : null}
                  </>
                ) : null}
                {selectTabIndex === 1 ? (
                  <TagFilter
                    variant="secondary"
                    optionIconType={"checkbox"}
                    hasLeftIcon={false}
                    // disableOptionsFooter
                    filterLabel={
                      <>
                        {filteredIdSections?.length
                          ? "Seções"
                          : "Todas as seções"}
                      </>
                    }
                    selectedOptions={filteredIdSections}
                    options={
                      (model === "climate" || model === "engaja"
                        ? sections?.filter(
                            ({ questions }) =>
                              !questions?.some(
                                ({ scaleRange }) => scaleRange?.max === 10
                              )
                          )
                        : sections
                      )?.map(({ title, id }) => ({
                        label: `${
                          title && title?.length > 19
                            ? title?.substring(0, 19)
                            : title || ""
                        }${title && title?.length > 19 ? "..." : ""}`,
                        value: id || "",
                      })) || []
                    }
                    onClick={(e) => {
                      setFilteredIdSections(
                        filteredIdSections?.includes(e)
                          ? filteredIdSections?.filter((v) => v !== e)
                          : [...(filteredIdSections || []), e]
                      );
                    }}
                    disabled={false}
                    onClear={() => setFilteredIdSections([])}
                  />
                ) : null}
              </TagContainer>
              <TabButton
                style={{
                  width:
                    model === "climate" || model === "engaja"
                      ? "450px"
                      : "350px",
                }}
                selected={selectTabIndex}
                onChange={(e) => setSelectTabIndex(e)}
                tabs={
                  (model === "climate" || model === "engaja") &&
                  climateWithEnpsSectionId
                    ? [
                        {
                          name: "Mapa de calor",
                          icon: "IconGraph",
                        },
                        {
                          name: "Perguntas",
                          icon: "IconQuestionMark",
                        },
                        {
                          name: "eNPS",
                          icon: "IconDashboard",
                        },
                      ]
                    : [
                        {
                          name: "Mapa de calor",
                          icon: "IconGraph",
                        },
                        {
                          name: "Perguntas",
                          icon: "IconQuestionMark",
                        },
                      ]
                }
              />
            </RightHeaderContent>
          </Header>
          <Divider spacing="xs2" />
        </>
      ) : null}
      {selectTabIndex === 0 && (model === "climate" || model === "engaja") ? (
        <HeatMap
          segment="departments"
          columns={
            employeesWithoutDepartment?.length
              ? [
                  "Geral",
                  ...((filteredIdDepartments?.length === 0
                    ? departments
                    : departments?.filter(({ _id }) =>
                        filteredIdDepartments?.includes(_id)
                      )
                  )?.map(({ name }) => name) || []),
                  "Outros",
                ]
              : [
                  "Geral",
                  ...((filteredIdDepartments?.length === 0
                    ? departments
                    : departments?.filter(({ _id }) =>
                        filteredIdDepartments?.includes(_id)
                      )
                  )?.map(({ name }) => name) || []),
                ]
          }
          rows={getHeatMapRows()}
        />
      ) : null}
      {selectTabIndex > 0
        ? (selectTabIndex === 2
            ? sections?.filter(({ questions }) =>
                questions?.find(({ scaleRange }) => scaleRange?.max === 10)
              ) || []
            : getSections(
                (model === "climate" || model === "engaja") &&
                  selectTabIndex === 2
              )
          )?.map(({ id, title, questions }, sectionIndex) => (
            <SectionContainer key={id}>
              <Typography
                variant="headline7"
                color="var(--color-neutral-30)"
                fontWeight={700}
              >
                {title || ""}
              </Typography>
              <Divider spacing="xs1" />
              {questions?.map(
                (
                  {
                    id: questionId,
                    title: questionTitle,
                    options,
                    answerFormat = "",
                    scaleRange,
                    lowerLabel,
                    upperLabel,
                  },
                  questionIndex
                ) => {
                  questionTitle = `${
                    (sections
                      ?.filter((_, i) => i < sectionIndex)
                      ?.reduce(
                        (a, { questions }) => a + (questions?.length || 0),
                        0
                      ) || 0) +
                    (questionIndex + 1)
                  }. ${questionTitle}`;
                  return (
                    <QuestionContainer key={questionId}>
                      <QuestionDetailBox
                        totalAnswers={getTotalAnswer(questionId || "")}
                        answerFormat={
                          (model === "climate" ||
                            model === "engaja" ||
                            model === "enps") &&
                          scaleRange?.max === 10
                            ? "enps"
                            : answerFormat || "text"
                        }
                      >
                        {["text", "date"]?.includes(answerFormat) ? (
                          <QuestionFeed
                            questionTitle={questionTitle || ""}
                            monitorings={getAnswers(questionId || "")}
                            anonymous={anonymous}
                          />
                        ) : [
                            "multiple-choice",
                            "select-box",
                            "multi-select",
                            "dropdown",
                          ]?.includes(answerFormat) ? (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              flexDirection: "column",
                              width: "100%",
                            }}
                          >
                            <HorizontalBarChart
                              title={questionTitle || ""}
                              data={[
                                ...(options?.map(({ id: optionId, value }) => ({
                                  name: optionId || "",
                                  label: value,
                                  value: count(optionId || ""),
                                })) || []),
                                {
                                  name: "outro",
                                  value: getOthers(questionId || "")?.length,
                                  label: "Outro",
                                },
                              ]}
                            />
                            {getOthers(questionId || "")?.length ? (
                              <div style={{ width: "100%" }}>
                                <Divider spacing="xxs2" />
                                <Typography
                                  variant="body3"
                                  color="var(--color-neutral-30)"
                                  fontWeight={700}
                                >
                                  Outros:
                                </Typography>
                                <QuestionFeed
                                  questionTitle={""}
                                  monitorings={getOthers(questionId || "")}
                                />
                              </div>
                            ) : null}
                          </div>
                        ) : (
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              flexDirection: "column",
                            }}
                          >
                            {enps && scaleRange?.max === 10 ? (
                              <>
                                <ENPSCharts
                                  title={questionTitle || ""}
                                  {...calcENPS()}
                                />
                                <Divider spacing="s3" />
                                <LineSeparator />
                                <Divider spacing="s3" />
                                <Typography
                                  variant="headline8"
                                  color="var(--color-neutral-30)"
                                  fontWeight={700}
                                >
                                  Gráfico respostas x notas
                                </Typography>
                              </>
                            ) : null}
                            <VerticalBarChart
                              lowerLabel={lowerLabel}
                              upperLabel={upperLabel}
                              enps={
                                enps && scaleRange?.max === 10 ? true : false
                              }
                              title={
                                enps && scaleRange?.max === 10
                                  ? ""
                                  : questionTitle || ""
                              }
                              data={getScaleArray(
                                scaleRange?.min || 0,
                                scaleRange?.max || 0
                              )?.map((i) => ({
                                name: i?.toString(),
                                label: i?.toString(),
                                value: scaleCount(questionId || "", i),
                              }))}
                            />
                          </div>
                        )}
                      </QuestionDetailBox>
                    </QuestionContainer>
                  );
                }
              )}
              {sectionIndex !==
              (selectTabIndex === 2
                ? sections?.filter(({ questions }) =>
                    questions?.find(({ scaleRange }) => scaleRange?.max === 10)
                  ) || []
                : getSections(
                    (model === "climate" || model === "engaja") &&
                      selectTabIndex === 2
                  )
              )?.length -
                1 ? (
                <>
                  <LineSeparator />
                  <Divider spacing="s3" />
                </>
              ) : null}
            </SectionContainer>
          ))
        : null}
    </>
  );
};

export { Analytic };
