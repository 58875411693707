import { dayjs } from "@flash-tecnologia/hros-web-ui-v2";
import { useTranslation } from "react-i18next";
import { CardInfo, TagList } from "../../../../../components";
import { PreviewAside, PreviewContainer } from "./styles";
import { DetailTabInterface } from "./types";

export default ({
  model,
  createdAt,
  name,
  title,
  description,
  recurrence,
  startDate,
  interactionDeadline,
  anonymous,
  notifications,
  createdBy,
  owners,
}: DetailTabInterface) => {
  const [t] = useTranslation("translations", {
    keyPrefix: "page",
  });

  return (
    <PreviewContainer>
      <PreviewAside>
        <CardInfo
          style={{ flex: 1 }}
          direction="column"
          title="Informações básicas"
          data={[
            {
              label: "Modelo de pesquisa",
              value: {
                custom: "Personalizado",
                climate: "Engajamento",
                enps: "eNPS",
                engaja: "Engaja S/A",
                feeling: "Pesquisa Sentimento",
                voluntaryExit: "Pesquisa Desligamento Voluntário",
                involuntaryExit: "Pesquisa Desligamento Involuntário",
              }[model || "custom"],
            },
            {
              label: "Data de criação",
              value: createdAt
                ? dayjs(createdAt)?.format("DD/MM/YYYY - HH:mm")
                : "-",
            },
            {
              label: "Título da pesquisa",
              value: name || "-",
            },
            {
              label: "Título de exibição pública",
              value: title || "-",
            },
            {
              label: "Descrição",
              value: description || "-",
            },
          ]}
        />
        <CardInfo
          style={{ flex: 1 }}
          direction="column"
          title="Informações de envio"
          data={
            recurrence
              ? [
                  {
                    label: "Recorrência",
                    value:
                      recurrence?.frequency && recurrence?.repeat
                        ? `a cada ${recurrence?.repeat} ${t(
                            recurrence?.repeat > 1
                              ? `${recurrence?.frequency}s`
                              : recurrence?.frequency
                          )}`
                        : "-",
                  },
                  {
                    label: "Dias de envio",
                    value: recurrence?.daysOfTheWeek?.length
                      ? `${recurrence?.daysOfTheWeek?.reduce((a, c, i) => {
                          return `${a} ${t(c)}${
                            i === (recurrence?.daysOfTheWeek?.length || 0) - 1
                              ? ""
                              : ","
                          }`;
                        }, "")}`
                      : "-",
                  },
                  {
                    label: "Período",
                    value: startDate?.period ? t(startDate?.period) : "-",
                  },
                  {
                    label: "Início da recorrência",
                    value: startDate?.date
                      ? dayjs(startDate?.date)?.format("DD/MM/YYYY")
                      : "-",
                  },
                  {
                    label: "Término da recorrência",
                    value: recurrence
                      ? recurrence?.endDate
                        ? dayjs(recurrence?.endDate)?.format("DD/MM/YYYY")
                        : recurrence?.occurrenceLimit === -1
                        ? "Nunca"
                        : recurrence?.occurrenceLimit &&
                          recurrence?.occurrenceLimit > 0
                        ? `Depois de ${recurrence?.occurrenceLimit} envios`
                        : "-"
                      : "-",
                  },
                  {
                    label: "Prazo de resposta",
                    value:
                      (recurrence?.interactionDeadlineConfig?.calculatedDate
                        ?.multiplier &&
                        recurrence?.interactionDeadlineConfig?.calculatedDate
                          ?.period) ||
                      recurrence?.interactionDeadlineConfig?.nextMode
                        ? `${
                            recurrence?.interactionDeadlineConfig?.nextMode
                              ? "Até o próximo envio"
                              : `Até ${
                                  recurrence?.interactionDeadlineConfig
                                    ?.calculatedDate?.multiplier
                                } ${t(
                                  `${
                                    recurrence?.interactionDeadlineConfig
                                      ?.calculatedDate?.period || "day"
                                  }${
                                    recurrence?.interactionDeadlineConfig
                                      ?.calculatedDate?.multiplier &&
                                    recurrence?.interactionDeadlineConfig
                                      ?.calculatedDate?.multiplier > 1
                                      ? "s"
                                      : ""
                                  }`
                                )}`
                          } após a data de cada envio`
                        : "-",
                  },
                ]
              : [
                  {
                    label: "Data de envio",
                    value: startDate?.date
                      ? dayjs(startDate?.date)?.format("DD/MM/YYYY")
                      : "-",
                  },
                  {
                    label: "Período",
                    value: startDate?.period
                      ? `${t(startDate?.period)
                          ?.charAt(0)
                          .toLocaleUpperCase()}${t(startDate?.period)?.slice(
                          1
                        )}`
                      : "-",
                  },
                  {
                    label: "Prazo de respostas",
                    value: interactionDeadline
                      ? dayjs(interactionDeadline)?.format("DD/MM/YYYY")
                      : "-",
                  },
                ]
          }
        />
      </PreviewAside>
      <PreviewAside>
        <CardInfo
          style={{ flex: 1 }}
          direction="column"
          title="Configurações"
          data={[
            {
              label: "Anonimato",
              value: anonymous
                ? "Habilitado (as pessoas não serão identificadas)"
                : "Não habilitado (as pessoas serão identificadas)",
            },
            {
              label: "Notificações",
              value:
                notifications?.push || notifications?.email
                  ? `Habilitado - ${
                      notifications?.push ? "Push no aplicativo" : ""
                    } ${
                      notifications?.push && notifications?.email ? " e " : ""
                    } ${notifications?.email ? "E-mail corporativo" : ""}`
                  : "Desabilitado",
            },
            {
              label: "Envio da notificação",
              value:
                notifications?.reminder?.enabled &&
                notifications?.reminder?.minRate
                  ? `Notificar a cada 3 dias até atingir a taxa de resposta de ${notifications?.reminder?.minRate}%`
                  : "Desabilitado",
            },
            {
              label: "Criador",
              value: createdBy || "",
            },
            {
              label: "Responsáveis",
              value: !owners?.map((o) => o.id)?.includes("*") ? (
                <TagList
                  tags={
                    owners?.map((owner) => ({
                      id: owner?.id,
                      label: owner?.name,
                    })) ?? []
                  }
                />
              ) : (
                "Todos com acesso ao módulo"
              ),
            },
          ]}
        />
      </PreviewAside>
    </PreviewContainer>
  );
};
