import { useState } from "react";
import { dayjs, Icons, Tooltip } from "@flash-tecnologia/hros-web-ui-v2";
import { Tag, Typography } from "../../../..";
import {
  StepDescription,
  StepContainer,
  Box,
} from "../../../common/components";
import { Divider } from "../../../../v2";
import { StyleContainer, ReviewStepsContainer, Step } from "./styles";
import { StepReviewInterface } from "./types";
import { EditStepButton, TableReview } from "./components";
import { TagList } from "@components/TagList";
import { trpc } from "@api/client";
import { EmployeeModel } from "@models";
import { useUser } from "src/hooks/useUser";
import EngajaModelQuestions from "@components/EngajaModelQuestions";

const StepReview = ({
  hide,
  model,
  name,
  notifications,
  anonymous,
  description,
  recurrence,
  sections,
  employees,
  title,
  startDate,
  companyEmployees,
  interactionDeadline,
  recurrenceMode,
  owners,
  onStepClick,
}: StepReviewInterface) => {
  const user = useUser();
  const [selected, setSelected] = useState(0);

  if (hide) return <></>;

  return (
    <StepContainer>
      <ReviewStepsContainer>
        <StepDescription
          model={model}
          title="Revisão"
          description="Confira a prévia da sua pesquisa e revise antes de enviá-la."
        />
        {[
          "Detalhes",
          "Envio",
          "Configurações",
          "Conteúdo",
          "Destinatários",
        ]?.map((s, i) => (
          <Step
            key={i}
            selected={selected === i}
            onClick={() => setSelected(i)}
          >
            {s}
          </Step>
        ))}
      </ReviewStepsContainer>
      <StyleContainer>
        {selected === 0 ? (
          <>
            <Box>
              <EditStepButton onClick={() => onStepClick(1)} />
              <Typography
                variant="headline8"
                fontWeight={700}
                color="var(--color-neutral-30)"
              >
                Revisar detalhes
              </Typography>
              <Divider spacing={"quark"} />
              <Typography
                variant="body4"
                fontWeight={400}
                color="var(--color-neutral-50)"
              >
                Confira os detalhes da presquisa.
              </Typography>
              <Divider spacing="s1" />
              <Typography
                variant="body4"
                fontWeight={700}
                color="var(--color-neutral-50)"
              >
                Modelo da pesquisa
              </Typography>
              <Typography
                variant="body3"
                fontWeight={600}
                color="var(--color-neutral-40)"
              >
                {
                  {
                    custom: "Personalizada",
                    climate: "Engajamento",
                    enps: "eNPS",
                    engaja: "Engaja S/A",
                  }[model || "custom"]
                }
              </Typography>
              <Divider spacing="xs2" />
              <Typography
                variant="body4"
                fontWeight={700}
                color="var(--color-neutral-50)"
              >
                Nome de identificação da pesquisa
              </Typography>
              <Typography
                variant="body3"
                fontWeight={600}
                color="var(--color-neutral-40)"
              >
                {name}
              </Typography>
              <Divider spacing="xs2" />
              <Typography
                variant="body4"
                fontWeight={700}
                color="var(--color-neutral-50)"
              >
                Nome de exibição pública
              </Typography>
              <Typography
                variant="body3"
                fontWeight={600}
                color="var(--color-neutral-40)"
              >
                {title}
              </Typography>
              <Divider spacing="xs2" />
              <Typography
                variant="body4"
                fontWeight={700}
                color="var(--color-neutral-50)"
              >
                Descrição
              </Typography>
              <Typography
                variant="body3"
                fontWeight={600}
                color="var(--color-neutral-40)"
              >
                {description}
              </Typography>
            </Box>
            <Divider spacing="xs2" />
          </>
        ) : null}
        {selected === 1 ? (
          <>
            <Box>
              <EditStepButton onClick={() => onStepClick(2)} />
              <Typography
                variant="headline8"
                fontWeight={700}
                color="var(--color-neutral-30)"
              >
                Revisar envio
              </Typography>
              <Divider spacing={"quark"} />
              <Typography
                variant="body4"
                fontWeight={400}
                color="var(--color-neutral-50)"
              >
                Confira quando sua pesquisa será enviada.
              </Typography>
              <Divider spacing="s1" />
              <Typography
                variant="body4"
                fontWeight={700}
                color="var(--color-neutral-50)"
              >
                {recurrenceMode ? "Início da recorrência" : "Data de envio"}
              </Typography>
              <Typography
                variant="body3"
                fontWeight={600}
                color="var(--color-neutral-40)"
              >
                {startDate?.date
                  ? dayjs(startDate?.date)?.format("DD/MM/YYYY")
                  : null}
              </Typography>
              <Divider spacing="xs2" />
              <Typography
                variant="body4"
                fontWeight={700}
                color="var(--color-neutral-50)"
              >
                Período
              </Typography>
              <Typography
                variant="body3"
                fontWeight={600}
                color="var(--color-neutral-40)"
              >
                {startDate?.period === "morning" ? "Manhã" : null}
                {startDate?.period === "afternoon" ? "Tarde" : null}
                {startDate?.period === "night" ? "Noite" : null}
              </Typography>
              {!recurrenceMode ? (
                <>
                  <Divider spacing="xs2" />
                  <Typography
                    variant="body4"
                    fontWeight={700}
                    color="var(--color-neutral-50)"
                  >
                    Prazo de resposta
                  </Typography>
                  <Typography
                    variant="body3"
                    fontWeight={600}
                    color="var(--color-neutral-40)"
                  >
                    {interactionDeadline
                      ? dayjs(interactionDeadline)?.format("DD/MM/YYYY")
                      : null}
                  </Typography>
                </>
              ) : (
                <>
                  <Divider spacing="xs2" />
                  <Typography
                    variant="body4"
                    fontWeight={700}
                    color="var(--color-neutral-50)"
                  >
                    Término da recorrência
                  </Typography>
                  <Typography
                    variant="body3"
                    fontWeight={600}
                    color="var(--color-neutral-40)"
                  >
                    {recurrence?.endDate
                      ? dayjs(recurrence?.endDate)?.format("DD/MM/YYYY")
                      : null}
                    {recurrence?.occurrenceLimit &&
                    recurrence?.occurrenceLimit > 0
                      ? `Depois de ${recurrence?.occurrenceLimit} ocorrências`
                      : ""}
                    {recurrence?.occurrenceLimit === -1 ? "Nunca" : ""}
                  </Typography>
                </>
              )}
            </Box>
          </>
        ) : null}
        {selected === 2 ? (
          <>
            <Box>
              <EditStepButton onClick={() => onStepClick(3)} />
              <Typography
                variant="headline8"
                fontWeight={700}
                color="var(--color-neutral-30)"
              >
                Revisar configurações
              </Typography>
              <Divider spacing={"quark"} />
              <Typography
                variant="body4"
                fontWeight={400}
                color="var(--color-neutral-50)"
              >
                Confira as configurações habilitadas.
              </Typography>
              <Divider spacing="s1" />
              <Typography
                variant="body4"
                fontWeight={700}
                color="var(--color-neutral-50)"
              >
                Anonimato
              </Typography>
              <Typography
                variant="body3"
                fontWeight={600}
                color="var(--color-neutral-40)"
              >
                {anonymous
                  ? "Padrão - Habilitado (as pessoas não serão identificadas)"
                  : "Padrão - Desabilitado (as pessoas serão identificadas)"}
              </Typography>
              {model === "climate" ? (
                <>
                  <Divider spacing="xs2" />
                  <Typography
                    variant="body4"
                    fontWeight={700}
                    color="var(--color-neutral-50)"
                  >
                    eNPS
                  </Typography>
                  <Typography
                    variant="body3"
                    fontWeight={600}
                    color="var(--color-neutral-40)"
                  >
                    {sections?.some(({ questions }) =>
                      questions?.some(
                        ({ scaleRange }) => scaleRange?.max === 10
                      )
                    )
                      ? "Habilitado"
                      : "Desabilitado"}
                  </Typography>
                </>
              ) : null}
              {notifications?.email || notifications?.push ? (
                <>
                  <Divider spacing="s1" />
                  <Typography
                    variant="body4"
                    fontWeight={700}
                    color="var(--color-neutral-50)"
                  >
                    Notificações
                  </Typography>
                  <Typography
                    variant="body3"
                    fontWeight={600}
                    color="var(--color-neutral-40)"
                  >
                    {`Habilitado - ${
                      notifications?.push ? "Push no aplicativo" : ""
                    } ${
                      notifications?.push && notifications?.email ? " e " : ""
                    } ${notifications?.email ? "E-mail corporativo" : ""}`}
                  </Typography>
                </>
              ) : null}
              <>
                <Divider spacing="s1" />
                <Typography
                  variant="body4"
                  fontWeight={700}
                  color="var(--color-neutral-50)"
                >
                  Envio da notificação
                </Typography>
                <Typography
                  variant="body3"
                  fontWeight={600}
                  color="var(--color-neutral-40)"
                >
                  {notifications?.reminder?.enabled &&
                  notifications?.reminder?.minRate
                    ? `Notificar a cada 3 dias até atingir a taxa de resposta de ${notifications?.reminder?.minRate}%`
                    : "Desabilitado"}
                </Typography>
              </>
              <>
                <Divider spacing="s1" />
                <div
                  style={{ display: "flex", flexDirection: "row", gap: "4px" }}
                >
                  <Typography
                    variant="body4"
                    fontWeight={700}
                    color="var(--color-neutral-50)"
                  >
                    Criador
                  </Typography>
                  <Tooltip
                    title="O criador é um responsável que não pode ser removido da lista de responsáveis e suas permissões não podem ser modificadas."
                    placement="top-start"
                  >
                    <button>
                      <Icons
                        name="IconInfoCircle"
                        fill="transparent"
                        color="#83727D"
                        size={16}
                      />
                    </button>
                  </Tooltip>
                </div>
                <Typography
                  variant="body3"
                  fontWeight={600}
                  color="var(--color-neutral-40)"
                >
                  {user?.name}
                </Typography>
              </>
              <>
                <Divider spacing="s1" />
                <Typography
                  variant="body4"
                  fontWeight={700}
                  color="var(--color-neutral-50)"
                >
                  Responsáveis
                </Typography>
                <Divider spacing={"quark"} />
                {!owners?.map((o) => o.id).includes("*") ? (
                  <TagList
                    tags={
                      owners?.map((owner) => ({
                        id: owner?.id,
                        label: owner?.name,
                      })) ?? []
                    }
                  />
                ) : (
                  <Typography
                    variant="body3"
                    fontWeight={600}
                    color="var(--color-neutral-40)"
                  >
                    Todos com acesso ao módulo
                  </Typography>
                )}
              </>
            </Box>
            <Divider spacing="xs2" />
          </>
        ) : null}
        {selected === 3 ? (
          <>
            <Box>
              <EditStepButton onClick={() => onStepClick(4)} />
              <Typography
                variant="headline8"
                fontWeight={700}
                color="var(--color-neutral-30)"
              >
                Revisar perguntas
              </Typography>
              <Divider spacing={"quark"} />
              <Typography
                variant="body4"
                fontWeight={400}
                color="var(--color-neutral-50)"
              >
                Confira as perguntas que serão enviadas nessa pesquisa.
              </Typography>
              <Divider spacing="s1" />
              {model === "engaja" ? (
                <EngajaModelQuestions />
              ) : (
                <TableReview sections={sections} />
              )}
            </Box>
            <Divider spacing="xs2" />
          </>
        ) : null}
        {selected === 4 ? (
          <>
            <Box>
              <EditStepButton onClick={() => onStepClick(5)} />
              <Typography
                variant="headline8"
                fontWeight={700}
                color="var(--color-neutral-30)"
              >
                Revisar destinatários
              </Typography>
              <Divider spacing={"quark"} />
              <Typography
                variant="body4"
                fontWeight={400}
                color="var(--color-neutral-50)"
              >
                Confira as pessoas para quem a pesquisa será enviada.
              </Typography>
              <Divider spacing="s1" />
              <Typography
                variant="body4"
                fontWeight={700}
                color="var(--color-neutral-50)"
              >
                Pessoas
              </Typography>
              <Divider spacing={"quark"} />
              {employees?.slice(0, 11)?.map((employeeId) => {
                const employee = companyEmployees?.find(
                  ({ _id }) => _id === employeeId
                );

                if (employee?.name)
                  return (
                    <Tag key={employee?._id} variant="item">
                      {employee?.name}
                    </Tag>
                  );

                return null;
              })}
              {employees && employees?.length > 11 ? (
                <Tag variant="item">{`+ ${employees?.length - 11}`}</Tag>
              ) : null}
            </Box>
            <Divider spacing="xs2" />
          </>
        ) : null}
      </StyleContainer>
    </StepContainer>
  );
};

export { StepReview };
