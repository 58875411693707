import { PageContainer, Skeleton } from "@flash-tecnologia/hros-web-ui-v2";
import { Container } from "./styles";

const PageSkeleton = () => (
  <PageContainer>
    <Container>
      <Skeleton variant="rounded" height="100%" width="30%" />
      <Skeleton variant="rounded" height="100%" width="70%" />
    </Container>
  </PageContainer>
);

export { PageSkeleton };
