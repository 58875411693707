import { dayjs } from "@flash-tecnologia/hros-web-ui-v2";
import { Typography } from "@components/Typography";
import { LineSeparator } from "@components/LineSeparator";
import { QuestionTable } from "./components";
import { BorderIcon } from "@components/BorderIcon";
import { Container, Section, TextContainer } from "./styles";
import { SurveyReviewInterface } from "./types";

const SurveyReview = ({
  title,
  anonymous,
  sendDate,
  interactionDeadline,
  sections,
}: SurveyReviewInterface) => {
  const questionStats = sections?.reduce(
    (acc, { questions }) => ({
      totalQuestions: acc?.totalQuestions + questions?.length || 0,
      totalAnsweredQuestions:
        acc?.totalAnsweredQuestions +
          questions?.filter(
            (q) =>
              q?.answer || typeof q?.answer === "number" || q?.answer?.length
          )?.length || 0,
    }),
    {
      totalQuestions: 0,
      totalAnsweredQuestions: 0,
    }
  );

  return (
    <Container>
      <Section>
        <Typography
          variant="body4"
          fontWeight={600}
          color="var(--color-secondary-50)"
          style={{ marginBottom: "6px" }}
        >
          Revisão da pesquisa
        </Typography>
        <Typography
          variant="headline7"
          fontWeight={700}
          color="var(--color-neutral-20)"
          style={{ marginBottom: "12px" }}
        >
          {title}
        </Typography>
        <LineSeparator />
        <Typography
          variant="body4"
          fontWeight={400}
          color="var(--color-neutral-40)"
          style={{ marginBottom: "12px", marginTop: "12px" }}
        >
          Revise suas respostas abaixo antes de enviar a pesquisa.
        </Typography>
        <TextContainer style={{ marginBottom: "12px" }}>
          <BorderIcon
            name="IconMessage2"
            size={14}
            color="var(--color-secondary-50)"
            style={{ padding: "4px", borderColor: "var(--color-secondary-95)" }}
          />
          <Typography
            variant="body4"
            fontWeight={600}
            color="var(--color-secondary-50)"
          >
            {questionStats?.totalAnsweredQuestions} de{" "}
            {questionStats?.totalQuestions} perguntas respondidas
          </Typography>
        </TextContainer>
        {interactionDeadline ? (
          <TextContainer style={{ marginBottom: "16px" }}>
            <BorderIcon
              name="IconCalendar"
              size={14}
              color="var(--color-secondary-50)"
              style={{
                padding: "4px",
                borderColor: "var(--color-secondary-95)",
              }}
            />
            <Typography
              variant="body4"
              fontWeight={600}
              color="var(--color-secondary-50)"
            >
              Prazo de resposta:{" "}
            </Typography>
            {sendDate ? (
              <Typography
                variant="body4"
                fontWeight={600}
                color="var(--color-secondary-50)"
              >
                {dayjs(sendDate)?.format("DD/MM/YYYY")}
                {" - "}
              </Typography>
            ) : null}
            <Typography
              variant="body4"
              fontWeight={600}
              color="var(--color-secondary-50)"
            >
              {dayjs(interactionDeadline)?.format("DD/MM/YYYY")}
            </Typography>
          </TextContainer>
        ) : null}
        {anonymous ? (
          <TextContainer>
            <BorderIcon
              name="IconShieldLock"
              size={14}
              color="var(--color-secondary-50)"
              style={{
                padding: "4px",
                borderColor: "var(--color-secondary-95)",
              }}
            />
            <Typography
              variant="body4"
              fontWeight={600}
              color="var(--color-secondary-50)"
            >
              Pesquisa anônima
            </Typography>
          </TextContainer>
        ) : null}
      </Section>
      {sections?.map(({ id, title, description, questions }) => (
        <Section key={id}>
          <Typography
            variant="headline8"
            fontWeight={700}
            color="var(--color-secondary-50)"
            style={{ marginBottom: "16px" }}
          >
            {title}
          </Typography>
          {description ? (
            <Typography
              variant="body4"
              fontWeight={400}
              color="var(--color-neutral-50)"
              style={{ marginBottom: "16px" }}
            >
              {description}
            </Typography>
          ) : null}
          <QuestionTable data={questions} />
        </Section>
      ))}
    </Container>
  );
};

export { SurveyReview };
