import { GenericBox } from "@components/GenericBox";
import { GraphTwoLinesOneBaseLine } from "@components/Graphs";
import { GraphEmptyState } from "@components/Graphs/GraphEmptyState";
import { DateSelect, GraphHeader } from "@components/Graphs/GraphHeader";

export const FirstGraph = ({
  data,
}: //   onFilterChange,
// testCompanyId,
{
  data: {
    info: { title: string; metric: string; explanation: string };
    data: any;
    settings: any;
    filters?: {
      dateTo?: string;
      dateFrom?: string;
    };
  };
  //   onFilterChange: (date: DateSelect | undefined) => void;
  // testCompanyId?: string;
}) => {
  const info = data?.info;
  const graphData = data?.data;
  const settings = data?.settings;
  const filters = data?.filters;

  const isEmpty = graphData?.length == 0;

  return (
    <GenericBox size={"medium"}>
      <GraphHeader
        info={info}
        // onFiltersChange={onFilterChange}
        filters={filters}
        // reportName="FEELING_FIRST_GRAPH"
        // testCompanyId={testCompanyId}
        disabled={isEmpty}
      />
      {isEmpty ? (
        <GraphEmptyState />
      ) : (
        <GraphTwoLinesOneBaseLine data={graphData} settings={settings} />
      )}
    </GenericBox>
  );
};
