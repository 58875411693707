import { Typography } from "@flash-tecnologia/hros-web-ui-v2";
import { Grid } from "@mui/material";
import styled from "styled-components";

export const Metrics = styled.div`
  margin: 32px 0px 56px;
`;

export const CommentsWrapper = styled.div`
  display: flex;
  padding: 32px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
`;
