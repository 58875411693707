import { Button, Modal as ModalUiv2 } from "@flash-tecnologia/hros-web-ui-v2";
import { Typography } from "@components/Typography";
import { ModalChildrenContainer, ModalFooter } from "./styles";
const { Root: ModalRoot } = ModalUiv2;

const content = {
  0: {
    title: "Nos conte sobre você!",
    subTitle: "Sobre você",
    description: (
      <div>
        <Typography variant="body4" color="var(--color-neutral-40)">
          Para começar, queremos saber algumas informações
        </Typography>
        <Typography variant="body4" color="var(--color-neutral-40)">
          demográficas e profissionais para garantir uma análise com
        </Typography>
        <Typography variant="body4" color="var(--color-neutral-40)">
          valor e resultados relevantes para todos os colaboradores.
        </Typography>
        <Typography
          variant="body4"
          color="var(--color-neutral-40)"
          style={{ marginTop: "12px" }}
        >
          Vamos começar?
        </Typography>
      </div>
    ),
    btnLabel: "Responder",
  },
  1: {
    title: "Como você se sente hoje?",
    subTitle: "Atributos do Engajamento",
    description: (
      <div>
        <Typography variant="body4" color="var(--color-neutral-40)">
          Nas próximas sessões, responda às afirmativas levando em
        </Typography>
        <Typography variant="body4" color="var(--color-neutral-40)">
          conta como você se sente no seu cenário de trabalho atual.
        </Typography>
        <Typography
          variant="body4"
          color="var(--color-neutral-40)"
          style={{ marginTop: "12px" }}
        >
          Vamos lá?
        </Typography>
      </div>
    ),
    btnLabel: "Responder",
  },
  7: {
    title: "O que é importante para você?",
    subTitle: "Ações",
    description: (
      <div>
        <Typography variant="body4" color="var(--color-neutral-40)">
          Você está na metade da pesquisa! Se quiser, pode fazer uma
        </Typography>
        <Typography variant="body4" color="var(--color-neutral-40)">
          pausa. Suas respostas estarão salvas, e você poderá continuar
        </Typography>
        <Typography variant="body4" color="var(--color-neutral-40)">
          de onde parou.
        </Typography>
        <Typography variant="body4" color="var(--color-neutral-40)">
          Agora, queremos entender o impacto que cada prática tem no
        </Typography>
        <Typography variant="body4" color="var(--color-neutral-40)">
          seu trabalho. Caso sua empresa já realize algum dos exemplos
        </Typography>
        <Typography variant="body4" color="var(--color-neutral-40)">
          a seguir, avalie o impacto que isso tem sobre você atualmente.
        </Typography>
        <Typography variant="body4" color="var(--color-neutral-40)">
          Se a prática não for realizada, avalie o impacto que teria se
        </Typography>
        <Typography variant="body4" color="var(--color-neutral-40)">
          fosse implementada.
        </Typography>
        <Typography
          variant="body4"
          color="var(--color-neutral-40)"
          style={{ marginTop: "12px" }}
        >
          Pronto para continuar?
        </Typography>
      </div>
    ),
    // description:
    //   "Você está na metade da pesquisa! Se quiser, pode fazer uma <br/> pausa. Suas respostas estarão salvas, e você poderá continuar <br/> de onde parou. <br/> Agora, queremos entender o impacto que cada prática tem no <br/> seu trabalho. Caso sua empresa já realize algum dos exemplos <br/> a seguir, avalie o impacto que isso tem sobre você atualmente. <br/> Se a prática não for realizada, avalie o impacto que teria se <br/> fosse implementada. <br/> Pronto para continuar?",
    btnLabel: "Responder",
  },
  13: {
    title: "Chegamos à última parte da pesquisa!",
    subTitle: "eNPS",
    description: (
      <div>
        <Typography variant="body4" color="var(--color-neutral-40)">
          Agora, queremos medir seu nível de satisfação e o quanto você
        </Typography>
        <Typography variant="body4" color="var(--color-neutral-40)">
          recomendaria a nossa empresa para outras pessoas. Sua
        </Typography>
        <Typography variant="body4" color="var(--color-neutral-40)">
          resposta nos ajudará a identificar áreas que precisam de
        </Typography>
        <Typography variant="body4" color="var(--color-neutral-40)">
          melhorias e a fortalecer o que já funciona bem.
        </Typography>
      </div>
    ),

    btnLabel: "Responder",
  },
};

const EngajaModal = ({
  dimension,
  visible,
  onClick,
}: {
  dimension: number;
  visible: boolean;
  onClick: (dimension: number) => void;
}) => (
  <ModalRoot
    open={visible}
    onClose={() => null}
    showClose={false}
    children={
      <ModalChildrenContainer>
        <Typography
          variant="body4"
          fontWeight={700}
          color="var(--color-primary)"
          style={{ marginBottom: "8px" }}
        >
          {content[dimension]?.subTitle}
        </Typography>
        <Typography
          variant="headline8"
          fontWeight={700}
          color="var(--color-neutral-10)"
          style={{ marginBottom: "8px" }}
        >
          {content[dimension]?.title}
        </Typography>
        {content[dimension]?.description}
      </ModalChildrenContainer>
    }
    footer={
      <ModalFooter>
        <div>
          <Button
            variant="primary"
            size="large"
            onClick={() => onClick(dimension)}
          >
            Responder
          </Button>
        </div>
      </ModalFooter>
    }
  />
);

export { EngajaModal };
