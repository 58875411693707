import { LinkButton, NotificationCard } from "@flash-tecnologia/hros-web-ui-v2";
import { MouseEventHandler, ReactNode } from "react";
import styled from "styled-components";

const LinkButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  margin-top: 16px;
`;

interface EngajaNotificationCardInterface {
  title: string;
  onClose: () => void;
  description: string | ReactNode;
  linkButtons?: {
    label: string | any;
    callback: MouseEventHandler<HTMLButtonElement>;
  }[];
}

export const EngajaNotificationCard = ({
  title,
  description,
  linkButtons,
  onClose,
}: EngajaNotificationCardInterface) => {
  return (
    // TODO change type to primary when DS in published
    <NotificationCard.Root
      type={"primary" as any}
      variant={"contained"}
      showCloseButton
      onClose={onClose}
    >
      <NotificationCard.Icon iconName={"IconStar"} />
      <NotificationCard.WrapperTexts>
        <NotificationCard.Title>{title}</NotificationCard.Title>
        <NotificationCard.Subtitle>{description}</NotificationCard.Subtitle>
        {linkButtons && (
          <LinkButtonsWrapper>
            {linkButtons?.map((item) => (
              <LinkButton variant="default" onClick={item?.callback}>
                {item?.label}
              </LinkButton>
            ))}
          </LinkButtonsWrapper>
        )}
      </NotificationCard.WrapperTexts>
    </NotificationCard.Root>
  );
};
