import { Icons, IconsProps } from "@flash-tecnologia/hros-web-ui-v2";
import { IconContainer } from "./styles";

const BorderIcon = ({ onClick, style, ...rest }: IconsProps) => {
  return (
    <IconContainer
      style={style}
      onClick={(e: any) => (onClick ? onClick(e) : null)}
    >
      <Icons {...rest} />
    </IconContainer>
  );
};

export { BorderIcon };
