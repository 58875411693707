import { Typography } from "@flash-tecnologia/hros-web-ui-v2";
import { Container, InfoContainer, TitleContainer } from "./styles";
import { DrawerContentInterface } from "./types";
import { Dot } from "@components/Dot";

const DrawerContent = ({ content }: DrawerContentInterface) => (
  <Container>
    {content?.map(({ title, description, color }, i) => (
      <InfoContainer
        key={i}
        style={{
          borderBottom:
            i === content?.length - 1
              ? "none"
              : "1px solid var(--color-neutral-90)",
        }}
      >
        <TitleContainer>
          {color ? (
            <Dot
              style={{
                backgroundColor: color,
                height: "8px",
                width: "8px",
                margin: "0 4px 0 0",
              }}
            />
          ) : null}
          <Typography
            variant="headline9"
            style={{ fontWeight: 700, color: "var(--color-neutral-30)" }}
          >
            {title}
          </Typography>
        </TitleContainer>
        <Typography
          variant="body4"
          style={{
            fontWeight: 400,
            color: "var(--color-neutral-30)",
          }}
        >
          {description}
        </Typography>
      </InfoContainer>
    ))}
  </Container>
);

export { DrawerContent };
