import { Button } from "@flash-tecnologia/hros-web-ui-v2";
import { Link } from "react-router-dom";
import { Modal } from "@components/v2";
import { ModalFooter } from "./styles";

const SurveyAlreadyAnswered = () => (
  <Modal
    open={true}
    onClose={() => null}
    subTitle="Sua pesquisa já foi <br/> respondida."
    description="Acesse a plataforma para visualizar suas respostas."
    iconStatus="info"
    iconName="IconCheck"
    footer={{
      children: (
        <ModalFooter>
          <div>
            <Link to="/engagement/my-surveys">
              <Button variant="primary" size="large">
                Acessar minhas pesquisas
              </Button>
            </Link>
          </div>
        </ModalFooter>
      ),
    }}
  />
);

export { SurveyAlreadyAnswered };
