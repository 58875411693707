import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 40px;
  border: 1px solid var(--color-neutral-90);
  border-radius: 12px;
  width: 100%;
`;

const FieldsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 24px;
  margin-top: 24px;
`;

export { Container, FieldsContainer };
