import styled from "styled-components";

const ModalFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 108px;
  border-top: 1px solid var(--color-neutral-90);
`;

export { ModalFooter };
