import { Typography } from "@flash-tecnologia/hros-web-ui-v2";
import { Container } from "./styles";
import { InfoProps } from "./types";

const Info = ({ title, description, children }: InfoProps) => (
  <Container>
    <Typography
      variant="headline7"
      variantColor="var(--color-secondary-50)"
      weight={700}
    >
      {title}
    </Typography>
    <Typography
      variant="body3"
      variantColor="var(--color-neutral-50)"
      weight={400}
    >
      {description}
    </Typography>
    {children ? children : null}
  </Container>
);

export { Info };
