import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  gap: 40px;
  padding: 40px;
`;

const Section = styled.div`
  width: 100%;
  max-width: 870px;
  border-radius: 12px;
  padding: 40px;
  background-color: var(--color-neutral-100);
`;

const TextContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
`;

export { Container, Section, TextContainer };
