import { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import {
  Breadcrumbs,
  Icons,
  Skeleton,
  Tag,
  dayjs,
} from "@flash-tecnologia/hros-web-ui-v2";
import { trpc } from "@api/client";
import { alert, segment } from "../../utils";
import { Question } from "../../models/engagement/EngagementModel";
import {
  Avatar,
  DataGrid,
  StatisticRow,
  Typography,
  PageContainer,
} from "../../components";
import { Divider } from "../../components/v2";
import { FiltersType } from "@components/TableFilter/types";
import { AnswerFormatTagContainer, Header, QuestionContainer } from "./styles";

export default () => {
  const navigate = useNavigate();
  const [filters, setFilters] = useState<FiltersType>([
    {
      id: "answerFormat",
      type: "multi-select",
      label: "Formato da resposta",
      valueInLabel: true,
      options: [
        {
          label: "Texto",
          value: "text",
          checked: false,
        },
        {
          label: "Data",
          value: "date",
          checked: false,
        },
        {
          label: "Múltipla escolha",
          value: "multiple-choice",
          checked: false,
        },
        {
          label: "Caixa de seleção",
          value: "select-box",
          checked: false,
        },
        {
          label: "Seleção Multi-Tags",
          value: "multi-select",
          checked: false,
        },
        {
          label: "Lista suspensa",
          value: "dropdown",
          checked: false,
        },
        {
          label: "Reação (Emoji)",
          value: "emoji",
          checked: false,
        },
        {
          label: "Reação (Corações)",
          value: "heart",
          checked: false,
        },
        {
          label: "Reação (Estrelas)",
          value: "star",
          checked: false,
        },
        {
          label: "Escala numérica",
          value: "scale",
          checked: false,
        },
      ],
    },
    {
      id: "required",
      type: "boolean",
      label: "Obrigatoriedade",
      valueInLabel: true,
      options: [
        {
          label: "Sim",
          value: "true",
          checked: false,
        },
        {
          label: "Não",
          value: "false",
          checked: false,
        },
      ],
    },
  ]);
  const answerFormatsLabels = {
    text: {
      label: "Texto",
      icon: "IconCursorText",
    },
    date: {
      label: "Data",
      icon: "IconCalendar",
    },
    "multiple-choice": {
      label: "Múltipla escolha",
      icon: "IconCircleDot",
    },
    "select-box": {
      label: "Caixa de seleção",
      icon: "IconSquareCheck",
    },
    "multi-select": {
      label: "Seleção Multi-Tags",
      icon: "IconSquareCheck",
    },
    dropdown: {
      label: "Lista suspensa",
      icon: "IconArrowDownCircle",
    },
    emoji: {
      label: "Reação (Emoji)",
      icon: "IconMoodHappy",
    },
    heart: {
      label: "Reação (Corações)",
      icon: "IconHeart",
    },
    star: {
      label: "Reação (Estrelas)",
      icon: "IconStar",
    },
    scale: {
      label: "Escala numérica",
      icon: "IconNumbers",
    },
  };

  const { employeeId, engagementId } = useParams();
  const [questions, setQuestions] = useState<Question[]>([]);
  const [search, setSearch] = useState<string>("");

  const { refetch: getEngagementReportByEngagementId } =
    trpc?.getEngagementReportByEngagementId?.useQuery(
      {
        screen: "survey-employee-answer",
        type: "survey",
        id: engagementId || "",
        employeeId: employeeId || "",
      },
      {
        retry: false,
        refetchOnWindowFocus: false,
        onError: (error: any) => {
          alert("Ocorreu um erro, tente novamente mais tarde.");
          console.log(error);
        },
        enabled: false,
      }
    );

  const {
    data: employee,
    isFetching: getEngagementMonitoringByIdLoading,
    refetch: getEmployeeMonitoringsByEngagementId,
  } = trpc.getEmployeeMonitoringsByEngagementId.useQuery(
    {
      id: engagementId || "",
      employeeId: employeeId || "",
    },
    {
      retry: false,
      enabled: false,
      refetchOnWindowFocus: false,
      onError: (e: any) => {
        alert("Ocorreu um erro, tente novamente mais tarde.");
        console.log("err: ", e);
      },
    }
  );

  const { data: engagement, isFetching: getEngagementByIdLoading } =
    trpc.getEngagementById.useQuery(
      {
        id: engagementId || "",
      },
      {
        retry: false,
        enabled: true,
        refetchOnWindowFocus: false,
        onSuccess: (e) => {
          if (e?.anonymous) return;
          getEmployeeMonitoringsByEngagementId();
        },
        onError: (e: any) => {
          alert("Ocorreu um erro, tente novamente mais tarde.");
          console.log("err: ", e);
        },
      }
    );

  useEffect(() => {
    if (engagement?.anonymous) navigate(-1);
  }, [engagement]);

  const questionFilter = () => {
    const filter = questions?.filter(({ answerFormat, required, title }) => {
      const answerFormatFilter = filters[0]?.options?.filter(
        ({ checked }) => checked
      );

      if (answerFormatFilter?.length) {
        if (!answerFormatFilter?.find(({ value }) => value === answerFormat))
          return false;
      }

      const reqFilter = filters[1]?.options?.find(({ checked }) => checked);

      if (reqFilter) {
        if (reqFilter?.value === "true" && !required) return false;
        if (reqFilter?.value === "false" && required) return false;
      }

      if (search?.length) {
        if (!title?.match(RegExp(search, "ig"))) return false;
      }

      return true;
    });

    return filter;
  };

  const getMonitoring = () => {
    if (employee && employee?.length) {
      const monitoring = employee[0]?.monitorings?.find(
        ({ type }) => type === "react" || type === "enps"
      );

      if (monitoring) return monitoring;
    }

    return;
  };

  const getMonitoringDate = () => {
    const m = getMonitoring();
    if (m?.updatedAt) return dayjs(m?.updatedAt)?.format("DD/MM/YYYY");
    return "-";
  };

  const getQuestionMonitoringValue = (questionId: string) => {
    const selectedQuestion: {
      value: string | number | string[];
      justification?: string;
    } = {
      value: "",
      justification: "",
    };

    const m = getMonitoring();

    m?.sections?.forEach(({ questions }) =>
      questions?.forEach((q) => {
        if (q?.id === questionId) {
          if (q?.value) selectedQuestion["value"] = q?.value;
          if (q?.justification)
            selectedQuestion["justification"] = q?.justification;
        }
      })
    );

    return selectedQuestion;
  };

  useEffect(() => {
    if (engagement?.sections) {
      const allQuestions: Question[] = [];
      engagement?.sections?.forEach(({ questions }) =>
        questions?.forEach((q) => allQuestions?.push(q))
      );

      setQuestions([...allQuestions]);
    }
  }, [engagement]);

  if (engagement?.anonymous) return <></>;

  if (getEngagementMonitoringByIdLoading || getEngagementByIdLoading)
    return <Skeleton variant="rectangular" width="100%" height="100%" />;

  return (
    <PageContainer>
      <Breadcrumbs
        separator=">"
        breadcrumbs={[
          <Link to="/engagement/survey">Gerenciar pesquisas</Link>,
          <Link to={`/engagement/employee/survey/${employeeId}`}>
            Detalhe da pessoa
          </Link>,
          <Link
            to={`/engagement/employee/${employeeId}/survey/${engagementId}`}
          >
            Respostas da pesquisa
          </Link>,
        ]}
      />
      <Divider spacing="xs2" />
      <Header>
        <Avatar
          name={(employee && employee[0]?.name) || ""}
          email={(employee && employee[0]?.corporateEmail) || ""}
          image=""
          variants={{
            name: "headline6",
          }}
          avatarSize="64px"
        />
      </Header>
      <Divider spacing="xs2" />
      <QuestionContainer>
        <Typography
          fontWeight={700}
          color="var(--color-neutral-30)"
          variant="headline8"
        >
          {engagement?.name || ""}
        </Typography>
        <Divider spacing="xxxs" type="horizontal" />
        <Tag variant="gray">{`${questions?.length} perguntas`}</Tag>
      </QuestionContainer>
      <Divider spacing="xs2" />
      <StatisticRow
        data={[
          {
            icon: "IconCalendar",
            label: "Enviado em",
            value: engagement?.sendDate
              ? dayjs(engagement?.sendDate)?.format("DD/MM/YYYY")
              : "",
          },
          {
            icon: "IconCircleCheck",
            label: "Respondido em",
            value: getMonitoringDate(),
          },
          {
            icon: "IconFilePlus",
            label: "Prazo de resposta",
            value: engagement?.interactionDeadline
              ? dayjs(engagement?.interactionDeadline)?.format("DD/MM/YYYY")
              : "",
          },
        ]}
      />
      <Divider spacing="xs2" />
      <DataGrid
        filterTags={{
          filters: filters,
          onFilter: (e) => setFilters(e),
          onClear: (e) => setFilters(e),
        }}
        report={{
          type: "survey",
          onExport: () => {
            segment({
              track:
                "company_engagement_personanwers_exportanwersreport_clicked",
            });
            getEngagementReportByEngagementId();
          },
          texts: {
            title: <>Deseja exportar o relatório?</>,
            message: (
              <>
                O relatório apresentará somente os{" "}
                <strong>dados dessa tabela</strong>
                <br />
                convertidos em um arquivo CSV.
              </>
            ),
            toolTip: "Exportar relatório",
            endButton: "Voltar para respostas da pesquisa",
          },
        }}
        searchField={{
          placeholder: "Buscar por pergunta",
          onSearch: (e) => setSearch(e),
          value: search,
        }}
        loading={getEngagementByIdLoading || getEngagementMonitoringByIdLoading}
        columns={[
          {
            Header: "Pergunta",
            accessor: "title",
            Cell: ({ row }) => {
              return (
                <Typography variant="body3" color="var(--color-neutral-40)">
                  {row?.original?.title || ""}
                </Typography>
              );
            },
          },
          {
            Header: "Formato da resposta",
            accessor: "answerFormat",
            Cell: ({ row }) => {
              return (
                <Tag variant="gray">
                  <AnswerFormatTagContainer>
                    <Icons
                      size={16}
                      name={
                        answerFormatsLabels[row?.original?.answerFormat]?.icon
                      }
                    />
                    <Divider spacing="quark" type="horizontal" />
                    {answerFormatsLabels[row?.original?.answerFormat]?.label}
                  </AnswerFormatTagContainer>
                </Tag>
              );
            },
          },
          {
            Header: "Obrigatória",
            accessor: "required",
            Cell: ({ row }) => {
              if (row?.original?.required)
                return <Tag variant="success">Sim</Tag>;
              return <Tag variant="yellow">Não</Tag>;
            },
          },
          {
            Header: "Resposta",
            accessor: "answer",
            Cell: ({ row }) => {
              const { value, justification } = getQuestionMonitoringValue(
                row?.original?.id
              );

              const answerFormat = row?.original?.answerFormat;
              const options = row?.original?.options;

              if (answerFormat === "text") return <div>{value}</div>;
              if (
                answerFormat === "multiple-choice" ||
                answerFormat === "dropdown"
              ) {
                const v = options?.find((o) => o?.id === value)?.value;
                if (v || justification)
                  return (
                    <Typography variant="body3" color="var(--color-neutral-40)">
                      {v || justification}
                    </Typography>
                  );
                return <></>;
              }

              if (answerFormat === "date" && typeof value === "string")
                return value ? (
                  <>{dayjs(value)?.format("DD/MM/YYYY")}</>
                ) : (
                  <></>
                );
              if (
                ["scale", "emoji", "star", "heart"]?.includes(
                  answerFormat || ""
                )
              )
                return <div>{value}</div>;
              if (
                ["multi-select", "select-box"]?.includes(answerFormat || "") &&
                Array.isArray(value)
              ) {
                return (
                  <>
                    {value?.map((v, i) => {
                      const optionValue = options?.find(
                        (o) => o?.id === v
                      )?.value;

                      if (optionValue)
                        return (
                          <Typography
                            variant="body3"
                            color="var(--color-neutral-40)"
                          >
                            {`${optionValue}${
                              i < value?.length - (justification ? 0 : 1)
                                ? ","
                                : ""
                            }`}
                          </Typography>
                        );
                      return <></>;
                    })}
                    {justification ? (
                      <Typography
                        variant="body3"
                        color="var(--color-neutral-40)"
                      >
                        {justification}
                      </Typography>
                    ) : null}
                  </>
                );
              }

              return <>{value || justification}</>;
            },
          },
        ]}
        data={questionFilter()}
      />
    </PageContainer>
  );
};
