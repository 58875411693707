import { Divider } from "@components/v2";
import {
  MainContainer,
  RelativeContainer,
  Shadow,
  FloatContainer,
  Header,
  ShapeIconContainer,
  Footer,
  ContentContainer,
  TextSection,
  ParagraphContainer,
} from "./styles";
import { FloatContentInterface } from "./types";
import { Typography } from "@components/index";
import { Button, Icons, ShapeIcon } from "@flash-tecnologia/hros-web-ui-v2";
import { InnerCard } from "../InnerCard";
import EngajaModelDetails from "../EngajaModelDetails";

export const ModelContent = ({ modelDescription, innerCardProps }) => {
  return (
    <>
      {modelDescription?.map(({ title, paragraphs }, i) => (
        <TextSection key={i}>
          <Typography
            variant="headline9"
            fontWeight={700}
            color="var(--color-neutral-30)"
          >
            {title}
          </Typography>
          <Divider spacing="xxs2" />
          {paragraphs?.map((p) => (
            <ParagraphContainer key={`p-${i}`}>
              <Typography
                variant="headline9"
                fontWeight={400}
                color="var(--color-neutral-30)"
              >
                {p}
              </Typography>
            </ParagraphContainer>
          ))}
        </TextSection>
      ))}
      {innerCardProps && <InnerCard {...innerCardProps} />}
    </>
  );
};

export const FloatContent = ({
  open,
  onClose,
  onClick,
  modelDescription,
  modelTitle,
  innerCardProps,
  disabled,
  model,
}: FloatContentInterface) => (
  <MainContainer open={open}>
    <RelativeContainer>
      {open ? <Shadow onClick={() => (open ? onClose(false) : null)} /> : null}
      <FloatContainer>
        <Header>
          <ShapeIconContainer onClick={() => (open ? onClose(false) : null)}>
            <ShapeIcon
              name="IconX"
              variant="transparent"
              size={40}
              color="var(--color-neutral-40)"
            />
          </ShapeIconContainer>
          <Typography
            variant="headline9"
            fontWeight={700}
            color="var(--color-neutral-30)"
          >
            Sobre o modelo
          </Typography>
          <Typography
            variant="headline7"
            fontWeight={700}
            color="var(--color-neutral-10)"
          >
            {modelTitle}
          </Typography>
        </Header>
        <ContentContainer>
          {model === "engaja" ? (
            <EngajaModelDetails
              modelDescription={modelDescription}
              innerCardProps={innerCardProps}
            />
          ) : (
            <ModelContent
              modelDescription={modelDescription}
              innerCardProps={innerCardProps}
            />
          )}
        </ContentContainer>
        <Footer>
          <Button
            variant="primary"
            size="large"
            onClick={() => onClick()}
            disabled={disabled}
          >
            Usar modelo
            <Icons name="IconArrowRight" size={24} />
          </Button>
        </Footer>
      </FloatContainer>
    </RelativeContainer>
  </MainContainer>
);
